export enum Ophthalmology {
    OphthalmicExamination,
    OrderPrescription,
    DifferentialDiagnosis,
    History,
    VisualAcuityExamination,
    Retinoscopy,
    ExternalExamination,
    SlitLapExamination,
    PostDelatedExamination,
}
import { PurchaseOrderDetail } from "./purchase-order-detail.entity";

export class PurchaseOrderHeader {
    purchaseOrderHeaderId: number;
    poNumber: string;
    poRaiseDate: string;
    estimatedDeliveryDate: Date | null;
    createdBy: number;
    createdDate: Date;
    tenderStatusId: number;
    totalAmount: number;
    totalTax: number;
    totalNetAmount: number;
    supplierId: number;
    pharmacyWareHouseId: number;
    dueDays: number | null;
    locationId: number | null;
    centralWarehouseLocationId: number | null;
    supplierName: string;
    wareHouseName: string;
    locationName: string;
    createdByName: string;
    status: string;
    billNumber: string;
    billType: string;
    billDate: Date | null;
    dueDate: Date | null;
    billAmount: number | null;
    discount: number | null;
    taxes: number | null;
    netamount: number | null;
    pharmacyPurchaseHeaderId: number | null;

    supplier: any;
    poDetail: Array<PurchaseOrderDetail>;
    totalBillCount?: number;
    createdByRole: string;
    isShow = false;
    documentName: string;
    documentType: string;
    contentType: string;
    size: number;
    description?: string;
    documentUrl: string;
    thumbnailUrl: boolean;
    uploadedDate: Date;
    active: boolean;
    isImage: boolean;
    isVideo: boolean;
    modifiedBy: number;
    modifiedByName: string;
    modifiedDate?: Date;
    maxFileSize: string;
    base64: string;
    formedUrl: any;
    uploadedBy: number;
    grnDocumentId: number;

    WareHouseName: string;
    PharmacyWareHouseId: number;
    draftHeaderId: number;
    constructor() { this.poDetail = new Array<PurchaseOrderDetail>(); }
}
/* eslint-disable @typescript-eslint/no-inferrable-types */
export class PatientMedicationDetail {
    patientMedicationDetailId: number;
    patientMedicationHeaderId: number;
    pharmacyProductId: number;
    duration: number;
    dosage: number;
    categoryId: number;
    companyId: number;
    durationType: string;
    morningDosage: string;
    afternoonDosage: string;
    nightDosage: string;
    route: string;
    instruction: string;
    productName: string;
    genericName: string;
    companyName: string;
    categoryName: string;
    saleUnitName: string;
    isMorning: boolean;
    isAfternoon: boolean;
    isNight: boolean;
    remark: string;
    medicationId: string;
    medicationDurationTypeId: number;
    endDate: Date;
    unit: number;
    createdDate: Date;
    templateDetailId: number;
    templateHeaderId: number;
    active?: boolean;
    reasonForOverRide: string;
    isSeverity: boolean = false;
    fixedQuantity: number;
    instructions: string;
    pediatricFreq: number;
    isBilled: boolean;
    addDate: any;
    morningInstructionTypeId: number;
    afternoonInstructionTypeId: number;
    nightInstructionTypeId: number;
}


export class PatientMedicationHeader {
    patientMedicationHeaderId: number;
    patientLabHeaderId: number;
    appointmentId: number;
    createdBy: number;
    modifiedBy: number;
    patientId: number;
    patientAge: number;
    createdDate: Date;
    modifiedDate: Date;
    encounterType: string;
    createdByName: string;
    modifiedByName: string;
    appointmentNo: string;
    appointmentDate: string;
    appointmentTime: string;
    patientName: string;
    patientGender: string;
    patientMobile: string;
    providerName: string;
    umrNo: string;
    active: boolean;
    providerId: number;
    encryptedAppointmentId: string;
    providerNo: string;
    specializationName: string;
    practiceName: string;
    practiceLocation: string
    medicines: Array<PatientMedicationDetail>;
    
    labs: Array<any>;
    IsBillingDone?: boolean;
    billNumber: string;
    requisitionNumber: string;
    thumbnailUrl: string;
    encryptedPatientId: string;
    name: string;
    streetAddress: string;
    cityName?: string;
    stateName?: string;
    countryName?: string;
    specializationId?: number;
    remark: string;
    route: string;
    productName: string;
    duration: number;
    instruction: string;
    testName: string;
    textCode: string;
    isBilled: boolean;
    relation: string;
    relativeName: string;
    constructor() {
        this.medicines = new Array<PatientMedicationDetail>();
        this.labs = new Array<any>();
    }
}
export class DoctorSpecializationConsultations {
    providerId: number;
    specializationId: number;
    locationId: number;
    consultationTypeId: number;
    consultationTypeName: string;
    specializationName: string;
    doctorName: string;
    totalItems: number;
    doctorType: string;
    amount: string;
    chargeName: string;
}
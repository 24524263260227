<div class="modal-body" *ngIf="loading">
    <div class="d-flex align-items-center">
        <span class="spinner-grow text-warning" role="status" aria-hidden="true"></span>
        <span class="ml-2">Please wait while loading bill details....</span>
    </div>
</div>
<div class="modal-body p-0" *ngIf="!loading && bill.length === 0">
    <div class="d-flex justify-content-center">
        <div class="no-data-container">
            <img class="mt-1" width="350" src="assets/images/no-data-2.png" alt="no data" />
            <h4 class="mt-1">no bill data found.nothing to show you.</h4>
        </div>
    </div>
</div>
<div>
    <div class="modal-header  d-flex col-12">
        <div class="col-3">
            <h4 class="modal-title"><i class="mdi mdi-cash mr-1"></i>Lab Recepit</h4>

        </div>
        <div class="d-print-none justify-content-end d-flex">
            <div>
                <button class="btn btn-sm" [class.btn-light]="!isPrintLogo" [class.btn-outline-light]="isPrintLogo" type="button" (click)="onChangePrintType(false)"><i class="mdi mdi-check mr-1" *ngIf="!isPrintLogo"></i>Without Logo</button>
                <button class="btn btn-sm" [class.btn-light]="isPrintLogo" [class.btn-outline-light]="!isPrintLogo" type="button" (click)="onChangePrintType(true)"><i class="mdi mdi-check mr-1" *ngIf="isPrintLogo"></i>With Logo</button>
            </div>
            <div>
                <button class="btn btn-sm" [class.btn-light]="!isFooter" [class.btn-outline-light]="isFooter" type="button" (click)="onChangeFooter(false)"><i class="mdi mdi-check mr-1" *ngIf="!isFooter"></i>Without Footer</button>
                <button class="btn btn-sm" [class.btn-light]="isFooter" [class.btn-outline-light]="!isFooter" type="button" (click)="onChangeFooter(true)"><i class="mdi mdi-check mr-1" *ngIf="isFooter"></i>With Footer</button>
            </div>
            <button printSectionId="invoiceId" [useExistingCss]="true" ngxPrint class="btn btn-small btn-light "><i class="fe-printer mr-1"></i>Print</button>
            <button type="button" class="closeBtn" data-dismiss="modal" aria-hidden="true" (click)="onCloseModal()">×</button>

        </div>

    </div>
</div>
<div class="col-12 M-1" Id="invoiceId">
    <div *ngIf="bill">
        <div>
            <div colspan="4" align="center" *ngIf="isPrintLogo">
                <banner-setting [reportName]='"Lab Invoice Header"'></banner-setting>
            </div>
            <div align="center" *ngIf="!isPrintLogo">
                <h5>{{practiceName}}</h5>
                <h6>
                    {{locationName}}
                </h6>  
            </div>
            <div>
                <h5 class="justify-content-center d-flex">FINAL BILL</h5>
                <h5 class=" justify-content-center d-flex">INVESTIGATIONS EXCLUSIONS</h5>
            </div>

        </div>
        <div class="col-12 border-dark border-bottom ">
            <h5 class="justify-content-end d-flex"></h5>
        </div>
    </div>
    <div class="modal-body">
        <div class="row" *ngFor="let bill of bill;index as i;">

            <div class="col-12 d-flex">
                <div class="col-6">
                    <table>
                        <tr>
                            <td class="w-100px"><h5 class="font-weight-bold m-0 p-1">Bill No.</h5></td>
                            <td class="w-50px"><h5 class="font-weight-bold m-0 p-1">:</h5></td>
                            <td class="w-100px"><h5 class="font-weight-bold m-0 p-1">{{bill.requisitionNumber}}</h5></td>

                        </tr>
                        <tr>
                            <td class="w-100px"><h5 class="font-weight-bold m-0 p-1">IP No</h5></td>
                            <td class="w-50px"><h5 class="font-weight-bold m-0 p-1">:</h5></td>
                            <td class="w-100px"><h5 class="font-weight-bold m-0 p-1">{{bill.admissionNo}}</h5></td>

                        </tr>
                        <tr>
                            <td class="w-100px"><h5 class="font-weight-bold m-0 p-1 text-black">Name</h5></td>
                            <td class="w-50px"><h5 class="font-weight-bold m-0 p-1 text-black">:</h5></td>
                            <td class="w-100px"><h5 class="font-weight-bold m-0 p-1 text-black text-uppercase">{{bill.patientName}}</h5></td>
                        </tr>
                        <tr>
                            <td class="w-100px"><h5 class="font-weight-bold m-0 p-1">F/H Name</h5></td>
                            <td class="w-50px"><h5 class="font-weight-bold m-0 p-1">:</h5></td>
                            <td class="w-100px"><h5 class="font-weight-bold m-0 p-1">{{bill.ralativeName}}</h5></td>

                        </tr>
                        <tr>
                            <td class="w-100px"><h5 class="font-weight-bold m-0 p-1">Address</h5></td>
                            <td class="w-50px"><h5 class="font-weight-bold m-0 p-1">:</h5></td>
                            <td class="w-100px"><h5 class="font-weight-bold m-0 p-1">{{bill.streetAddress}}</h5></td>
                        </tr>
                        <tr>
                            <td class="w-100px"><h5 class="font-weight-bold m-0 p-1">Ward No</h5></td>
                            <td class="w-50px"><h5 class="font-weight-bold m-0 p-1">:</h5></td>
                            <td class="w-100px"><h5 class="font-weight-bold m-0 p-1">{{bill.wardName}}</h5></td>

                        </tr>
                        <tr>
                            <td class="w-100px"><h5 class="font-weight-bold m-0 p-1">Panel</h5></td>
                            <td class="w-50px"><h5 class="font-weight-bold m-0 p-1">:</h5></td>
                            <td class="w-100px"><h5 class="font-weight-bold m-0 p-1">{{bill.insuranceName}}</h5></td>
                        </tr>
                        <tr>
                            <td class="w-100px"><h5 class="font-weight-bold m-0 p-1">Unit Name</h5></td>
                            <td class="w-50px"><h5 class="font-weight-bold m-0 p-1">:</h5></td>
                            <td class="w-100px"><h5 class="font-weight-bold m-0 p-1">{{bill.unitName}}</h5></td>

                        </tr>
                        <tr>
                            <td class="w-250px"><h5 class="font-weight-bold m-0 p-1">Admission Date</h5></td>
                            <td class="w-50px"><h5 class="font-weight-bold m-0 p-1">:</h5></td>
                            <td class="w-250px"><h5 class="font-weight-bold m-0 ">{{bill.admissionDate |date:'dd/MM/yyyy'}} &nbsp;{{bill.admissionDate | date:'hh:mm a'}}</h5></td>
                        </tr>
                        <tr>
                            <td class="w-100px"><h5 class="font-weight-bold m-0 p-1">Discharge</h5></td>
                            <td class="w-50px"><h5 class="font-weight-bold m-0 p-1">:</h5></td>
                            <td class="w-100px"><h5 class="font-weight-bold m-0 p-1">{{bill.dischargeDate}}</h5></td>
                        </tr>
                    </table>
                </div>
                <div class="col-6">
                    <table>

                        <tr>
                            <td class="w-100px"><h5 class="font-weight-bold m-0 p-1">RunDate</h5></td>
                            <td class="w-50px"><h5 class="font-weight-bold m-0 p-1">:</h5></td>
                            <td class="w-250px"><h5 class="font-weight-bold m-0 p-1">{{currentDate | date:'dd/MM/yyyy' }}&nbsp; &nbsp;{{currentDate | date:'hh:mm a'}}</h5></td>

                        </tr>
                        <tr>
                            <td class="w-100px"><h5 class="font-weight-bold m-0 p-1">Bill Date</h5></td>
                            <td class="w-50px"><h5 class="font-weight-bold m-0 p-1">:</h5></td>
                            <td class="w-100px"><h5 class="font-weight-bold m-0 p-1">{{currentDate | date:'dd/MM/yyyy'}}</h5></td>
                        </tr>

                        <tr>

                            <td class="w-100px"><h5 class="font-weight-bold m-0 p-1">Sex</h5></td>
                            <td class="w-50px"><h5 class="font-weight-bold m-0 p-1">:</h5></td>
                            <td class="w-100px"><h5 class="font-weight-bold m-0 p-1">{{bill.gender}}</h5></td>
                        </tr>
                        <tr>

                            <td class="w-100px"><h5 class="font-weight-bold m-0 p-1">Age</h5></td>
                            <td class="w-50px"><h5 class="font-weight-bold m-0 p-1">:</h5></td>
                            <td class="w-100px"><h5 class="font-weight-bold m-0 p-1">{{bill.age}}</h5></td>
                        </tr>
                        <tr>

                            <td class="w-100px"><h5 class="font-weight-bold m-0 p-1">Bed No</h5></td>
                            <td class="w-50px"><h5 class="font-weight-bold m-0 p-1">:</h5></td>
                            <td class="w-100px"><h5 class="font-weight-bold m-0 p-1">{{bill.bedNumber}}</h5></td>
                        </tr>
                        <tr>

                            <td class="w-100px"><h5 class="font-weight-bold m-0 p-1">Pmt Mode</h5></td>
                            <td class="w-50px"><h5 class="font-weight-bold m-0 p-1">:</h5></td>
                            <td class="w-100px"><h5 class="font-weight-bold m-0 p-1">{{bill.payTypeName}}</h5></td>
                        </tr>
                        <tr>

                            <td class="w-100px"><h5 class="font-weight-bold m-0 p-1">Days</h5></td>
                            <td class="w-50px"><h5 class="font-weight-bold m-0 p-1">:</h5></td>
                            <td class="w-100px"><h5></h5></td>
                        </tr>
                    </table>
                </div>
            </div>



            <div class="col-12">
                <h5 class="justify-content-center d-flex border-dark border-bottom text-black font-weight-bold">SH DAY CARE UNIT</h5>

                <h5 class="justify-content-center d-flex text-black font-weight-bold">HEALTH CARE SERVICES</h5>

                <h5 class="justify-content-center d-flex text-black font-weight-bold">INVESTIGATION DETAILS</h5>
            </div>
            <div class="col-12 p-0">
                <table class="table table-black-border">
                    <thead class="border-dark border-top border-bottom">
                        <tr>
                            <th class="text-center"><h5 class="font-weight-bold m-0 p-0">Date</h5></th>
                            <th class="text-center"><h5 class="font-weight-bold m-0 p-0">RequisitionNumber</h5></th>
                            <th><h5 class="font-weight-bold m-0 p-0">INVESTIGATION NAME</h5></th>
                            <th class="text-center"><h5 class="font-weight-bold m-0 p-0">Total</h5></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let lab of bill.labs;index as j;">
                            <td class="m-0 p-0 text-center">
                                <h6 class="font-weight-bold " [textContent]="lab.uploadedDate |date:'dd/MM/yyyy'"></h6>
                            </td>
                            <td class="m-0  p-0 text-center">
                                <h6 class="font-weight-bold " [textContent]="bill.requisitionNumber"></h6>
                            </td>
                            <td class="m-0 p-0 ">
                                <h6 class="font-weight-bold " [textContent]="lab.testName"></h6>
                                <small class="d-block" [textContent]="lab.departmentName || '--'"></small>
                            </td>

                            <td class="m-0 p-0 text-center">
                                <h6 class="font-weight-bold " [textContent]="lab.netAmount || 0 | currency :'INR'"></h6>
                            </td>
                        </tr>
                    </tbody>
                </table>

            </div>

            <div class="justify-content-end d-flex m-0 col-12">
                <h5 class="border-dark border-bottom border-top font-weight-bold">Net Total  : {{bill.overallTotalAmount || 0 | currency:'INR' }}</h5>
            </div>
            <div class="justify-content-start d-flex m-0 col-12">
                <h5 class="font-weight-bold text-black">{{bill.overallTotalAmountInWord}}</h5>
            </div>
            <div class="justify-content-end d-flex col-12 m-0 border-dark border-bottom">
                <h5 class="font-weight-bold text-black">for FERNANDEZ HOSPITAL</h5>
            </div>


        </div>
    </div>

    <div>
        <table class="table">
            <tr>
                <td>
                    <h5 class="font-weight-bold">  Printed Date : {{currentDate | date:'dd/MM/yyyy, h:mm a'}}</h5>
                </td>
                <td>
                    <h5 class="font-weight-bold"> Printed By :{{page.userAccount.fullName}}</h5>
                </td>
            </tr>

        </table>
    </div>
    <div colspan="4" align="center" *ngIf="isFooter">
        <banner-setting [reporName]='"Lab Invoice Footer"'></banner-setting>
    </div>
</div>

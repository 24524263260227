<div class="content">

    <div class="row">

        <div class="col-12">
            <div class="page-title-box mb-0">
                <h4 class="page-title">
                    OP Nutritional Assessment Form
                </h4>
            </div>
        </div>

    </div>
    <div class="fixHeaight">
        <div class="bg-white font-12">
            <form [formGroup]="opNutritionalAssessment">
                <div class="bg-white">
                    <div>
                        <div class="row">
                            <div class="col-12 pr-0 row">
                                <div class="col-12">
                                    <div>
                                        <div class="p-0">
                                            <div class="col-12">
                                                <div class="row">
                                                    <div class="col-12 col-xl-4">
                                                        <div class="headingStyleOb">
                                                            <div class="trapeziumOb font-weight-semibold text-white">
                                                                <span class="p-1">OP Nutritional Assessment</span>
                                                            </div>
                                                        </div>
                                                        <div class="d-flex" style="margin-top:10px ">
                                                            <div class="form-group mb-1 col-5 d-flex align-items-baseline">
                                                                <label class="mb-1">Titles</label>
                                                            </div>
                                                            <div class="col-7 form-group mb-1">
                                                                <input type="text" class="form-control" autofocus formControlName="titles" />
                                                            </div>
                                                        </div>

                                                        <div class="d-flex">
                                                            <div class="form-group mb-1 col-5">
                                                                <label class="mb-1">Diet Type</label>
                                                            </div>
                                                            <div class="form-group mb-1 col-7 mb-1">
                                                                <ng-select [items]="conditionTypes"
                                                                           [loading]="loadingConditionTypes"
                                                                           [clearable]="true" [hideSelected]="true"
                                                                           bindLabel="value"
                                                                           bindValue="value"
                                                                           placeholder="Select Condition type"
                                                                           formControlName="deptType"
                                                                           (change)="onChangeDeptType()">
                                                                    <ng-template ng-notfound-tmp let-searchTerm="searchTerm">
                                                                        <div class="ng-option disabled">
                                                                            No condition type found for '{{searchTerm}}'
                                                                        </div>
                                                                    </ng-template>
                                                                </ng-select>
                                                                
                                                            </div>
                                                        </div>
                                                        <div class="d-flex">
                                                            <div class="form-group mb-1 col-5 d-flex align-items-baseline">
                                                                <label class="mb-1">Visit Type</label>
                                                            </div>
                                                            <div class="col-7 form-group mb-1">
                                                                <input type="text" class="form-control" formControlName="visitType" />
                                                            </div>
                                                        </div>
                                                        <div class="d-flex" *ngIf="opNutritionalAssessment.value.deptType == 'OBS'">
                                                            <div class="form-group mb-1 col-5 d-flex align-items-baseline">
                                                                <label class="mb-1">G P L A</label>
                                                            </div>
                                                            <div class="col-7 form-group mb-1">
                                                                <input type="text" class="form-control" formControlName="gpla" readonly />
                                                            </div>
                                                        </div>
                                                        <div class="d-flex" *ngIf="opNutritionalAssessment.value.deptType == 'OBS'">
                                                            <div class="form-group mb-1 col-5 d-flex align-items-baseline">
                                                                <label class="mb-1">Gestation Age (Weeks/days)</label>
                                                            </div>
                                                            <div class="col-7 form-group mb-1">
                                                                <input type="text" class="form-control" formControlName="gestationage" />
                                                            </div>
                                                        </div>
                                                        <div class="d-flex">
                                                            <div class="form-group mb-1 col-5 d-flex align-items-baseline">
                                                                <label class="mb-1">Special Features</label>
                                                            </div>
                                                            <div class="col-7 form-group mb-1">
                                                                <textarea class="form-control" formControlName="specialFeatures"></textarea>
                                                            </div>
                                                        </div>
                                                        <div class="d-flex">
                                                            <div class="form-group mb-1 col-5 d-flex align-items-baseline">
                                                                <label class="mb-1">Occupation</label>
                                                            </div>
                                                            <div class="col-7 form-group mb-1">
                                                                <input type="text" class="form-control" formControlName="occupation" />
                                                            </div>
                                                        </div>
                                                        <div class="d-flex">
                                                            <div class="form-group mb-1 col-5 d-flex align-items-baseline">
                                                                <label class="mb-1">Height in feet</label>
                                                            </div>
                                                            <div class="col-7 form-group mb-1">
                                                                <input type="text" class="form-control" formControlName="heightInFeet" (keyup)="positiveHeight()" (focusout)="onCalculateHeight($event)" />
                                                            </div>
                                                        </div>
                                                        <div class="d-flex">
                                                            <div class="form-group mb-1 col-5 d-flex align-items-baseline">
                                                                <label class="mb-1">Height In Meter</label>
                                                            </div>
                                                            <div class="col-7 form-group mb-1">
                                                                <input type="text" class="form-control" formControlName="heightInMeter" (keyup)="positiveHeightInMts()" />
                                                            </div>
                                                        </div>
                                                        <div class="d-flex" *ngIf="form.deptType.value == 'OBS'">
                                                            <div class="form-group mb-1 col-5 d-flex align-items-baseline">
                                                                <label class="mb-1">Pre-Pregnancy Wt (Kgs)</label>
                                                            </div>
                                                            <div class="col-7 form-group mb-1">
                                                                <input type="text" class="form-control" formControlName="prePregnancyWt" (keyup)="positivePrePegWt()" (focusout)=onCalculateOldBMI($event) />
                                                            </div>
                                                        </div>
                                                        <div class="d-flex" *ngIf="form.deptType.value == 'OBS'">
                                                            <div class="form-group mb-1 col-5 d-flex align-items-baseline">
                                                                <label class="mb-1">Pre-Pregnancy BMI</label>
                                                            </div>
                                                            <div class="col-7 form-group mb-1">
                                                                <input type="text" class="form-control" formControlName="prePregnancybmi" />
                                                            </div>
                                                        </div>
                                                        <div class="d-flex" *ngIf="form.deptType.value == 'OBS'">
                                                            <div class="form-group mb-1 col-5 d-flex align-items-baseline">
                                                                <label class="mb-1">Pre-Preg BMI Category</label>
                                                            </div>
                                                            <div class="col-7 form-group mb-1">
                                                                <input type="text" class="form-control" formControlName="prePregBmiCategory" />
                                                            </div>
                                                        </div>
                                                        <div class="d-flex" *ngIf="form.deptType.value == 'OBS'">
                                                            <div class="form-group mb-1 col-5 d-flex align-items-baseline">
                                                                <label class="mb-1">Wt Loss In 1st Trimester</label>
                                                            </div>
                                                            <div class="col-7 form-group mb-1">
                                                                <select class="form-control " #mySelect formControlName="wtLossIn1stTrimester" (change)="onChangeWtLoss();onCaluclateweightLoss(mySelect.value)" autocomplete="nope">
                                                                    <option value="null"></option>
                                                                    <option value="No">No</option>
                                                                    <option value="Yes">Yes</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class="d-flex" *ngIf="opNutritionalAssessment.value.wtLossIn1stTrimester == 'Yes'">
                                                            <div class="form-group mb-1 col-5 d-flex align-items-baseline">
                                                                <label class="mb-1">Weight </label>
                                                            </div>
                                                            <div class="col-7 form-group mb-1">
                                                                <input type="text" (keyup)="positiveWtlossTrimster()" (change)="onCaluclateweightLoss(opNutritionalAssessment.value.wtLossIn1stTrimester)" class="form-control" formControlName="weighttLossIn1stTrimester" />
                                                            </div>
                                                        </div>



                                                        <div class="d-flex">
                                                            <div class="form-group mb-1 col-5 d-flex align-items-baseline">
                                                                <label class="mb-1">Present Weight (Kg)</label>
                                                            </div>
                                                            <div class="col-7 form-group mb-1">
                                                                <input type="text"  readonly class="form-control" formControlName="presentWeight" />
                                                            </div>
                                                        </div>
                                                        <div class="d-flex">
                                                            <div class="form-group mb-1 col-5 d-flex align-items-baseline">
                                                                <label class="mb-1">Present BMI</label>
                                                            </div>
                                                            <div class="col-7 form-group mb-1">
                                                                <input type="text" readonly class="form-control" formControlName="presentBmi" />
                                                            </div>
                                                        </div>
                                                        <div class="d-flex">
                                                            <div class="form-group mb-1 col-5 d-flex align-items-baseline">
                                                                <label class="mb-1">BMI Categorization</label>
                                                            </div>
                                                            <div class="col-7 form-group mb-1">
                                                                <input type="text" readonly class="form-control" formControlName="bmiCategorization" />
                                                            </div>
                                                        </div>
                                                        <div class="d-flex" *ngIf="form.deptType.value == 'OBS'">
                                                            <div class="form-group mb-1 col-5 d-flex align-items-baseline">
                                                                <label class="mb-1">Wt Loss (Kgs)</label>
                                                            </div>
                                                            <div class="col-7 form-group mb-1">
                                                                <input type="text" (keyup)="positiveWtloss()" class="form-control" formControlName="wtLoss" />
                                                            </div>
                                                        </div>
                                                        <div class="d-flex" *ngIf="opNutritionalAssessment.value.deptType == 'OBS'">
                                                            <div class="form-group mb-1 col-5 d-flex align-items-baseline">
                                                                <label class="mb-1">Wt Gain (Kgs)</label>
                                                            </div>
                                                            <div class="col-7 form-group mb-1">
                                                                <input type="text" (keyup)="positiveWtgain()" class="form-control" formControlName="wtGain" />
                                                            </div>
                                                        </div>
                                                        <div class="headingStyleOb">
                                                            <div class="trapeziumOb font-weight-semibold text-white">
                                                                <span class="p-1">Present Condition</span>
                                                            </div>
                                                        </div>
                                                        <div class="d-flex" style="margin-top:10px ">
                                                            <div class="form-group mb-1 col-5 d-flex align-items-baseline">
                                                                <label class="mb-1">Present Risk Factors</label>
                                                            </div>
                                                            <div class="col-7 form-group mb-1">
                                                                <div class="col-12 form-group mb-1" *ngFor="let item of presentRiskFactors; let i = index">
                                                                    <div class="custom-control custom-checkbox">
                                                                        <input [attr.id]="'presentRiskFactors' + i" class="custom-control-input" type="checkbox" [checked]="isSelectedRisk(item)" (change)="onChangeRisk(item)">
                                                                        <label class="custom-control-label" [attr.for]="'presentRiskFactors' + i" [textContent]="item"></label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="d-flex">
                                                            <div class="form-group mb-1 col-5 d-flex align-items-baseline">
                                                                <label class="mb-1">Other Risk Factors</label>
                                                            </div>
                                                            <div class="col-7 form-group mb-1">
                                                                <textarea type="text" class="form-control" formControlName="otherRiskFactors"></textarea>
                                                            </div>
                                                        </div>
                                                        <div class="headingStyleOb">
                                                            <div class="trapeziumOb font-weight-semibold text-white">
                                                                <span class="p-1">Laboratory Findings</span>
                                                            </div>
                                                        </div>
                                                        <div class="d-flex" style="margin-top:10px ">
                                                            <div class="form-group mb-1 col-5 d-flex align-items-baseline">
                                                                <label class="mb-1">Haemoglobin</label>
                                                            </div>
                                                            <div class="col-7 form-group mb-1">
                                                                <input type="text" class="form-control" formControlName="haemoglobin" />
                                                            </div>
                                                        </div>
                                                        <div class="d-flex">
                                                            <div class="form-group mb-1 col-5 d-flex align-items-baseline">
                                                                <label class="mb-1">HMGBN Date</label>
                                                            </div>
                                                            <div class="col-7 form-group mb-1">
                                                                <input type="date" placeholder="DD/MM/YYYY" class="form-control" formControlName="hMGBNDate" />
                                                                <!--<input type="text" class="form-control date-picker" formControlName="hMGBNDate" readonly
                                                                   ngbDatepicker #hMGBNDate="ngbDatepicker" [maxDate]="today"
                                                                   (click)="hMGBNDate.toggle(); $event.stopPropagation();" />-->

                                                            </div>
                                                        </div>
                                                        <div class="d-flex">
                                                            <div class="form-group mb-1 col-5 d-flex align-items-baseline">
                                                                <label class="mb-1">OGTT</label>
                                                            </div>
                                                            <div class="col-7 form-group mb-1">
                                                                <textarea type="text" class="form-control" formControlName="oGTT"></textarea>
                                                            </div>
                                                        </div>
                                                        <div class="d-flex">
                                                            <div class="form-group mb-1 col-5 d-flex align-items-baseline">
                                                                <label class="mb-1">OGTT Date</label>
                                                            </div>
                                                            <div class="col-7 form-group mb-1">
                                                                <input type="date" class="form-control" placeholder="DD/MM/YYYY" formControlName="oGTTDate" />
                                                                <!--<input type="text" class="form-control date-picker" formControlName="oGTTDate" readonly
                                                                   ngbDatepicker #oGTTDate="ngbDatepicker" [maxDate]="today"
                                                                   (click)="oGTTDate.toggle(); $event.stopPropagation();" />-->
                                                            </div>
                                                        </div>
                                                        <div class="d-flex">
                                                            <div class="form-group mb-1 col-5 d-flex align-items-baseline">
                                                                <label class="mb-1">Outside Test</label>
                                                            </div>
                                                            <div class="col-7 form-group mb-1">
                                                                <textarea type="text" class="form-control" formControlName="outsideTest"></textarea>
                                                            </div>
                                                        </div>
                                                        <div class="headingStyleOb">
                                                            <div class="trapeziumOb font-weight-semibold text-white">
                                                                <span class="p-1">Diet History</span>
                                                            </div>
                                                        </div>

                                                        <div class="d-flex" style="margin-top:10px ">
                                                            <div class="form-group mb-1 col-5 d-flex align-items-baseline">
                                                                <label class="mb-1">Diet Preference</label>
                                                            </div>
                                                            <div class="col-7 form-group mb-1">
                                                                <select class="form-control " formControlName="dietPreference" autocomplete="nope">

                                                                    <option value="Vegetarian">Vegetarian</option>
                                                                    <option value="NonVegetarian">Non-Vegetarian</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class="d-flex">
                                                            <div class="form-group mb-1 col-5 d-flex align-items-baseline">
                                                                <label class="mb-1"></label>
                                                            </div>
                                                            <div class="col-7 form-group mb-1">
                                                                <div class="col-12 form-group mb-1" *ngFor="let item of dietPreferenceWith; let i = index">
                                                                    <div class="custom-control custom-checkbox">
                                                                        <input [attr.id]="'dietPreferenceWith' + i" class="custom-control-input" type="checkbox" [checked]="isSelecteddietPreferenceWith(item)" (change)="onChangedietPreferenceWith(item)">
                                                                        <label class="custom-control-label" [attr.for]="'dietPreferenceWith' + i" [textContent]="item"></label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="headingStyleOb">
                                                            <div class="trapeziumOb font-weight-semibold text-white">
                                                                <span class="p-1">Concerns</span>
                                                            </div>
                                                        </div>
                                                        <div class="d-flex" style="margin-top:10px ">
                                                            <div class="form-group mb-1 col-5 d-flex align-items-baseline">
                                                                <label class="mb-1">GI Health</label>
                                                            </div>

                                                            <div class="col-7 form-group mb-1">
                                                                <!--<select class="form-control " formControlName="giHealth" autocomplete="nope">
                                                                    <option value="null"></option>
                                                                    <option *ngFor="let item of yesOrNo">{{item}}</option>
                                                                </select>-->

                                                                <select class="form-control " formControlName="giHealth" (change)="onChangeGiHealth()" autocomplete="nope">
                                                                    <option value="No">No</option>
                                                                    <option value="Yes">Yes</option>
                                                                </select>
                                                            </div>
                                                        </div>

                                                        <div class="d-flex" *ngIf="form.giHealth.value == 'Yes'">
                                                            <div class="form-group mb-1 col-5 d-flex align-items-baseline">
                                                                <label class="mb-1">GI Health</label>
                                                            </div>
                                                            <div class="col-7 form-group mb-1">
                                                                <input type="text" class="form-control" formControlName="giHealthYes" autocomplete="nope" />
                                                            </div>
                                                        </div>

                                                        <div class="d-flex">
                                                            <div class="form-group mb-1 col-5 d-flex align-items-baseline">
                                                                <label class="mb-1">Dental</label>
                                                            </div>
                                                            <div class="col-7 form-group mb-1">
                                                                <select class="form-control " formControlName="dental" autocomplete="nope">

                                                                    <option value="No">No</option>
                                                                    <option value="Yes">Yes</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class="d-flex">
                                                            <div class="form-group mb-1 col-5 d-flex align-items-baseline">
                                                                <label class="mb-1">Any Other Concerns</label>
                                                            </div>
                                                            <div class="col-7 form-group mb-1">
                                                                <textarea class="form-control" formControlName="anyOtherConerns"></textarea>
                                                            </div>
                                                        </div>


                                                    </div>

                                                    <div class="col-12 col-xl-4">
                                                        <div class="headingStyleOb">
                                                            <div class="trapeziumOb font-weight-semibold text-white">
                                                                <span class="p-1">Food Allergies Or Intolerance</span>
                                                            </div>
                                                        </div>
                                                        <div class="d-flex" style="margin-top:10px ">
                                                            <div class="form-group mb-1 col-5 d-flex align-items-baseline">
                                                                <label class="mb-1">Milk</label>
                                                            </div>
                                                            <div class="col-7 form-group mb-1">
                                                                <select class="form-control " formControlName="milk" autocomplete="nope">
                                                                    <option value="No">No</option>
                                                                    <option value="Yes">Yes</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class="d-flex">
                                                            <div class="form-group mb-1 col-5 d-flex align-items-baseline">
                                                                <label class="mb-1">Nuts</label>
                                                            </div>
                                                            <div class="col-7 form-group mb-1">
                                                                <select class="form-control " formControlName="nuts" autocomplete="nope">
                                                                    <option value="No">No</option>
                                                                    <option value="Yes">Yes</option>
                                                                </select>
                                                            </div>
                                                        </div>

                                                        <div class="d-flex">
                                                            <div class="form-group mb-1 col-5 d-flex align-items-baseline">
                                                                <label class="mb-1">Likes</label>
                                                            </div>
                                                            <div class="col-7 form-group mb-1">
                                                                <input type="text" class="form-control" formControlName="likes" />
                                                            </div>
                                                        </div>
                                                        <div class="d-flex">
                                                            <div class="form-group mb-1 col-5 d-flex align-items-baseline">
                                                                <label class="mb-1">Dislikes</label>
                                                            </div>
                                                            <div class="col-7 form-group mb-1">
                                                                <input type="text" class="form-control" formControlName="dislikes" />
                                                            </div>
                                                        </div>
                                                        <div class="d-flex">
                                                            <div class="form-group mb-1 col-5 d-flex align-items-baseline">
                                                                <label class="mb-1">Other 1</label>
                                                            </div>
                                                            <div class="col-7 form-group mb-1">
                                                                <input type="text" class="form-control" formControlName="other1" />
                                                            </div>
                                                        </div>

                                                        <div class="headingStyleOb">
                                                            <div class="trapeziumOb font-weight-semibold text-white">
                                                                <span class="p-1">Diet Recall (Avg Of 4 Days)</span>
                                                            </div>
                                                        </div>
                                                        <div class="d-flex" style="margin-top:10px ">
                                                            <div class="form-group mb-1 col-5 d-flex align-items-baseline">
                                                                <label class="mb-1">Wake Up Time</label>
                                                            </div>
                                                            <div class="col-7 form-group mb-1">
                                                                <input type="time" class="form-control" formControlName="wakeUpTime" />
                                                            </div>
                                                        </div>
                                                        <div class="d-flex">
                                                            <div class="form-group mb-1 col-5 d-flex align-items-baseline">
                                                                <label class="mb-1">Sleeping Time</label>
                                                            </div>
                                                            <div class="col-7 form-group mb-1">
                                                                <input type="time" class="form-control" formControlName="sleepingTime" />
                                                            </div>
                                                        </div>

                                                        <div class="headingStyleOb">
                                                            <div class="trapeziumOb font-weight-semibold text-white">
                                                                <span class="p-1">Early Morning</span>
                                                            </div>
                                                        </div>
                                                        <div class="d-flex" style="margin-top:10px ">
                                                            <div class="form-group mb-1 col-5 d-flex align-items-baseline">
                                                                <label class="mb-1">Time</label>
                                                            </div>
                                                            <div class="col-7 form-group mb-1">
                                                                <input type="time" class="form-control" formControlName="morningTime" />
                                                            </div>
                                                        </div>
                                                        <div class="d-flex">
                                                            <div class="form-group mb-1 col-5 d-flex align-items-baseline">
                                                                <label class="mb-1">Milk</label>
                                                            </div>
                                                            <div class="col-7 form-group mb-1">
                                                                <select class="form-control " formControlName="morningMilk" (change)="onChangeMrgMilk()" autocomplete="nope">
                                                                    <option value="No">No</option>
                                                                    <option value="Yes">Yes</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class="d-flex" *ngIf="opNutritionalAssessment.value.morningMilk == 'Yes'">
                                                            <div class="form-group mb-1 col-5 d-flex align-items-baseline">
                                                                <label class="mb-1">Milk</label>
                                                            </div>
                                                            <div class="col-7 form-group mb-1">
                                                                <select class="form-control " formControlName="morningMilkOpt" autocomplete="nope">
                                                                    <option value="null"></option>
                                                                    <option value="150 ml">150 ml</option>
                                                                    <option value="200 ml">200 ml</option>
                                                                    <option value="250 ml">250 ml</option>
                                                                    <option value="100 ml">100 ml</option>
                                                                    <!--<option *ngFor="let item of morningMilkOpts">{{item}}</option>-->
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class="d-flex" *ngIf="form.morningMilk.value == 'Yes'">
                                                            <div class="form-group mb-1 col-5 d-flex align-items-baseline">
                                                                <label class="mb-1">Sugar (Tsp)</label>
                                                            </div>
                                                            <div class="col-7 form-group mb-1">
                                                                <select class="form-control " formControlName="morningMilkSugar" autocomplete="nope">
                                                                    <option value="null"></option>
                                                                    <option value="1tsp">1 tps</option>
                                                                    <option value="2tsp">2 tps</option>
                                                                    <option value="less then 1 tsp"> < 1 tps</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class="d-flex" *ngIf="form.morningMilk.value == 'Yes'">
                                                            <div class="form-group mb-1 col-5 d-flex align-items-baseline">
                                                                <label class="mb-1">Horlicks/ Boost/ Others (Tsp)</label>
                                                            </div>
                                                            <div class="col-7 form-group mb-1">
                                                                <select class="form-control " formControlName="morningMilkBoost" autocomplete="nope">
                                                                    <option value="null"></option>
                                                                    <option value="1tsp">1 tps</option>
                                                                    <option value="2tsp">2 tps</option>
                                                                    <option value="less then 1 tsp"> < 1 tps</option>
                                                                </select>
                                                            </div>
                                                        </div>

                                                        <div class="d-flex">
                                                            <div class="form-group mb-1 col-5 d-flex align-items-baseline">
                                                                <label class="mb-1">Dryfruits/ Nuts</label>
                                                            </div>
                                                            <div class="col-7 form-group mb-1">
                                                                <input type="text" class="form-control" formControlName="dryfruitsNuts" />
                                                            </div>
                                                        </div>
                                                        <div class="d-flex">
                                                            <div class="form-group mb-1 col-5 d-flex align-items-baseline">
                                                                <label class="mb-1">Any Others</label>
                                                            </div>
                                                            <div class="col-7 form-group mb-1">
                                                                <textarea type="text" class="form-control" formControlName="anyOther"></textarea>
                                                            </div>
                                                        </div>

                                                        <div class="headingStyleOb">
                                                            <div class="trapeziumOb font-weight-semibold text-white">
                                                                <span class="p-1">Break Fast</span>
                                                            </div>
                                                        </div>
                                                        <div class="d-flex" style="margin-top:10px ">
                                                            <div class="form-group mb-1 col-5 ">
                                                                <label class="mb-1">Time</label>
                                                            </div>
                                                            <div class="col-7 form-group mb-1">
                                                                <input type="time" class="form-control" formControlName="breakfasttime" />
                                                            </div>
                                                        </div>
                                                        <div class="d-flex">
                                                            <div class="form-group mb-1 col-5 d-flex align-items-baseline">
                                                                <label class="mb-1">Break Fast </label>
                                                            </div>
                                                            <div class="col-7 form-group mb-1">
                                                                <select class="form-control " formControlName="breakfast" (change)="onChangebreakfast()" autocomplete="nope">
                                                                    <option value="null"></option>
                                                                    <option value="Idli">Idli</option>
                                                                    <option value="Dosa">Dosa</option>
                                                                    <option value="Roti">Roti</option>
                                                                    <option value="Bread/Sanwich/Toast">Bread/Sanwich/Toast</option>
                                                                    <option value="Oats with milk">Oats with milk</option>
                                                                    <!--<option *ngFor="let item of breakfastOpts">{{item}}</option>-->
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class="d-flex" *ngIf="opNutritionalAssessment.value.breakfast == 'Idli'">
                                                            <div class="form-group mb-1 col-5 d-flex align-items-baseline">
                                                                <label class="mb-1">Idli (50 grms) medium size</label>
                                                            </div>
                                                            <div class="col-7 form-group mb-1">
                                                                <select class="form-control " formControlName="breakfastIdli" autocomplete="nope">
                                                                    <option value="null"></option>
                                                                    <option value="1">1</option>
                                                                    <option value="2">2</option>
                                                                    <option value="3">3</option>
                                                                    <option value="4">4</option>
                                                                    <option value="5">5</option>
                                                                    <option value="6">6</option>
                                                                    <option value="7">7</option>
                                                                    <option value="8">8</option>
                                                                    <option value="9">9</option>
                                                                    <option value="10">10</option>
                                                                    <!--<option *ngFor="let item of idliOpts">{{item}}</option>-->
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class="d-flex" *ngIf="opNutritionalAssessment.value.breakfast == 'Dosa'">
                                                            <div class="form-group mb-1 col-5 d-flex align-items-baseline">
                                                                <label class="mb-1">Dosa (30 grms)</label>
                                                            </div>
                                                            <div class="col-7 form-group mb-1">
                                                                <select class="form-control " formControlName="breakfastDosa" autocomplete="nope">
                                                                    <option value="null"></option>
                                                                    <option value="1">1</option>
                                                                    <option value="2">2</option>
                                                                    <option value="3">3</option>
                                                                    <option value="4">4</option>
                                                                    <!--<option *ngFor="let item of dosaOpts">{{item}}</option>-->
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class="d-flex" *ngIf="opNutritionalAssessment.value.breakfast == 'Roti'">
                                                            <div class="form-group mb-1 col-5 d-flex align-items-baseline">
                                                                <label class="mb-1">Roti (30g) each</label>
                                                            </div>
                                                            <div class="col-7 form-group mb-1">
                                                                <select class="form-control " formControlName="breakfastRoti" autocomplete="nope">
                                                                    <option value="null"></option>
                                                                    <option value="1">1</option>
                                                                    <option value="2">2</option>
                                                                    <option value="3">3</option>
                                                                    <!--<option *ngFor="let item of rotiOpts">{{item}}</option>-->
                                                                </select>
                                                            </div>
                                                        </div>


                                                        <div class="d-flex" *ngIf="opNutritionalAssessment.value.breakfast == 'Bread/Sanwich/Toast'">
                                                            <div class="form-group mb-1 col-5 d-flex align-items-baseline">
                                                                <label class="mb-1">Slice</label>
                                                            </div>
                                                            <div class="col-7 form-group mb-1">
                                                                <select class="form-control " formControlName="breakfastSlice" autocomplete="nope">
                                                                    <option value="null"></option>
                                                                    <option value="1 slice">1 slice</option>
                                                                    <option value="2 slice">2 slice</option>
                                                                    <option value="3 slice">3 slice</option>
                                                                    <option value="4 slice">4 slice</option>
                                                                    <option value="Others">Others</option>
                                                                    <!--<option *ngFor="let item of breadOpts">{{item}}</option>-->
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class="d-flex" *ngIf="opNutritionalAssessment.value.breakfastSlice == 'Others'">
                                                            <div class="form-group mb-1 col-5 d-flex align-items-baseline">
                                                                <label class="mb-1">Others</label>
                                                            </div>
                                                            <div class="col-7 form-group mb-1">
                                                                <input type="text" class="form-control" formControlName="breakfastSliceOther" autocomplete="nope" />

                                                            </div>
                                                        </div>
                                                        <div class="d-flex" *ngIf="opNutritionalAssessment.value.breakfast == 'Oats with milk'">
                                                            <div class="form-group mb-1 col-5 d-flex align-items-baseline">
                                                                <label class="mb-1">Oats</label>
                                                            </div>
                                                            <div class="col-7 form-group mb-1">
                                                                <select class="form-control " formControlName="breakfastOats" autocomplete="nope">
                                                                    <option value="null"></option>
                                                                    <option value="Others">Others</option>
                                                                    <option value="200 ml">200 ml</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class="d-flex" *ngIf="form.breakfastOats.value == 'Others'">
                                                            <div class="form-group mb-1 col-5 d-flex align-items-baseline">
                                                                <label class="mb-1">Oats</label>
                                                            </div>
                                                            <div class="col-7 form-group mb-1">
                                                                <input type="text" class="form-control" formControlName="breakfastOatsOther" autocomplete="nope" />
                                                            </div>
                                                        </div>
                                                        <!--<div class="d-flex">
                                                            <div class="form-group mb-1 col-5 d-flex align-items-baseline">
                                                                <label class="mb-1">Chutney</label>
                                                            </div>
                                                            <div class="col-7 form-group mb-1">
                                                                <input type="text" class="form-control" formControlName="chutney" autocomplete="nope" />
                                                            </div>
                                                        </div>-->
                                                        <div class="d-flex" style="margin-top:10px ">
                                                            <div class="form-group mb-1 col-5 d-flex align-items-baseline">
                                                                <label class="mb-1">Chutney </label>
                                                            </div>
                                                            <div class="col-7 form-group mb-1">                                                             
                                                                <div class="form-group mb-1 col-12">
                                                                    <input type="radio" value="Peanut" name="chutney" formControlName="chutney" (change)="onChangeType()" />&nbsp;
                                                                    <label class="radio" for="Peanut"> Peanut </label>
                                                                </div>
                                                                <div class="form-group mb-1 col-12">
                                                                    <input type="radio" value="Putana" name="chutney" formControlName="chutney" (change)="onChangeType()" />&nbsp;
                                                                    <label class="radio" for="Putana"> Putana </label>
                                                                </div>
                                                                <div class="form-group mb-1 col-12">
                                                                    <input type="radio" value="Veg" name="chutney" formControlName="chutney" (change)="onChangeType()" />&nbsp;
                                                                    <label class="radio" for="Veg"> Veg </label>
                                                                </div>
                                                                <div class="form-group mb-1 col-12">
                                                                    <input type="radio" value="Coconut Chutney" name="chutney" formControlName="chutney" (change)="onChangeType()" />&nbsp;
                                                                    <label class="radio" for="Coconut Chutney"> Coconut Chutney </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div *ngIf="form.chutney.value == 'Coconut Chutney'">
                                                            <div class="d-flex" style="margin-top:10px ">
                                                                <div class="form-group mb-1 col-5 d-flex align-items-baseline">
                                                                    <label class="mb-1">Quantity </label>
                                                                </div>
                                                                <div class="col-7 form-group mb-1">
                                                                    <input type="text" class="form-control" formControlName="quantity" />
                                                                </div>
                                                            </div>
                                                        </div>



                                                        <div class="d-flex">
                                                            <div class="form-group mb-1 col-5 d-flex align-items-baseline">
                                                                <label class="mb-1">Veg Curry</label>
                                                            </div>
                                                            <div class="col-7 form-group mb-1">
                                                                <select class="form-control " formControlName="vegCurry" (change)="changeBreakfastVeg()" autocomplete="nope">
                                                                    <option value="No">No</option>
                                                                    <option value="Yes">Yes</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class="d-flex" *ngIf="form.vegCurry.value == 'Yes'">
                                                            <div class="form-group mb-1 col-5 d-flex align-items-baseline">
                                                                <label class="mb-1">Cooked(200ml)</label>
                                                            </div>
                                                            <div class="col-7 form-group mb-1">
                                                                <select class="form-control " formControlName="cooked" autocomplete="nope">
                                                                    <option value="Null"></option>
                                                                    <option value="1/4 cup">1/4 cup</option>
                                                                    <option value="1/2 cup">1/2 cup</option>
                                                                    <option value="1 cup">1 cup</option>
                                                                    <!--<option *ngFor="let item of breakfastDalOpts">{{item}}</option>-->
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class="d-flex">
                                                            <div class="form-group mb-1 col-5 d-flex align-items-baseline">
                                                                <label class="mb-1">Dal</label>
                                                            </div>
                                                            <div class="col-7 form-group mb-1">
                                                                <select class="form-control " formControlName="dal" autocomplete="nope">
                                                                    <option value="Null"></option>
                                                                    <option value="1/4 cup">1/4 cup</option>
                                                                    <option value="1/2 cup">1/2 cup</option>
                                                                    <option value="1 cup">1 cup</option>
                                                                    <!--<option *ngFor="let item of breakfastDalOpts">{{item}}</option>-->
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class="d-flex">
                                                            <div class="form-group mb-1 col-5 d-flex align-items-baseline">
                                                                <label class="mb-1">Any Others</label>
                                                            </div>
                                                            <div class="col-7 form-group mb-1">
                                                                <textarea type="text" class="form-control" formControlName="breakfastOther"></textarea>
                                                            </div>
                                                        </div>
                                                        <div class="headingStyleOb">
                                                            <div class="trapeziumOb font-weight-semibold text-white">
                                                                <span class="p-1">Mid Morning</span>
                                                            </div>
                                                        </div>

                                                        <div class="d-flex" style="margin-top:10px ">
                                                            <div class="form-group mb-1 col-5 d-flex align-items-baseline">
                                                                <label class="mb-1">Time</label>
                                                            </div>
                                                            <div class="col-7 form-group mb-1">
                                                                <input type="time" class="form-control" formControlName="midMorningtime" />
                                                            </div>
                                                        </div>
                                                        <div class="d-flex">
                                                            <div class="form-group mb-1 col-5 d-flex align-items-baseline">
                                                                <label class="mb-1"> Fruits </label>
                                                            </div>
                                                            <div class="col-7 form-group mb-1">
                                                                <select class="form-control " formControlName="fruits" (change)="changeMidMrgFruits()" autocomplete="nope">
                                                                    <option value="No">No</option>
                                                                    <option value="Yes">Yes</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class="d-flex" *ngIf="opNutritionalAssessment.value.fruits == 'Yes'">
                                                            <div class="form-group mb-1 col-5 d-flex align-items-baseline">
                                                                <label class="mb-1">Fruit Type</label>
                                                            </div>
                                                            <div class="col-7 form-group mb-1">
                                                                <input type="text" class="form-control" formControlName="fruitType" autocomplete="nope" />
                                                            </div>
                                                        </div>
                                                        <div class="d-flex">
                                                            <div class="form-group mb-1 col-5 d-flex align-items-baseline">
                                                                <label class="mb-1">Fruit Juice </label>
                                                            </div>
                                                            <div class="col-7 form-group mb-1">
                                                                <select class="form-control " formControlName="fruitsJuice" (change)="changeMidMrgJuice()" autocomplete="nope">
                                                                    <option value="No">No</option>
                                                                    <option value="Yes">Yes</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class="d-flex" *ngIf="opNutritionalAssessment.value.fruitsJuice == 'Yes'">
                                                            <div class="form-group mb-1 col-5 d-flex align-items-baseline">
                                                                <label class="mb-1">Fruit Juice </label>
                                                            </div>
                                                            <div class="col-7 form-group mb-1">
                                                                <select class="form-control " formControlName="fruitJuiceQua" autocomplete="nope">
                                                                    <option value="null"></option>
                                                                    <option value="100 ml">100 ml</option>
                                                                    <option value="200 ml">200 ml</option>
                                                                    <option value="250 ml">250 ml</option>
                                                                    <option value="Others">Others</option>
                                                                    <!--<option *ngFor="let item of fruitjuiceOpts">{{item}}</option>-->
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class="d-flex" *ngIf="opNutritionalAssessment.value.fruitJuiceQua == 'Others'">
                                                            <div class="form-group mb-1 col-5 d-flex align-items-baseline">
                                                                <label class="mb-1">Others</label>
                                                            </div>
                                                            <div class="col-7 form-group mb-1">
                                                                <input type="text" class="form-control" formControlName="fruitJuiceOther" autocomplete="nope" />
                                                            </div>
                                                        </div>
                                                        <div class="d-flex">
                                                            <div class="form-group mb-1 col-5 d-flex align-items-baseline">
                                                                <label class="mb-1">Tea</label>
                                                            </div>
                                                            <div class="col-7 form-group mb-1">
                                                                <select class="form-control " formControlName="tea" (change)="onChangeTea()" autocomplete="nope">
                                                                    <option value="No">No</option>
                                                                    <option value="Yes">Yes</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class="d-flex" *ngIf="opNutritionalAssessment.value.tea == 'Yes'">
                                                            <div class="form-group mb-1 col-5 d-flex align-items-baseline">
                                                                <label class="mb-1">Tea</label>
                                                            </div>
                                                            <div class="col-7 form-group mb-1">
                                                                <input type="text" class="form-control" formControlName="teaQua" autocomplete="nope" />
                                                            </div>
                                                        </div>

                                                        <div class="d-flex">
                                                            <div class="form-group mb-1 col-5 d-flex align-items-baseline">
                                                                <label class="mb-1">Any Others</label>
                                                            </div>
                                                            <div class="col-7 form-group mb-1">
                                                                <textarea type="text" class="form-control" formControlName="midMorningOther"></textarea>
                                                            </div>
                                                        </div>
                                                        <div class="headingStyleOb">
                                                            <div class="trapeziumOb font-weight-semibold text-white">
                                                                <span class="p-1">Lunch</span>
                                                            </div>
                                                        </div>
                                                        <div class="d-flex" style="margin-top:10px ">
                                                            <div class="form-group mb-1 col-5 d-flex align-items-baseline">
                                                                <label class="mb-1">Time</label>
                                                            </div>
                                                            <div class="col-7 form-group mb-1">
                                                                <input type="time" class="form-control" formControlName="lunchtime" />
                                                            </div>
                                                        </div>
                                                        <div class="d-flex">
                                                            <div class="form-group mb-1 col-5 d-flex align-items-baseline">
                                                                <label class="mb-1">Cereals / Millet'S </label>
                                                            </div>
                                                            <div class="col-7 form-group mb-1">
                                                                <select class="form-control " formControlName="carealsMilletS" (change)="onChangeCarealsMillet()" autocomplete="nope">
                                                                    <option value="null"></option>
                                                                    <option value="Yes">Yes</option>
                                                                    <option value="Rice">Rice</option>
                                                                    <option value="Brown Rice (cooked 200 ml)">Brown Rice (cooked 200 ml)</option>
                                                                    <option value="Jowar Roti">Jowar Roti</option>
                                                                    <option value="Wheat rava (cooked 200 ml)">Wheat rava (cooked 200 ml)</option>
                                                                    <option value="Millets (cooked 200 ml)">Millets (cooked 200 ml)</option>
                                                                    <option value="Chapathi">Chapathi</option>
                                                                    <option value="Phulkas">Phulkas</option>
                                                                    <!--<option *ngFor="let item of carealsOpts">{{item}}</option>-->
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class="d-flex" *ngIf="opNutritionalAssessment.value.carealsMilletS == 'Rice'">
                                                            <div class="form-group mb-1 col-5 d-flex align-items-baseline">
                                                                <label class="mb-1">Rice</label>
                                                            </div>
                                                            <div class="col-7 form-group mb-1">
                                                                <select class="form-control " formControlName="lunceRice" autocomplete="nope">
                                                                    <option value="null"></option>
                                                                    <option value="1 cup">1 cup</option>
                                                                    <option value="2 cups">2 cups</option>
                                                                    <option value="3 cups">3 cups</option>
                                                                    <option value="1.5 cups">1.5 cups</option>
                                                                    <option value="2.5 cups">2.5 cups</option>
                                                                    <option value="3.5 cups">3.5 cups</option>
                                                                    <!--<option *ngFor="let item of riceOpts">{{item}}</option>-->
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class="d-flex" *ngIf="opNutritionalAssessment.value.carealsMilletS == 'Brown Rice (cooked 200 ml)'">
                                                            <div class="form-group mb-1 col-5 d-flex align-items-baseline">
                                                                <label class="mb-1">Brown Rice (cooked 200 ml)</label>
                                                            </div>
                                                            <div class="col-7 form-group mb-1">
                                                                <select class="form-control " formControlName="lunceBrownRice" autocomplete="nope">
                                                                    <option value="null"></option>
                                                                    <option value="1 cup">1 cup</option>
                                                                    <option value="2 cups">2 cups</option>
                                                                    <option value="3 cups">3 cups</option>
                                                                    <option value="1.5 cups">1.5 cups</option>
                                                                    <option value="2.5 cups">2.5 cups</option>
                                                                    <option value="3.5 cups">3.5 cups</option>
                                                                    <!--<option *ngFor="let item of riceOpts">{{item}}</option>-->
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class="d-flex" *ngIf="opNutritionalAssessment.value.carealsMilletS == 'Jowar Roti'">
                                                            <div class="form-group mb-1 col-5 d-flex align-items-baseline">
                                                                <label class="mb-1">Roti (30g each)</label>
                                                            </div>
                                                            <div class="col-7 form-group mb-1">
                                                                <select class="form-control " formControlName="lunceroti" autocomplete="nope">
                                                                    <option value="null"></option>
                                                                    <option value="1">1 </option>
                                                                    <option value="2">2 </option>
                                                                    <option value="3">3 </option>
                                                                    <!--<option *ngFor="let item of rotiOpts">{{item}}</option>-->
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class="d-flex" *ngIf="opNutritionalAssessment.value.carealsMilletS == 'Wheat rava (cooked 200 ml)'">
                                                            <div class="form-group mb-1 col-5 d-flex align-items-baseline">
                                                                <label class="mb-1">Wheat rava (cooked 200 ml)</label>
                                                            </div>
                                                            <div class="col-7 form-group mb-1">
                                                                <select class="form-control " formControlName="lunceWheatrawa" autocomplete="nope">
                                                                    <option value="null"></option>
                                                                    <option value="Yes">Yes</option>
                                                                    <!--<option *ngFor="let item of rawaOpts">{{item}}</option>-->
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class="d-flex" *ngIf="opNutritionalAssessment.value.carealsMilletS == 'Millets (cooked 200 ml)'">
                                                            <div class="form-group mb-1 col-5 d-flex align-items-baseline">
                                                                <label class="mb-1">Millets (cooked 200 ml)</label>
                                                            </div>
                                                            <div class="col-7 form-group mb-1">
                                                                <select class="form-control " formControlName="lunceMillets" autocomplete="nope">
                                                                    <option value="null"></option>
                                                                    <option value="Yes">Yes</option>
                                                                    <option value="1 cup">1 cup</option>
                                                                    <option value="2 cups">2 cups</option>
                                                                    <option value="3 cups">3 cups</option>
                                                                    <option value="1.5 cups">1.5 cups</option>
                                                                    <option value="2.5 cups">2.5 cups</option>
                                                                    <option value="3.5 cups">3.5 cups</option>
                                                                    <!--<option *ngFor="let item of milletsOpts">{{item}}</option>-->
                                                                </select>
                                                            </div>
                                                        </div>

                                                        <div class="d-flex" *ngIf="opNutritionalAssessment.value.carealsMilletS == 'Chapathi'">
                                                            <div class="form-group mb-1 col-5 d-flex align-items-baseline">
                                                                <label class="mb-1">Chapathi</label>
                                                            </div>
                                                            <div class="col-7 form-group mb-1">
                                                                <select class="form-control " formControlName="lunceChapathi" autocomplete="nope">
                                                                    <option value="null"></option>
                                                                    <option value="1">1</option>
                                                                    <option value="2">2</option>
                                                                    <option value="3">3</option>
                                                                    <option value="4">4</option>
                                                                    <!--<option *ngFor="let item of dosaOpts">{{item}}</option>-->
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class="d-flex" *ngIf="opNutritionalAssessment.value.carealsMilletS == 'Phulkas'">
                                                            <div class="form-group mb-1 col-5 d-flex align-items-baseline">
                                                                <label class="mb-1">Phulkas</label>
                                                            </div>
                                                            <div class="col-7 form-group mb-1">
                                                                <select class="form-control " formControlName="luncePulka" autocomplete="nope">
                                                                    <option value="null"></option>
                                                                    <option value="1">1</option>
                                                                    <option value="2">2</option>
                                                                    <option value="3">3</option>
                                                                    <option value="4">4</option>
                                                                    <!--<option *ngFor="let item of dosaOpts">{{item}}</option>-->
                                                                </select>
                                                            </div>
                                                        </div>


                                                        <div class="d-flex">
                                                            <div class="form-group mb-1 col-5 d-flex align-items-baseline">
                                                                <label class="mb-1">Veg Curry</label>
                                                            </div>
                                                            <div class="col-7 form-group mb-1">
                                                                <select class="form-control " formControlName="lunchvegCurry" (change)="onChangeLunchVeg()" autocomplete="nope">
                                                                    <option value="Yes">Yes</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class="d-flex" *ngIf="opNutritionalAssessment.value.lunchvegCurry == 'Yes'">
                                                            <div class="form-group mb-1 col-5 d-flex align-items-baseline">
                                                                <label class="mb-1">Veg Curry (cooked 200ml)</label>
                                                            </div>
                                                            <div class="col-7 form-group mb-1">
                                                                <select class="form-control " formControlName="lunchvegCooked" autocomplete="nope">
                                                                    <option value="null"></option>
                                                                    <option value="1/4 cup">1/4 cup</option>
                                                                    <option value="1/2 cup">1/2 cup</option>
                                                                    <option value="1 cup1">1 cup</option>
                                                                    <option value="1/3 cup">1/3 cup</option>
                                                                    <option value="3/4 cup">3/4 cup</option>
                                                                    <!--<option *ngFor="let item of vegCurryOpts">{{item}}</option>-->
                                                                </select>
                                                            </div>
                                                        </div>

                                                        <div class="d-flex">
                                                            <div class="form-group mb-1 col-5 d-flex align-items-baseline">
                                                                <label class="mb-1">Green Leafy</label>
                                                            </div>
                                                            <div class="col-7 form-group mb-1">
                                                                <select class="form-control " formControlName="greenLeafy" (change)="onChangeLunchLeafy()" autocomplete="nope">

                                                                    <option value="Yes">Yes</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class="d-flex" *ngIf="opNutritionalAssessment.value.greenLeafy == 'Yes'">
                                                            <div class="form-group mb-1 col-5 d-flex align-items-baseline">
                                                                <label class="mb-1">Green Leafy (Cooked 200ml)</label>
                                                            </div>
                                                            <div class="col-7 form-group mb-1">
                                                                <select class="form-control " formControlName="greenLeafyCooked" autocomplete="nope">
                                                                    <option value="null"></option>
                                                                    <option value="1/4 cup">1/4 cup</option>
                                                                    <option value="1/2 cup">1/2 cup</option>
                                                                    <option value="1 cup1">1 cup</option>
                                                                    <option value="1/3 cup">1/3 cup</option>
                                                                    <option value="3/4 cup">3/4 cup</option>
                                                                    <!--<option *ngFor="let item of vegCurryOpts">{{item}}</option>-->
                                                                </select>
                                                            </div>
                                                        </div>

                                                        <div class="d-flex">
                                                            <div class="form-group mb-1 col-5 d-flex align-items-baseline">
                                                                <label class="mb-1">Dal</label>
                                                            </div>
                                                            <div class="col-7 form-group mb-1">
                                                                <select class="form-control " formControlName="lunchdal" (change)="onChangeLunchDal()" autocomplete="nope">
                                                                    <option value="Yes">Yes</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class="d-flex" *ngIf="opNutritionalAssessment.value.lunchdal == 'Yes'">
                                                            <div class="form-group mb-1 col-5 d-flex align-items-baseline">
                                                                <label class="mb-1">Dal (cooked 200ml)</label>
                                                            </div>
                                                            <div class="col-7 form-group mb-1">
                                                                <select class="form-control " formControlName="lunchdalCooked" autocomplete="nope">
                                                                    <option value="null"></option>
                                                                    <option value="1/4 cup">1/4 cup</option>
                                                                    <option value="1/2 cup">1/2 cup</option>
                                                                    <option value="1 cup1">1 cup</option>
                                                                    <option value="1/3 cup">1/3 cup</option>
                                                                    <option value="3/4 cup">3/4 cup</option>
                                                                    <!--<option *ngFor="let item of vegCurryOpts">{{item}}</option>-->
                                                                </select>
                                                            </div>
                                                        </div>

                                                        <div class="d-flex">
                                                            <div class="form-group mb-1 col-5 d-flex align-items-baseline">
                                                                <label class="mb-1">Paneer</label>
                                                            </div>
                                                            <div class="col-7 form-group mb-1">
                                                                <select class="form-control " formControlName="panner" (change)="onChangeLunchPaneer()" autocomplete="nope">
                                                                    <option value="Yes">Yes</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class="d-flex" *ngIf="opNutritionalAssessment.value.panner == 'Yes'">
                                                            <div class="form-group mb-1 col-5 d-flex align-items-baseline">
                                                                <label class="mb-1">Paneer</label>
                                                            </div>
                                                            <div class="col-7 form-group mb-1">
                                                                <select class="form-control " formControlName="pannerPcs" autocomplete="nope">
                                                                    <option value="null"></option>
                                                                    <option value="2 pc">2 pc</option>
                                                                    <option value="3 pc">3 pc</option>
                                                                    <option value="4 pc">4 pc</option>
                                                                    <option value="5 pc">5 pc</option>
                                                                    <option value="6 pc">6 pc</option>
                                                                    <option value="Others">Others</option>
                                                                    <!--<option *ngFor="let item of paneerOpts">{{item}}</option>-->
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class="d-flex" *ngIf="opNutritionalAssessment.value.pannerPcs == 'Other'">
                                                            <div class="form-group mb-1 col-5 d-flex align-items-baseline">
                                                                <label class="mb-1">Others</label>
                                                            </div>
                                                            <div class="col-7 form-group mb-1">
                                                                <input type="text" class="form-control " formControlName="pannerOther" autocomplete="nope" />

                                                            </div>
                                                        </div>

                                                        <div class="d-flex">
                                                            <div class="form-group mb-1 col-5 d-flex align-items-baseline">
                                                                <label class="mb-1">Non-Veg</label>
                                                            </div>
                                                            <div class="col-7 form-group mb-1">
                                                                <select class="form-control " formControlName="lunchNonVeg" (change)="onChangeLunchnonVeg()" autocomplete="nope">

                                                                    <option value="Yes">Yes</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class="d-flex" *ngIf="opNutritionalAssessment.value.lunchNonVeg == 'Yes'">
                                                            <div class="form-group mb-1 col-5 d-flex align-items-baseline">
                                                                <label class="mb-1">Non-Veg (Pcs)</label>
                                                            </div>
                                                            <div class="col-7 form-group mb-1">
                                                                <select class="form-control " formControlName="lunchNonVegPcs" autocomplete="nope">
                                                                    <option value="Null"></option>
                                                                    <option value="2-3 pcs">2-3 pcs</option>
                                                                    <option value="4-5 pcs">4-5 pcs</option>
                                                                    <option value="Others">Others</option>
                                                                    <!--<option *ngFor="let item of lunchNonVegOpts">{{item}}</option>-->
                                                                </select>
                                                            </div>
                                                        </div>

                                                        <div class="d-flex" *ngIf="opNutritionalAssessment.value.lunchNonVegPcs == 'Others'">
                                                            <div class="form-group mb-1 col-5 d-flex align-items-baseline">
                                                                <label class="mb-1">Others</label>
                                                            </div>
                                                            <div class="col-7 form-group mb-1">
                                                                <input type="text" class="form-control " formControlName="lunchNonVegOther" autocomplete="nope" />

                                                            </div>
                                                        </div>

                                                        <div class="d-flex">
                                                            <div class="form-group mb-1 col-5 d-flex align-items-baseline">
                                                                <label class="mb-1">Curd</label>
                                                            </div>
                                                            <div class="col-7 form-group mb-1">
                                                                <select class="form-control " formControlName="lunchcurd" autocomplete="nope">
                                                                    <option value="null"></option>
                                                                    <option value="100 ml">100 ml</option>
                                                                    <option value="200 ml">200 ml</option>
                                                                    <option value="Less than 100 ml">Less than 100 ml</option>
                                                                    <option value="No">No</option>
                                                                    <!--<option *ngFor="let item of curdOpts">{{item}}</option>-->
                                                                </select>
                                                            </div>
                                                        </div>

                                                        <div class="d-flex">
                                                            <div class="form-group mb-1 col-5 d-flex align-items-baseline">
                                                                <label class="mb-1">Any Others</label>
                                                            </div>
                                                            <div class="col-7 form-group mb-1">
                                                                <textarea type="text" class="form-control" formControlName="lunchOther"></textarea>
                                                            </div>
                                                        </div>
                                                        <div class="headingStyleOb">
                                                            <div class="trapeziumOb font-weight-semibold text-white">
                                                                <span class="p-1">Mid Evening</span>
                                                            </div>
                                                        </div>
                                                        <div class="d-flex" style="margin-top:10px ">
                                                            <div class="form-group mb-1 col-5 d-flex align-items-baseline">
                                                                <label class="mb-1">Time</label>
                                                            </div>
                                                            <div class="col-7 form-group mb-1">
                                                                <input type="time" class="form-control" formControlName="eveningtime" />
                                                            </div>
                                                        </div>
                                                        <div class="d-flex">
                                                            <div class="form-group mb-1 col-5 d-flex align-items-baseline">
                                                                <label class="mb-1">Fruits</label>
                                                            </div>
                                                            <div class="col-7 form-group mb-1">
                                                                <select class="form-control " formControlName="eveningfruits" (change)="changeEvgFruit()" autocomplete="nope">
                                                                    <option value="Yes">Yes</option>
                                                                    <option value="No">No</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class="d-flex" *ngIf="opNutritionalAssessment.value.eveningfruits == 'Yes'">
                                                            <div class="form-group mb-1 col-5 d-flex align-items-baseline">
                                                                <label class="mb-1">Other Fruits</label>
                                                            </div>
                                                            <div class="col-7 form-group mb-1">
                                                                <input type="text" class="form-control" formControlName="eveningfruitsOther" autocomplete="nope" />
                                                            </div>
                                                        </div>
                                                        <div class="d-flex">
                                                            <div class="form-group mb-1 col-5 d-flex align-items-baseline">
                                                                <label class="mb-1">Fruit Juice</label>
                                                            </div>
                                                            <div class="col-7 form-group mb-1">
                                                                <select class="form-control " formControlName="eveningfruitJuice" (change)="changeEvgFruitJuice()" autocomplete="nope">

                                                                    <option value="Yes">Yes</option>
                                                                    <option value="No">No</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class="d-flex" *ngIf="opNutritionalAssessment.value.eveningfruitJuice == 'Yes'">
                                                            <div class="form-group mb-1 col-5 d-flex align-items-baseline">
                                                                <label class="mb-1">Fruit Juice</label>
                                                            </div>
                                                            <div class="col-7 form-group mb-1">
                                                                <select class="form-control " formControlName="eveningfruitsJuice" autocomplete="nope">
                                                                    <option value="null"></option>
                                                                    <option value="100 ml">100 ml</option>
                                                                    <option value="200 ml">200 ml</option>
                                                                    <option value="250 ml">250 ml</option>
                                                                    <option value="Others">Others</option>
                                                                    <!--<option *ngFor="let item of fruitjuiceOpts">{{item}}</option>-->
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class="d-flex" *ngIf="opNutritionalAssessment.value.eveningfruitsJuice == 'Others'">
                                                            <div class="form-group mb-1 col-5 d-flex align-items-baseline">
                                                                <label class="mb-1">Others</label>
                                                            </div>
                                                            <div class="col-7 form-group mb-1">
                                                                <input type="text" class="form-control " formControlName="eveningfruitsJuiceOther" autocomplete="nope" />

                                                            </div>
                                                        </div>
                                                        <div class="d-flex">
                                                            <div class="form-group mb-1 col-5 d-flex align-items-baseline">
                                                                <label class="mb-1">Samosa / Fried item </label>
                                                            </div>
                                                            <div class="col-7 form-group mb-1">
                                                                <select class="form-control " formControlName="eveningSamosa" (change)="changeEvgSamosa()" autocomplete="nope">
                                                                    <option value="Yes">Yes</option>
                                                                    <option value="No">No</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class="d-flex" *ngIf="opNutritionalAssessment.value.eveningSamosa == 'Yes'">
                                                            <div class="form-group mb-1 col-5 d-flex align-items-baseline">
                                                                <label class="mb-1">Type Item Name</label>
                                                            </div>
                                                            <div class="col-7 form-group mb-1">
                                                                <input type="text" class="form-control" formControlName="eveningSamosaName" autocomplete="nope" />
                                                            </div>
                                                        </div>

                                                        <div class="d-flex">
                                                            <div class="form-group mb-1 col-5 d-flex align-items-baseline">
                                                                <label class="mb-1">Bakery Item</label>
                                                            </div>
                                                            <div class="col-7 form-group mb-1">
                                                                <select class="form-control " formControlName="eveningBakery" (change)="changeEvgBakery()" autocomplete="nope">
                                                                    <option value="Yes">Yes</option>
                                                                    <option value="No">No</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class="d-flex" *ngIf="opNutritionalAssessment.value.eveningBakery == 'Yes'">
                                                            <div class="form-group mb-1 col-5 d-flex align-items-baseline">
                                                                <label class="mb-1">Type Item Name</label>
                                                            </div>
                                                            <div class="col-7 form-group mb-1">
                                                                <input type="text" class="form-control" formControlName="eveningBakeryName" autocomplete="nope" />
                                                            </div>
                                                        </div>
                                                        <div class="d-flex">
                                                            <div class="form-group mb-1 col-5 d-flex align-items-baseline">
                                                                <label class="mb-1">Any Other</label>
                                                            </div>
                                                            <div class="col-7 form-group mb-1">
                                                                <textarea type="text" class="form-control" formControlName="eveningOther"></textarea>
                                                            </div>
                                                        </div>


                                                        <div class="headingStyleOb">
                                                            <div class="trapeziumOb font-weight-semibold text-white">
                                                                <span class="p-1">Dinner</span>
                                                            </div>
                                                        </div>
                                                        <div class="d-flex" style="margin-top:10px ">
                                                            <div class="form-group mb-1 col-5 d-flex align-items-baseline">
                                                                <label class="mb-1">Time</label>
                                                            </div>
                                                            <div class="col-7 form-group mb-1">
                                                                <input type="time" class="form-control" formControlName="dinnertime" />
                                                            </div>
                                                        </div>
                                                        <div class="d-flex">
                                                            <div class="form-group mb-1 col-5 d-flex align-items-baseline">
                                                                <label class="mb-1">Cereals / Millet'S </label>
                                                            </div>
                                                            <div class="col-7 form-group mb-1">
                                                                <select class="form-control " formControlName="dinnercarealsMilletS" (change)="changeDinnerCereals()" autocomplete="nope">
                                                                    <option value="null"></option>
                                                                    <option value="Yes">Yes</option>
                                                                    <option value="Rice">Rice</option>
                                                                    <option value="Brown Rice (cooked 200 ml)">Brown Rice (cooked 200 ml)</option>
                                                                    <option value="Jowar Roti">Jowar Roti</option>
                                                                    <option value="Wheat rava (cooked 200 ml)">Wheat rava (cooked 200 ml)</option>
                                                                    <option value="Millets (cooked 200 ml)">Millets (cooked 200 ml)</option>
                                                                    <option value="Chapathi">Chapathi</option>
                                                                    <option value="Phulkas">Phulkas</option>
                                                                    <!--<option *ngFor="let item of carealsOpts">{{item}}</option>-->
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class="d-flex" *ngIf="opNutritionalAssessment.value.dinnercarealsMilletS == 'Rice'">
                                                            <div class="form-group mb-1 col-5 d-flex align-items-baseline">
                                                                <label class="mb-1">Rice</label>
                                                            </div>
                                                            <div class="col-7 form-group mb-1">
                                                                <select class="form-control " formControlName="dinnerRice" autocomplete="nope">
                                                                    <option value="null"></option>
                                                                    <option value="1 cup">1 cup</option>
                                                                    <option value="2 cups">2 cups</option>
                                                                    <option value="3 cups">3 cups</option>
                                                                    <option value="1.5 cups">1.5 cups</option>
                                                                    <option value="2.5 cups">2.5 cups</option>
                                                                    <option value="3.5 cups">3.5 cups</option>
                                                                    <!--<option *ngFor="let item of riceOpts">{{item}}</option>-->
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class="d-flex" *ngIf="opNutritionalAssessment.value.dinnercarealsMilletS == 'Brown Rice (cooked 200 ml)'">
                                                            <div class="form-group mb-1 col-5 d-flex align-items-baseline">
                                                                <label class="mb-1">Brown Rice (cooked 200ml)</label>
                                                            </div>
                                                            <div class="col-7 form-group mb-1">
                                                                <select class="form-control " formControlName="dinnerBrownRice" autocomplete="nope">
                                                                    <option value="null"></option>
                                                                    <option value="1 cup">1 cup</option>
                                                                    <option value="2 cups">2 cups</option>
                                                                    <option value="3 cups">3 cups</option>
                                                                    <option value="1.5 cups">1.5 cups</option>
                                                                    <option value="2.5 cups">2.5 cups</option>
                                                                    <option value="3.5 cups">3.5 cups</option>
                                                                    <!--<option *ngFor="let item of riceOpts">{{item}}</option>-->
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class="d-flex" *ngIf="opNutritionalAssessment.value.dinnercarealsMilletS == 'Jowar Roti'">
                                                            <div class="form-group mb-1 col-5 d-flex align-items-baseline">
                                                                <label class="mb-1">Roti (30g each)</label>
                                                            </div>
                                                            <div class="col-7 form-group mb-1">
                                                                <select class="form-control " formControlName="dinnerRoti" autocomplete="nope">
                                                                    <option value="null"></option>
                                                                    <option value="1">1</option>
                                                                    <option value="2">2</option>
                                                                    <option value="3">3</option>
                                                                    <!--<option *ngFor="let item of rotiOpts">{{item}}</option>-->
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class="d-flex" *ngIf="opNutritionalAssessment.value.dinnercarealsMilletS == 'Wheat rava (cooked 200 ml)'">
                                                            <div class="form-group mb-1 col-5 d-flex align-items-baseline">
                                                                <label class="mb-1">Wheat Rava (cooked 200ml)</label>
                                                            </div>
                                                            <div class="col-7 form-group mb-1">
                                                                <select class="form-control " formControlName="dinnerWheatRava" autocomplete="nope">
                                                                    <option value="null"></option>

                                                                    <option value="Yes">Yes</option>
                                                                    <!--<option *ngFor="let item of rotiOpts">{{item}}</option>-->
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class="d-flex" *ngIf="opNutritionalAssessment.value.dinnercarealsMilletS == 'Millets (cooked 200 ml)'">
                                                            <div class="form-group mb-1 col-5 d-flex align-items-baseline">
                                                                <label class="mb-1">Millets (cooked 200ml)</label>
                                                            </div>
                                                            <div class="col-7 form-group mb-1">
                                                                <select class="form-control " formControlName="dinnerMillets" autocomplete="nope">
                                                                    <option value="null"></option>
                                                                    <option value="Yes">Yes</option>
                                                                    <option value="1 cup">1 cup</option>
                                                                    <option value="2 cups">2 cups</option>
                                                                    <option value="3 cups">3 cups</option>
                                                                    <option value="1.5 cups">1.5 cups</option>
                                                                    <option value="2.5 cups">2.5 cups</option>
                                                                    <option value="3.5 cups">3.5 cups</option>
                                                                    <!--<option *ngFor="let item of milletsOpts">{{item}}</option>-->
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <!--<div class="d-flex" *ngIf="opNutritionalAssessment.value.dinnercarealsMilletS == 'Wheat rawa(cooked 200 ml)'">
                                                            <div class="form-group mb-1 col-5 d-flex align-items-baseline">
                                                                <label class="mb-1">Wheat Rava</label>
                                                            </div>
                                                            <div class="col-7 form-group mb-1">
                                                                <select class="form-control " formControlName="dinnerWheatrawa" autocomplete="nope">
                                                                    <option *ngFor="let item of riceOpts">{{item}}</option>
                                                                </select>
                                                            </div>
                                                        </div>-->
                                                        <div class="d-flex" *ngIf="opNutritionalAssessment.value.dinnercarealsMilletS == 'Chapathi'">
                                                            <div class="form-group mb-1 col-5 d-flex align-items-baseline">
                                                                <label class="mb-1">Chapathi</label>
                                                            </div>
                                                            <div class="col-7 form-group mb-1">
                                                                <select class="form-control " formControlName="dinnerChapathi" autocomplete="nope">
                                                                    <option value="null"></option>
                                                                    <option value="1">1</option>
                                                                    <option value="2">2</option>
                                                                    <option value="3">3</option>
                                                                    <option value="4">4</option>
                                                                    <!--<option *ngFor="let item of dosaOpts">{{item}}</option>-->
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class="d-flex" *ngIf="opNutritionalAssessment.value.dinnercarealsMilletS == 'Phulkas'">
                                                            <div class="form-group mb-1 col-5 d-flex align-items-baseline">
                                                                <label class="mb-1">Phulkas</label>
                                                            </div>
                                                            <div class="col-7 form-group mb-1">
                                                                <select class="form-control " formControlName="dinnerPulka" autocomplete="nope">
                                                                    <option value="null"></option>
                                                                    <option value="1">1</option>
                                                                    <option value="2">2</option>
                                                                    <option value="3">3</option>
                                                                    <option value="4">4</option>

                                                                    <!--<option *ngFor="let item of dosaOpts">{{item}}</option>-->
                                                                </select>
                                                            </div>
                                                        </div>

                                                        <div class="d-flex">
                                                            <div class="form-group mb-1 col-5 d-flex align-items-baseline">
                                                                <label class="mb-1">Veg Curry</label>
                                                            </div>
                                                            <div class="col-7 form-group mb-1">
                                                                <select class="form-control " formControlName="dinnervegCurry" (change)="changeDinnerVeg()" autocomplete="nope">

                                                                    <option value="Yes">Yes</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class="d-flex" *ngIf="opNutritionalAssessment.value.dinnervegCurry == 'Yes'">
                                                            <div class="form-group mb-1 col-5 d-flex align-items-baseline">
                                                                <label class="mb-1">Veg Curry (Cooked 200ml)</label>
                                                            </div>
                                                            <div class="col-7 form-group mb-1">
                                                                <select class="form-control " formControlName="dinnervegCooked" autocomplete="nope">
                                                                    <option value="null"></option>

                                                                    <option value="1/4 cup">1/4 cup</option>
                                                                    <option value="1/2 cup">1/2 cup</option>
                                                                    <option value="1 cup1">1 cup</option>
                                                                    <option value="1/3 cup">1/3 cup</option>
                                                                    <option value="3/4 cup">3/4 cup</option>
                                                                    <!--<option *ngFor="let item of vegCurryOpts">{{item}}</option>-->
                                                                </select>
                                                            </div>
                                                        </div>

                                                        <div class="d-flex">
                                                            <div class="form-group mb-1 col-5 d-flex align-items-baseline">
                                                                <label class="mb-1">Green Leafy</label>
                                                            </div>
                                                            <div class="col-7 form-group mb-1">
                                                                <select class="form-control " formControlName="dinnergreenLeafy" (change)="changeDinnerLeafy()" autocomplete="nope">

                                                                    <option value="Yes">Yes</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class="d-flex" *ngIf="opNutritionalAssessment.value.dinnergreenLeafy == 'Yes'">
                                                            <div class="form-group mb-1 col-5 d-flex align-items-baseline">
                                                                <label class="mb-1">Green Leafy (cooked 200ml)</label>
                                                            </div>
                                                            <div class="col-7 form-group mb-1">
                                                                <select class="form-control " formControlName="dinnergreenLeafOpts" autocomplete="nope">
                                                                    <option value="null"></option>

                                                                    <option value="1/4 cup">1/4 cup </option>
                                                                    <option value="1/2 cup">1/2 cup</option>
                                                                    <option value="1 cup">1 cup</option>
                                                                    <!--<option *ngFor="let item of  dinLeafyOpts">{{item}}</option>-->
                                                                </select>
                                                            </div>
                                                        </div>

                                                        <div class="d-flex">
                                                            <div class="form-group mb-1 col-5 d-flex align-items-baseline">
                                                                <label class="mb-1">Dal</label>
                                                            </div>
                                                            <div class="col-7 form-group mb-1">
                                                                <select class="form-control " formControlName="dinnerdal" (change)="changeDinnerDal()" autocomplete="nope">

                                                                    <option value="Yes">Yes</option>
                                                                    <option value="No">No</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class="d-flex" *ngIf="opNutritionalAssessment.value.dinnerdal == 'Yes'">
                                                            <div class="form-group mb-1 col-5 d-flex align-items-baseline">
                                                                <label class="mb-1">Dal (cooked 200ml)</label>
                                                            </div>
                                                            <div class="col-7 form-group mb-1">
                                                                <select class="form-control " formControlName="dinnerdalopts" autocomplete="nope">
                                                                    <option value="null"></option>

                                                                    <option value="1/4 cup">1/4 cup</option>
                                                                    <option value="1/2 cup">1/2 cup</option>
                                                                    <option value="1 cup1">1 cup</option>
                                                                    <option value="1/3 cup">1/3 cup</option>
                                                                    <option value="3/4 cup">3/4 cup</option>
                                                                    <!--<option *ngFor="let item of vegCurryOpts">{{item}}</option>-->
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class="d-flex">
                                                            <div class="form-group mb-1 col-5 d-flex align-items-baseline">
                                                                <label class="mb-1">Paneer</label>
                                                            </div>
                                                            <div class="col-7 form-group mb-1">
                                                                <select class="form-control " formControlName="dinnerpanner" (change)="changeDinnerPaneer()" autocomplete="nope">

                                                                    <option value="Yes">Yes</option>
                                                                    <option value="No">No</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class="d-flex" *ngIf="opNutritionalAssessment.value.dinnerpanner == 'Yes'">
                                                            <div class="form-group mb-1 col-5 d-flex align-items-baseline">
                                                                <label class="mb-1">Paneer (Pcs)</label>
                                                            </div>
                                                            <div class="col-7 form-group mb-1">
                                                                <select class="form-control " formControlName="dinnerpannerPCs" autocomplete="nope">
                                                                    <option value="null"></option>

                                                                    <option value="2 pc">2 pc</option>
                                                                    <option value="3 pc">3 pc</option>
                                                                    <option value="4 pc">4 pc</option>
                                                                    <option value="5 pc">5 pc</option>
                                                                    <option value="6 pc">6 pc</option>
                                                                    <option value="Others">Others</option>
                                                                    <!--<option *ngFor="let item of paneerOpts">{{item}}</option>-->
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class="d-flex" *ngIf="opNutritionalAssessment.value.dinnerpannerPCs == 'Others'">
                                                            <div class="form-group mb-1 col-5 d-flex align-items-baseline">
                                                                <label class="mb-1">Others</label>
                                                            </div>
                                                            <div class="col-7 form-group mb-1">
                                                                <input type="text" class="form-control " formControlName="dinnerpannerOthers" autocomplete="nope" />
                                                            </div>
                                                        </div>

                                                        <div class="d-flex">
                                                            <div class="form-group mb-1 col-5 d-flex align-items-baseline">
                                                                <label class="mb-1">Non-Veg</label>
                                                            </div>
                                                            <div class="col-7 form-group mb-1">
                                                                <select class="form-control " formControlName="ldinnerNonVeg" (change)="changeDinnerNonVeg()" autocomplete="nope">

                                                                    <option value="Yes">Yes</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class="d-flex" *ngIf="opNutritionalAssessment.value.ldinnerNonVeg == 'Yes'">
                                                            <div class="form-group mb-1 col-5 d-flex align-items-baseline">
                                                                <label class="mb-1">Non Veg (Pcs)</label>
                                                            </div>
                                                            <div class="col-7 form-group mb-1">
                                                                <select class="form-control " formControlName="ldinnerNonVegPcs" autocomplete="nope">
                                                                    <option value="null"></option>
                                                                    <option value="2-3 pcs">2-3 pcs</option>
                                                                    <option value="4-5 pcs">4-5 pcs</option>
                                                                    <option value="Others">Others</option>
                                                                    <!--<option *ngFor="let item of lunchNonVegOpts">{{item}}</option>-->
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class="d-flex" *ngIf="opNutritionalAssessment.value.ldinnerNonVegPcs == 'Others'">
                                                            <div class="form-group mb-1 col-5 d-flex align-items-baseline">
                                                                <label class="mb-1">Others</label>
                                                            </div>
                                                            <div class="col-7 form-group mb-1">
                                                                <input type="text" class="form-control" formControlName="ldinnerNonVegOther" autocomplete="nope" />
                                                            </div>
                                                        </div>

                                                        <div class="d-flex">
                                                            <div class="form-group mb-1 col-5 d-flex align-items-baseline">
                                                                <label class="mb-1">Curd</label>
                                                            </div>
                                                            <div class="col-7 form-group mb-1">
                                                                <select class="form-control " formControlName="dinnercurd" autocomplete="nope">
                                                                    <option value="null"></option>

                                                                    <option value="100 ml">100 ml</option>
                                                                    <option value="200 ml">200 ml</option>
                                                                    <option value="Less than 100 ml">Less than 100 ml</option>
                                                                    <option value="No">No</option>
                                                                    <!--<option *ngFor="let item of dinnerCurdOpts">{{item}}</option>-->
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class="d-flex">
                                                            <div class="form-group mb-1 col-5 d-flex align-items-baseline">
                                                                <label class="mb-1">Any Others</label>
                                                            </div>
                                                            <div class="col-7 form-group mb-1">
                                                                <textarea type="text" class="form-control" formControlName="dinnerOther"></textarea>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="col-12 col-xl-4">

                                                        <div class="headingStyleOb">
                                                            <div class="trapeziumOb font-weight-semibold text-white">
                                                                <span class="p-1">Bed Time</span>
                                                            </div>
                                                        </div>
                                                        <div class="d-flex" style="margin-top:10px ">
                                                            <div class="form-group mb-1 col-5 d-flex align-items-baseline">
                                                                <label class="mb-1">Time</label>
                                                            </div>
                                                            <div class="col-7 form-group mb-1">
                                                                <input type="time" class="form-control" formControlName="bedtime" />
                                                            </div>
                                                        </div>
                                                        <div class="d-flex">
                                                            <div class="form-group mb-1 col-5 d-flex align-items-baseline">
                                                                <label class="mb-1">Milk </label>
                                                            </div>
                                                            <div class="col-7 form-group mb-1">
                                                                <select class="form-control " formControlName="bedTimeMilk" (change)="changeBedTimeMilk()" autocomplete="nope">
                                                                    <option value="No">No</option>
                                                                    <option value="Yes">Yes</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class="d-flex" *ngIf="opNutritionalAssessment.value.bedTimeMilk == 'Yes'">
                                                            <div class="form-group mb-1 col-5 d-flex align-items-baseline">
                                                                <label class="mb-1">Milk </label>
                                                            </div>
                                                            <div class="col-7 form-group mb-1">
                                                                <select class="form-control " formControlName="bedTimeMilkOpts" autocomplete="nope">
                                                                    <option value="null"></option>

                                                                    <option value="150 ml">150 ml</option>
                                                                    <option value="200 ml">200 ml</option>
                                                                    <option value="250 ml">250 ml</option>
                                                                    <option value="100 ml">100 ml</option>
                                                                    <!--<option *ngFor="let item of morningMilkOpts">{{item}}</option>-->
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class="d-flex" *ngIf="opNutritionalAssessment.value.bedTimeMilk == 'Yes'">
                                                            <div class="form-group mb-1 col-5 d-flex align-items-baseline">
                                                                <label class="mb-1">Sugar (Tsp)</label>
                                                            </div>
                                                            <div class="col-7 form-group mb-1">
                                                                <select class="form-control " formControlName="bedTimeMilkSugar" autocomplete="nope">
                                                                    <option value="null"></option>

                                                                    <option value="1tsp">1 tps</option>
                                                                    <option value="2tsp">2 tps</option>
                                                                    <option value="less then 1 tsp"> < 1 tps</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class="d-flex" *ngIf="opNutritionalAssessment.value.bedTimeMilk == 'Yes'">
                                                            <div class="form-group mb-1 col-5 d-flex align-items-baseline">
                                                                <label class="mb-1">Horlicks/ Boost/ Others (Tsp)</label>
                                                            </div>
                                                            <div class="col-7 form-group mb-1">
                                                                <select class="form-control " formControlName="bedTimeMilkBoost" autocomplete="nope">
                                                                    <option value="null"></option>
                                                                    <!--<option value="null">Nill</option>-->
                                                                    <option value="1tsp">1 tps</option>
                                                                    <option value="2tsp">2 tps</option>
                                                                    <option value="less then 1 tsp"> < 1 tps</option>
                                                                </select>
                                                            </div>
                                                        </div>

                                                        <div class="d-flex">
                                                            <div class="form-group mb-1 col-5 d-flex align-items-baseline">
                                                                <label class="mb-1">Dry Fruits</label>
                                                            </div>
                                                            <div class="col-7 form-group mb-1">
                                                                <input type="text" class="form-control" formControlName="bedtimeDryFruits" />
                                                            </div>
                                                        </div>
                                                        <div class="d-flex">
                                                            <div class="form-group mb-1 col-5 d-flex align-items-baseline">
                                                                <label class="mb-1">Any Others</label>
                                                            </div>
                                                            <div class="col-7 form-group mb-1">
                                                                <textarea type="text" class="form-control" formControlName="bedTimeOther"></textarea>
                                                            </div>
                                                        </div>
                                                        <div class="headingStyleOb">
                                                            <div class="trapeziumOb font-weight-semibold text-white">
                                                                <span class="p-1">Quality Of Diet</span>
                                                            </div>
                                                        </div>
                                                        <div class="d-flex" style="margin-top:10px ">
                                                            <div class="form-group mb-1 col-5">
                                                                <label class="mb-1">How Often you Eat Samosa/Puff/Fried Snacks /Fried Chicken / Fried Mutton / Cakes </label>
                                                            </div>
                                                            <div class="col-7 form-group mb-1">
                                                                <select class="form-control " formControlName="oftenyouEatSamosa" autocomplete="nope">
                                                                    <option value="null"></option>

                                                                    <option value="Once / week">Once / week</option>
                                                                    <option value="twice / week">twice / week</option>
                                                                    <option value="Once / fortNight">Once / fortNight</option>
                                                                    <option value="No">No</option>
                                                                    <option value="Once / month">Once / month</option>
                                                                    <option value="thrice / week">thrice / week</option>
                                                                    <option value="Daily">Daily</option>
                                                                    <!--<option *ngFor="let item of qualityOfDiet">{{item}}</option>-->
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class="d-flex">
                                                            <div class="form-group mb-1 col-5 d-flex align-items-baseline">
                                                                <label class="mb-1">Type Of Food</label>
                                                            </div>
                                                            <div class="col-7 form-group mb-1">
                                                                <input type="text" class="form-control" formControlName="typeOfFood" />
                                                            </div>
                                                        </div>
                                                        <div class="d-flex">
                                                            <div class="form-group mb-1 col-5 d-flex align-items-baseline">
                                                                <label class="mb-1">Inclusion Of Fruit Juice 200ml </label>
                                                            </div>
                                                            <div class="col-7 form-group mb-1">
                                                                <select class="form-control " formControlName="inclusionOfFruitJuice" autocomplete="nope">
                                                                    <option value="null"></option>

                                                                    <option value="Once / week">Once / week</option>
                                                                    <option value="twice / week">twice / week</option>
                                                                    <option value="Alternative days">Alternative days</option>
                                                                    <option value="Daily">Daily</option>
                                                                    <option value="Once / month">Once / month</option>
                                                                    <option value="Once / fortNight">Once / fortNight</option>
                                                                    <!--<option *ngFor="let item of inclusionOpts">{{item}}</option>-->
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class="d-flex">
                                                            <div class="form-group mb-1 col-5 d-flex align-items-baseline">
                                                                <label class="mb-1">Fruit Juice</label>
                                                            </div>
                                                            <div class="col-7 form-group mb-1">
                                                                <select class="form-control " formControlName="qualityFruitJuice" (change)="changeQualityFruit()" autocomplete="nope">
                                                                    <option value="No">No</option>
                                                                    <option value="Yes">Yes</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class="d-flex" *ngIf="opNutritionalAssessment.value.qualityFruitJuice !== 'No'">
                                                            <div class="form-group mb-1 col-5 d-flex align-items-baseline">
                                                                <label class="mb-1">Fruit Juice Ml </label>
                                                            </div>
                                                            <div class="col-7 form-group mb-1">
                                                                <input type="text" class="form-control" formControlName="qualityFruitJuiceMl" autocomplete="nope" />
                                                            </div>
                                                        </div>

                                                        <div class="d-flex">
                                                            <div class="form-group mb-1 col-5 d-flex align-items-baseline">
                                                                <label class="mb-1">Carbonated Drink</label>
                                                            </div>
                                                            <div class="col-7 form-group mb-1">
                                                                <select class="form-control " formControlName="carbonatedDrink" (change)="changeCarbonated()" autocomplete="nope">
                                                                    <option value="No">No</option>
                                                                    <option value="Yes">Yes</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class="d-flex" *ngIf="opNutritionalAssessment.value.carbonatedDrink == 'Yes'">
                                                            <div class="form-group mb-1 col-5 d-flex align-items-baseline">
                                                                <label class="mb-1">Carbonated</label>
                                                            </div>
                                                            <div class="col-7 form-group mb-1">
                                                                <select class="form-control " formControlName="carbonated" autocomplete="nope">
                                                                    <option value="null"></option>

                                                                    <option value="Once/ day">Once/ day</option>
                                                                    <option value="twice / week">twice / week</option>
                                                                    <option value="alternate day">alternate day</option>
                                                                    <option value="monthly / once">monthly / once</option>
                                                                    <option value="Rarely">Rarely</option>
                                                                    <!--<option *ngFor="let item of corbonatedOpts">{{item}}</option>-->
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class="d-flex">
                                                            <div class="form-group mb-1 col-5 d-flex align-items-baseline">
                                                                <label class="mb-1">High Sugar Drink(More Than 3tsp)</label>
                                                            </div>
                                                            <div class="col-7 form-group mb-1">
                                                                <select class="form-control " formControlName="highSugarDrinkMore" (change)="changeHighSugarDrink()" autocomplete="nope">
                                                                    <option value="No">No</option>
                                                                    <option value="Yes">Yes</option>

                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class="d-flex" *ngIf="opNutritionalAssessment.value.highSugarDrinkMore !== 'No'">
                                                            <div class="form-group mb-1 col-5 d-flex align-items-baseline">
                                                                <label class="mb-1">High Sugar Drink</label>
                                                            </div>
                                                            <div class="col-7 form-group mb-1">
                                                                <input type="text" class="form-control" formControlName="highSugarDrink" />
                                                            </div>
                                                        </div>
                                                        <div class="d-flex">
                                                            <div class="form-group mb-1 col-5 d-flex align-items-baseline">
                                                                <label class="mb-1">Inclusion Of Processed Junk foods >100g (Twice/ Week)</label>
                                                            </div>
                                                            <div class="col-7 form-group mb-1">
                                                                <select class="form-control " formControlName="inclusionOfProcessedJunk" autocomplete="nope">
                                                                    <option value="No">No</option>
                                                                    <option value="Yes">Yes</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class="d-flex">
                                                            <div class="form-group mb-1 col-5 d-flex align-items-baseline">
                                                                <label class="mb-1">Intake Of Whole Grains</label>
                                                            </div>
                                                            <div class="col-7 form-group mb-1">
                                                                <select class="form-control " formControlName="intakeOfWholeGrains" (change)="changeWholeGrains()" autocomplete="nope">
                                                                    <option value="No">No</option>
                                                                    <option value="Yes">Yes</option>

                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class="d-flex" *ngIf="opNutritionalAssessment.value.intakeOfWholeGrains == 'Yes'">
                                                            <div class="form-group mb-1 col-5 d-flex align-items-baseline">
                                                                <label class="mb-1">Quantity</label>
                                                            </div>
                                                            <div class="col-7 form-group mb-1">
                                                                <input type="text" class="form-control" formControlName="intakeOfWholeGrainsQuantity" />
                                                            </div>
                                                        </div>
                                                        <div class="d-flex">
                                                            <div class="form-group mb-1 col-5 d-flex align-items-baseline">
                                                                <label class="mb-1">More Than 50% Of Diet</label>
                                                            </div>
                                                            <div class="col-7 form-group mb-1">
                                                                <select class="form-control " formControlName="moreThanOfDiet" autocomplete="nope">
                                                                    <option value="No">No</option>
                                                                    <option value="Yes">Yes</option>
                                                                </select>
                                                            </div>
                                                        </div>


                                                        <div class="d-flex">
                                                            <div class="form-group mb-1 col-5 d-flex align-items-baseline">
                                                                <label class="mb-1">Low Vegetable Intake (< 3 and a half  serving )/ day</label>
                                                            </div>
                                                            <div class="col-7 form-group mb-1">
                                                                <select class="form-control " formControlName="lowVegetableIntake" autocomplete="nope">
                                                                    <option value="No">No</option>
                                                                    <option value="Yes">Yes</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class="d-flex">
                                                            <div class="form-group mb-1 col-5 d-flex align-items-baseline">
                                                                <label class="mb-1">Intake Of Fruits</label>
                                                            </div>
                                                            <div class="col-7 form-group mb-1">
                                                                <select class="form-control " formControlName="intakeOfFruits" (change)="changeintakeOfFruits()" autocomplete="nope">
                                                                    <option value="No">No</option>
                                                                    <option value="Yes">Yes</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class="d-flex" *ngIf="opNutritionalAssessment.value.intakeOfFruits == 'Yes'">
                                                            <div class="form-group mb-1 col-5 d-flex align-items-baseline">
                                                                <label class="mb-1">Intake Fruits</label>
                                                            </div>
                                                            <div class="col-7 form-group mb-1">
                                                                <select class="form-control " formControlName="intakeFruits" autocomplete="nope">
                                                                    <option value="Null"></option>
                                                                    <option value="Less than 2 fruit">Less than 2 fruit</option>
                                                                    <option value="More than 2 fruit">More than 2 fruit</option>
                                                                    <option value="1 / Day">1 / Day</option>
                                                                    <option value="2 / Day">2 / Day</option>
                                                                    <option value="3 / Day">3 / Day</option>
                                                                    <option value="twice / week">twice / week</option>
                                                                    <option value="thrice / week">thrice / week</option>
                                                                    <option value="None">None</option>
                                                                    <!--<option *ngFor="let item of intakeFruitOpts">{{item}}</option>-->
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class="d-flex">
                                                            <div class="form-group mb-1 col-5 d-flex align-items-baseline">
                                                                <label class="mb-1">Type Of Milk / Curd</label>
                                                            </div>
                                                            <div class="col-7 form-group mb-1">
                                                                <div class="col-12 form-group mb-1" *ngFor="let item of typeOfMilkCurd; let i = index">
                                                                    <div class="custom-control custom-checkbox">
                                                                        <input [attr.id]="'typeOfMilkCurd' + i" class="custom-control-input" type="checkbox" [checked]="isSelectedtypeOfMilkCurd(item)" (change)="onChangetypeOfMilkCurd(item)">
                                                                        <label class="custom-control-label" [attr.for]="'typeOfMilkCurd' + i" [textContent]="item"></label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <!--<div class="custom-control custom-checkbox col-5">
                                                            <input class="custom-control-input" type="checkbox" id="Skim">
                                                            <label class="custom-control-label" for="Skim">Skim</label>
                                                        </div>

                                                        <div class="custom-control custom-checkbox col-2">
                                                            <input class="custom-control-input" type="checkbox" id="onePer">
                                                            <label class="custom-control-label" for="onePer">1 %</label>
                                                        </div>
                                                        <div class="custom-control custom-checkbox col-5 pl-4">
                                                            <input class="custom-control-input" type="checkbox" id="notConsuming">
                                                            <label class="custom-control-label" for="notConsuming">Not Consuming</label>
                                                        </div>
                                                        <div class="custom-control custom-checkbox col-3">
                                                            <input class="custom-control-input" type="checkbox" id="Whole">
                                                            <label class="custom-control-label" for="Whole">Whole</label>
                                                        </div>
                                                        <div class="custom-control custom-checkbox col-4">
                                                            <input class="custom-control-input" type="checkbox" id="threePer">
                                                            <label class="custom-control-label" for="threePer">3 %</label>
                                                                                                            </div>-->


                                                        <div class="d-flex">
                                                            <div class="form-group mb-1 col-5 d-flex align-items-baseline">
                                                                <label class="mb-1">Water Intake</label>
                                                            </div>
                                                            <div class="col-7 form-group mb-1">
                                                                <select class="form-control " formControlName="waterIntake" autocomplete="nope">
                                                                    <option value="null"></option>
                                                                    <option value="1 ltr/ Day">1 ltr/ Day</option>
                                                                    <option value="2 ltrs/ Day">2 ltrs/ Day</option>
                                                                    <option value="3 ltrs/ Day">3 ltrs/ Day</option>

                                                                    <option value=" < 1 ltr"> < 1 ltr</option>
                                                                    <option value="Others">Others</option>
                                                                    <option value="3.5 letre">3.5 letre</option>
                                                                    <option value="4 litre">4 litre</option>
                                                                    <!--<option *ngFor="let item of waterIntakeOpts">{{item}}</option>-->
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class="d-flex" *ngIf="opNutritionalAssessment.value.waterIntake == 'Others'">
                                                            <div class="form-group mb-1 col-5 d-flex align-items-baseline">
                                                                <label class="mb-1">Water Intake Others</label>
                                                            </div>
                                                            <div class="col-7 form-group mb-1">
                                                                <input type="text" class="form-control" formControlName="waterIntakeOthers" autocomplete="nope" />
                                                            </div>
                                                        </div>
                                                        <div class="d-flex">
                                                            <div class="form-group mb-1 col-5 d-flex align-items-baseline">
                                                                <label class="mb-1">Total Oil Used/ Month</label>
                                                            </div>
                                                            <div class="col-7 form-group mb-1">
                                                                <select class="form-control " formControlName="totalOilUsed" autocomplete="nope">
                                                                    <option value="null"></option>

                                                                    <option value="1 L">1 L</option>
                                                                    <option value="2 L">2 L</option>
                                                                    <option value="3 L">3 L</option>
                                                                    <option value="4 L">4 L</option>
                                                                    <option value="5 L">5 L</option>
                                                                    <option value="Others">Others</option>
                                                                    <option value="Not aware">Not aware</option>
                                                                    <!--<option *ngFor="let item of totalOilUsedOpts">{{item}}</option>-->
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class="d-flex" *ngIf="opNutritionalAssessment.value.totalOilUsed == 'Others'">
                                                            <div class="form-group mb-1 col-5 d-flex align-items-baseline">
                                                                <label class="mb-1">Oil Others</label>
                                                            </div>
                                                            <div class="col-7 form-group mb-1">
                                                                <input type="text" class="form-control" formControlName="totalOilUsedOthers" autocomplete="nope" />
                                                            </div>
                                                        </div>
                                                        <div class="d-flex">
                                                            <div class="form-group mb-1 col-5 d-flex align-items-baseline">
                                                                <label class="mb-1">Type Of Oil</label>
                                                            </div>
                                                            <div class="col-7 form-group mb-1">
                                                                <select class="form-control " formControlName="typeOfOil" autocomplete="nope">
                                                                    <!--<option *ngFor="let item of typeOfOilOpts">{{item}}</option>-->
                                                                    <option value=""> </option>
                                                                    <option value="Sunflower">Sunflower </option>
                                                                    <option value="Safflower">Safflower </option>
                                                                    <option value="Rice Brand">Rice Brand </option>
                                                                    <option value="Ground Nuts">Ground Nuts </option>
                                                                    <option value="Mustard">Mustard </option>
                                                                    <option value="Sesame">Sesame </option>
                                                                    <option value="Cold Pressed">cold Pressed </option>
                                                                    <option value="Olive">Olive </option>
                                                                    <option value="Others">Others </option>
                                                                    <option value="Not Aware">Not Aware </option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class="d-flex" *ngIf="opNutritionalAssessment.value.typeOfOil == 'Others'">
                                                            <div class="form-group mb-1 col-5 d-flex align-items-baseline">
                                                                <label class="mb-1">Other Oil</label>
                                                            </div>
                                                            <div class="col-7 form-group mb-1">
                                                                <input type="text" class="form-control" formControlName="otherOil" autocomplete="nope" />
                                                            </div>
                                                        </div>
                                                        <div class="d-flex">
                                                            <div class="form-group mb-1 col-5 d-flex align-items-baseline">
                                                                <label class="mb-1">Ghee / Butter</label>
                                                            </div>
                                                            <div class="col-7 form-group mb-1">
                                                                <select class="form-control " formControlName="gheeButter" (change)="changeGhee()" autocomplete="nope">
                                                                    <option value="No">No</option>
                                                                    <option value="Yes">Yes</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class="d-flex" *ngIf="opNutritionalAssessment.value.gheeButter == 'Yes'">
                                                            <div class="form-group mb-1 col-5 d-flex align-items-baseline">
                                                                <label class="mb-1">Ghee / Butter(Tsp)</label>
                                                            </div>
                                                            <div class="col-7 form-group mb-1">
                                                                <select class="form-control " formControlName="gheeButterYes" autocomplete="nope">
                                                                    <option value="null"></option>
                                                                    <option value="1 Tbsp">1 Tbsp</option>
                                                                    <option value="2 Tbsp">2 Tbsp</option>
                                                                    <option value="3 Tbsp">3 Tbsp</option>
                                                                    <option value="4 Tbsp">4 Tbsp</option>
                                                                    <option value="5 Tbsp">5 Tbsp</option>
                                                                    <option value="Others">Others</option>
                                                                    <!--<option *ngFor="let item of gheeButterOpts">{{item}}</option>-->
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class="d-flex" *ngIf="opNutritionalAssessment.value.gheeButterYes == 'Others'">
                                                            <div class="form-group mb-1 col-5 d-flex align-items-baseline">
                                                                <label class="mb-1">Others</label>
                                                            </div>
                                                            <div class="col-7 form-group mb-1">
                                                                <input type="text" class="form-control" formControlName="gheeButterOthers" autocomplete="nope" />
                                                            </div>
                                                        </div>

                                                        <div class="d-flex">
                                                            <div class="form-group mb-1 col-5 d-flex align-items-baseline">
                                                                <label class="mb-1">Beans / Grams</label>
                                                            </div>
                                                            <div class="col-7 form-group mb-1">
                                                                <select class="form-control " formControlName="beansGrams" (change)="changeBeans()" autocomplete="nope">

                                                                    <option value="Yes">Yes</option>
                                                                    <option value="No">No</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class="d-flex" *ngIf="opNutritionalAssessment.value.beansGrams == 'Yes'">
                                                            <div class="form-group mb-1 col-5 d-flex align-items-baseline">
                                                                <label class="mb-1">Taken / Day / Week</label>
                                                            </div>
                                                            <div class="col-7 form-group mb-1">
                                                                <select class="form-control " formControlName="beansGramsYes" autocomplete="nope">
                                                                    <option value="null"></option>
                                                                    <option value="Once / Day">Once / Day</option>
                                                                    <option value="twice / week">twice / week</option>
                                                                    <option value="thrice / week">thrice / week</option>
                                                                    <option value="Once / week">Once / week</option>
                                                                    <option value="Once / month">Once / month</option>
                                                                    <option value="Once / fortNight">Once / fortNight</option>
                                                                    <option value="Rarely">Rarely</option>
                                                                    <option value="Daily">Daily</option>
                                                                    <option value="Twice a day">Twice a day</option>
                                                                    <!--<option *ngFor="let item of beansGramsOpts">{{item}}</option>-->
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class="d-flex">
                                                            <div class="form-group mb-1 col-5 d-flex align-items-baseline">
                                                                <label class="mb-1">Dal (200 Ml)</label>
                                                            </div>
                                                            <div class="col-7 form-group mb-1">
                                                                <select class="form-control " formControlName="qualitydalYes" (change)="changeQualityDal()" autocomplete="nope">
                                                                    <option value="Yes">Yes</option>
                                                                    <option value="No">No</option>
                                                                </select>
                                                            </div>
                                                        </div>

                                                        <div class="d-flex" *ngIf="opNutritionalAssessment.value.qualitydalYes == 'Yes'">
                                                            <div class="form-group mb-1 col-5 d-flex align-items-baseline">
                                                                <label class="mb-1">Taken / Day / Week</label>
                                                            </div>
                                                            <div class="col-7 form-group mb-1">
                                                                <select class="form-control " formControlName="qualitydal" autocomplete="nope">
                                                                    <option value="null"></option>
                                                                    <option value="Once / day">Once / day</option>
                                                                    <option value="twice / week">twice / week</option>
                                                                    <option value="thrice / week">thrice / week</option>

                                                                    <option value="Once / week">Once / week</option>
                                                                    <option value="Rarely">Rarely</option>
                                                                    <!--<option *ngFor="let item of qualityOfDal">{{item}}</option>-->
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class="d-flex">
                                                            <div class="form-group mb-1 col-5 d-flex align-items-baseline">
                                                                <label class="mb-1">Paneer</label>
                                                            </div>
                                                            <div class="col-7 form-group mb-1">
                                                                <select class="form-control " formControlName="qualityPaneerYes" (change)="changeQualityPaneer()" autocomplete="nope">

                                                                    <option value="Yes">Yes</option>
                                                                    <option value="No">No</option>
                                                                </select>
                                                            </div>
                                                        </div>

                                                        <div class="d-flex" *ngIf="opNutritionalAssessment.value.qualityPaneerYes == 'Yes'">
                                                            <div class="form-group mb-1 col-5 d-flex align-items-baseline">
                                                                <label class="mb-1">Taken / Day / Week</label>
                                                            </div>
                                                            <div class="col-7 form-group mb-1">
                                                                <select class="form-control " formControlName="qualityPaneer" autocomplete="nope">
                                                                    <option value="null"></option>
                                                                    <option value="Once / day">Once / day</option>
                                                                    <option value="twice / week">twice / week</option>
                                                                    <option value="thrice / week">thrice / week</option>

                                                                    <option value="Once / week">Once / week</option>
                                                                    <option value="Rarely">Rarely</option>
                                                                    <!--<option *ngFor="let item of qualityOfDal">{{item}}</option>-->
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class="d-flex">
                                                            <div class="form-group mb-1 col-5 d-flex align-items-baseline">
                                                                <label class="mb-1">GLV</label>
                                                            </div>
                                                            <div class="col-7 form-group mb-1">
                                                                <select class="form-control " formControlName="qualityGlv" autocomplete="nope">
                                                                    <option value="Null"></option>
                                                                    <option value="Once / week">Once / week</option>
                                                                    <option value="Twice / week">Twice / week</option>
                                                                    <option value="Thrice / week">Thrice / week</option>
                                                                    <option value="Rarely">Rarely</option>

                                                                    <option value="Once / Day">Once / Day</option>
                                                                    <option value="Daily">Daily</option>
                                                                    <!--<option *ngFor="let item of qualityGLVOpts">{{item}}</option>-->
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class="d-flex">
                                                            <div class="form-group mb-1 col-5 d-flex align-items-baseline">
                                                                <label class="mb-1">Mutton / Red Meat</label>
                                                            </div>
                                                            <div class="col-7 form-group mb-1">
                                                                <select class="form-control " formControlName="qualityMutton" (change)="changeQualityMutton()" autocomplete="nope">
                                                                    <option value="Null"></option>

                                                                    <option value="Yes">Yes</option>
                                                                    <option value="Others">Others</option>
                                                                    <option value="Rarely">Rarely</option>
                                                                    <option value="No">No</option>
                                                                    <!--<option *ngFor="let item of mottonOpts">{{item}}</option>-->
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class="d-flex" *ngIf="opNutritionalAssessment.value.qualityMutton == 'Yes'">
                                                            <div class="form-group mb-1 col-5 d-flex align-items-baseline">
                                                                <label class="mb-1">Taken / Day / Week</label>
                                                            </div>
                                                            <div class="col-7 form-group mb-1">
                                                                <select class="form-control " formControlName="qualityMuttontake" autocomplete="nope">
                                                                    <option value="null"></option>
                                                                    <option value="Once / Day">Once / Day</option>
                                                                    <option value="twice / week">twice / week</option>
                                                                    <option value="thrice / week">thrice / week</option>
                                                                    <option value="Once / week">Once / week</option>
                                                                    <option value="Once / fortNight">Once / fortNight</option>
                                                                    <option value="Rarerly">Rarerly</option>
                                                                    <option value="twice a day">twice a day</option>
                                                                    <!--<option *ngFor="let item of qualityMuttonOpts">{{item}}</option>-->
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class="d-flex" *ngIf="opNutritionalAssessment.value.qualityMutton == 'Others'">
                                                            <div class="form-group mb-1 col-5 d-flex align-items-baseline">
                                                                <label class="mb-1">Others</label>
                                                            </div>
                                                            <div class="col-7 form-group mb-1">
                                                                <input type="text" class="form-control " formControlName="qualityMuttonOther" autocomplete="nope" />
                                                            </div>
                                                        </div>

                                                        <div class="d-flex">
                                                            <div class="form-group mb-1 col-5 d-flex align-items-baseline">
                                                                <label class="mb-1">Chicken</label>
                                                            </div>
                                                            <div class="col-7 form-group mb-1">
                                                                <select class="form-control " formControlName="qualityChicken" (change)="changeQualityChicken()" autocomplete="nope">
                                                                    <option value="No">No</option>
                                                                    <option value="Yes">Yes</option>

                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class="d-flex" *ngIf="opNutritionalAssessment.value.qualityChicken == 'Yes'">
                                                            <div class="form-group mb-1 col-5 d-flex align-items-baseline">
                                                                <label class="mb-1">Taken / Day / Week</label>
                                                            </div>
                                                            <div class="col-7 form-group mb-1">
                                                                <select class="form-control " formControlName="qualityChickentake" autocomplete="nope">
                                                                    <option value="null"></option>
                                                                    <option value="Once / Day">Once / Day</option>
                                                                    <option value="twice / week">twice / week</option>
                                                                    <option value="thrice / week">thrice / week</option>
                                                                    <option value="Once / week">Once / week</option>
                                                                    <option value="Once / fortNight">Once / fortNight</option>
                                                                    <option value="Rarerly">Rarerly</option>
                                                                    <option value="twice a day">aytwice a day</option>
                                                                    <!--<option *ngFor="let item of qualityMuttonOpts">{{item}}</option>-->
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class="d-flex">
                                                            <div class="form-group mb-1 col-5 d-flex align-items-baseline">
                                                                <label class="mb-1">Fish</label>
                                                            </div>
                                                            <div class="col-7 form-group mb-1">
                                                                <select class="form-control " formControlName="qualityFish" (change)="changeQualityFish()" autocomplete="nope">
                                                                    <option value="No">No</option>
                                                                    <option value="Yes">Yes</option>

                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class="d-flex" *ngIf="opNutritionalAssessment.value.qualityFish == 'Yes'">
                                                            <div class="form-group mb-1 col-5 d-flex align-items-baseline">
                                                                <label class="mb-1">Taken / Day / Week</label>
                                                            </div>
                                                            <div class="col-7 form-group mb-1">
                                                                <select class="form-control " formControlName="qualityFishtake" autocomplete="nope">
                                                                    <option value="null"></option>
                                                                    <option value="Once / Day">Once / Day</option>
                                                                    <option value="twice / week">twice / week</option>
                                                                    <option value="thrice / week">thrice / week</option>
                                                                    <option value="Once / week">Once / week</option>
                                                                    <option value="Once / fortNight">Once / fortNight</option>
                                                                    <option value="Rarerly">Rarerly</option>
                                                                    <option value="twice a day">twice a day</option>
                                                                    <!--<option *ngFor="let item of qualityMuttonOpts">{{item}}</option>-->
                                                                </select>
                                                            </div>
                                                        </div>

                                                        <div class="d-flex">
                                                            <div class="form-group mb-1 col-5 d-flex align-items-baseline">
                                                                <label class="mb-1">Egg</label>
                                                            </div>
                                                            <div class="col-7 form-group mb-1">
                                                                <select class="form-control " formControlName="qualityEgg" (change)="changeEggWhite()" autocomplete="nope">
                                                                    <option value="No">No</option>
                                                                    <option value="Yes">Yes</option>

                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class="d-flex" *ngIf="opNutritionalAssessment.value.qualityEgg == 'Yes'">
                                                            <div class="form-group mb-1 col-5 d-flex align-items-baseline">
                                                                <label class="mb-1">Egg White</label>
                                                            </div>
                                                            <div class="col-7 form-group mb-1">
                                                                <select class="form-control " formControlName="eggWhite" autocomplete="nope">
                                                                    <option value="null"></option>
                                                                    <option value="Once / Day">Once / Day</option>
                                                                    <option value="twice / week">twice / week</option>
                                                                    <option value="thrice / week">thrice / week</option>
                                                                    <option value="Once / week">Once / week</option>
                                                                    <option value="Once / fortNight">Once / fortNight</option>
                                                                    <option value="Rarerly">Rarerly</option>
                                                                    <option value="No">No</option>
                                                                    <!--<option *ngFor="let item of qualityeggWhiteOpts">{{item}}</option>-->
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class="d-flex" *ngIf="opNutritionalAssessment.value.qualityEgg == 'Yes'">
                                                            <div class="form-group mb-1 col-5 d-flex align-items-baseline">
                                                                <label class="mb-1">Egg Whole</label>
                                                            </div>
                                                            <div class="col-7 form-group mb-1">
                                                                <select class="form-control " formControlName="eggWhole" autocomplete="nope">
                                                                    <option value="null"></option>
                                                                    <option value="Once / Day">Once / Day</option>
                                                                    <option value="twice / week">twice / week</option>
                                                                    <option value="thrice / week">thrice / week</option>

                                                                    <option value="Once / week">Once / week</option>
                                                                    <option value="No">No</option>
                                                                    <!--<option *ngFor="let item of qualityeggwholeOpts">{{item}}</option>-->
                                                                </select>
                                                            </div>
                                                        </div>

                                                        <div class="headingStyleOb">
                                                            <div class="trapeziumOb font-weight-semibold text-white">
                                                                <span class="p-1">Life Style</span>
                                                            </div>
                                                        </div>

                                                        <div class="d-flex" style="margin-top:10px ">
                                                            <div class="form-group mb-1 col-5 d-flex align-items-baseline">
                                                                <label class="mb-1">Activity Pattern</label>
                                                            </div>
                                                            <div class="col-7 form-group mb-1">
                                                                <select class="form-control " formControlName="activityPattern" autocomplete="nope">
                                                                    <option value="sedendary">Sedentary</option>
                                                                    <option value="moderateryActive">Moderatery Active</option>
                                                                    <option value="active">Active</option>

                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class="d-flex">
                                                            <div class="form-group mb-1 col-5 d-flex align-items-baseline">
                                                                <label class="mb-1">Physical Activity</label>
                                                            </div>
                                                            <div class="col-7 form-group mb-1">
                                                                <select class="form-control " formControlName="physicalActivity" autocomplete="nope">
                                                                    <option value="No">No</option>
                                                                    <option value="Yes">Yes</option>

                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class="d-flex">
                                                            <div class="form-group mb-1 col-5 d-flex align-items-baseline">
                                                                <label class="mb-1">Walking</label>
                                                            </div>
                                                            <div class="col-7 form-group mb-1">
                                                                <select class="form-control " formControlName="walking" autocomplete="nope">
                                                                    <option *ngFor="let item of walkingOpts">{{item}}</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class="d-flex">
                                                            <div class="form-group mb-1 col-5 d-flex align-items-baseline">
                                                                <label class="mb-1">Cooking / Household Work</label>
                                                            </div>
                                                            <div class="col-7 form-group mb-1">
                                                                <select class="form-control " formControlName="cookingHouseholdWork" autocomplete="nope">
                                                                    <option *ngFor="let item of cookingHouseholdWorkOpts">{{item}}</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class="d-flex" *ngIf="opNutritionalAssessment.value.cookingHouseholdWork == 'Others'">
                                                            <div class="form-group mb-1 col-5 d-flex align-items-baseline">
                                                                <label class="mb-1">Others</label>
                                                            </div>
                                                            <div class="col-7 form-group mb-1">
                                                                <textarea class="form-control " formControlName="cookingHouseholdWorkOthers" autocomplete="nope"></textarea>

                                                            </div>
                                                        </div>

                                                        <div class="d-flex">
                                                            <div class="form-group mb-1 col-5 d-flex align-items-baseline">
                                                                <label class="mb-1">Time Spend On Sitting Or Reclining in a day</label>
                                                            </div>
                                                            <div class="col-7 form-group mb-1">
                                                                <select class="form-control " formControlName="sittingOrReclining" autocomplete="nope">
                                                                    <option *ngFor="let item of timeSpendOpts">{{item}}</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class="d-flex" *ngIf="opNutritionalAssessment.value.sittingOrReclining == 'Others'">
                                                            <div class="form-group mb-1 col-5 d-flex align-items-baseline">
                                                                <label class="mb-1">Sitting Others</label>
                                                            </div>
                                                            <div class="col-7 form-group mb-1">
                                                                <textarea type="text" class="form-control" formControlName="sittingOthers"></textarea>
                                                            </div>
                                                        </div>
                                                        <div class="d-flex">
                                                            <div class="form-group mb-1 col-5 d-flex align-items-baseline">
                                                                <label class="mb-1">Exercise</label>
                                                            </div>
                                                            <div class="col-7 form-group mb-1">
                                                                <input type="text" class="form-control" formControlName="exercise" />
                                                            </div>
                                                        </div>
                                                        <div class="headingStyleOb">
                                                            <div class="trapeziumOb font-weight-semibold text-white">
                                                                <span class="p-1">Plan Of Nutrition Care</span>
                                                            </div>
                                                        </div>
                                                        <div class="d-flex" style="margin-top:10px ">
                                                            <div class="form-group mb-1 col-5 d-flex align-items-baseline">
                                                                <label class="mb-1">Nutritional Screening</label>
                                                            </div>
                                                            <div class="col-7 form-group mb-1">
                                                                <select class="form-control " formControlName="nutritionalScreening" autocomplete="nope">
                                                                    <option value="No">No</option>
                                                                    <option value="Yes">Yes</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class="d-flex">
                                                            <div class="form-group mb-1 col-5 d-flex align-items-baseline">
                                                                <label class="mb-1">Pamphlet Of Healthy Food Habit</label>
                                                            </div>
                                                            <div class="col-7 form-group mb-1">
                                                                <select class="form-control " formControlName="pamphaletOfHealthyFood" autocomplete="nope">
                                                                    <option value="No">No</option>
                                                                    <option value="Yes">Yes</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class="d-flex">
                                                            <div class="form-group mb-1 col-5 d-flex align-items-baseline">
                                                                <label class="mb-1">Nutritional Risk</label>
                                                            </div>
                                                            <div class="col-7 form-group mb-1">
                                                                <select class="form-control " formControlName="nutritionalRisk" autocomplete="nope">
                                                                    <option value="No">No</option>
                                                                    <option value="Yes">Yes</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class="d-flex">
                                                            <div class="form-group mb-1 col-5 d-flex align-items-baseline">
                                                                <label class="mb-1">Counselled By Nutritionist/Diet Plan</label>
                                                            </div>
                                                            <div class="col-7 form-group mb-1">
                                                                <select class="form-control " formControlName="counselledByNutritionist" autocomplete="nope">
                                                                    <option value="No">No</option>
                                                                    <option value="Yes">Yes</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class="d-flex">
                                                            <div class="form-group mb-1 col-5 d-flex align-items-baseline">
                                                                <label class="mb-1">Diet Recommened</label>
                                                            </div>
                                                            <div class="col-7 form-group mb-1">
                                                                <select class="form-control " formControlName="dietRecommened" autocomplete="nope">
                                                                    <option value="Null"></option>
                                                                    <option value="Normal(Balanced) marco-micro nutrients">Normal(Balanced) marco-micro nutrients</option>
                                                                    <option value="Diabetic (Low GI CHO,Moderate Protien)">Diabetic (Low GI CHO,Moderate Protien)</option>
                                                                    <option value="PIH/HIN(Low Fat(SFA),Moderate Protein)">PIH/HIN(Low Fat(SFA),Moderate Protein)</option>
                                                                    <option value="SoftDiet(Easy to digest CHO with optional fluid)">SoftDiet(Easy to digest CHO with optional fluid)</option>
                                                                    <option value="Anemia(High Biological value Protien,Fe,Vit C)">Anemia(High Biological value Protien,Fe,Vit C)</option>
                                                                    <option value="Rest wt Gain(Complex CHO,Moderate Protein)">Rest wt Gain(Complex CHO,Moderate Protein)</option>
                                                                    <option value="Low BMI(High Calorie,High Protein)">Low BMI(High Calorie,High Protein)</option>
                                                                    <option value="Others">Others</option>
                                                                    <option value="RWG + GDM">RWG + GDM</option>
                                                                    <option value="Weight Loss">Weight Loss</option>
                                                                    <option value="TLC 1">TLC 1</option>
                                                                    <option value="TLC 2">TLC 2</option>
                                                                    <option value="TLC 3">TLC 3</option>
                                                                    <option value="Pre Diabetic">Pre Diabetic</option>
                                                                    <option value="High BMI">High BMI</option>
                                                                    <option value="PCOS">PCOS</option>
                                                                    <option value="Yes">Yes</option>
                                                                    <!--<option *ngFor="let item of dietRecommenedOpts">{{item}}</option>-->

                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class="d-flex">
                                                            <div class="form-group mb-1 col-5 d-flex align-items-baseline">
                                                                <label class="mb-1">Leaflet Given / Diet Plan</label>
                                                            </div>
                                                            <div class="col-7 form-group mb-1">
                                                                <select class="form-control " formControlName="leafletGivenDietPlan" autocomplete="nope">
                                                                    <option value="No">No</option>
                                                                    <option value="Yes">Yes</option>
                                                                </select>
                                                            </div>
                                                        </div>

                                                        <div class="d-flex">
                                                            <div class="form-group mb-1 col-5 d-flex align-items-baseline">
                                                                <label class="mb-1">Prescribed Calories(Kcals/Day)</label>
                                                            </div>
                                                            <div class="col-7 form-group mb-1">
                                                                <input type="text" class="form-control" formControlName="prescribedCalories" />
                                                            </div>
                                                        </div>
                                                        <div class="d-flex">
                                                            <div class="form-group mb-1 col-5 d-flex align-items-baseline">
                                                                <label class="mb-1">Prescribed Protien(Gms/Day)</label>
                                                            </div>
                                                            <div class="col-7 form-group mb-1">
                                                                <input type="text" class="form-control" formControlName="prescribedProtien" />
                                                            </div>
                                                        </div>
                                                        <div class="d-flex">
                                                            <div class="form-group mb-1 col-5 d-flex align-items-baseline">
                                                                <label class="mb-1">Signature</label>
                                                            </div>
                                                            <div class="col-7 form-group mb-1">
                                                                <input type="text" class="form-control" formControlName="signature" />
                                                            </div>
                                                        </div>
                                                        <div class="d-flex">
                                                            <div class="form-group mb-1 col-5 d-flex align-items-baseline">
                                                                <label class="mb-1">Date</label>
                                                            </div>
                                                            <div class="col-7 form-group mb-1">
                                                                <input type="date" class="form-control" placeholder="DD/MM/YYYY" formControlName="date" (change)="getDateFormat($event)" />
                                                                <!--<input type="text" class="form-control date-picker" formControlName="date" readonly
                                                                ngbDatepicker #date="ngbDatepicker" [maxDate]="today"
                                                                (click)="date.toggle(); $event.stopPropagation();" />-->
                                                            </div>
                                                        </div>
                                                        <div class="d-flex" *ngIf="opNutritionalAssessment.value.dietRecommened == 'Others'">
                                                            <div class="form-group mb-1 col-5 d-flex align-items-baseline">
                                                                <label class="mb-1">Others</label>
                                                            </div>
                                                            <div class="col-7 form-group mb-1">
                                                                <textarea class="form-control " formControlName="dietRecommenedOthers" autocomplete="nope"></textarea>

                                                            </div>
                                                        </div>
                                                        <div class="d-flex">
                                                            <div class="form-group mb-1 col-5 d-flex align-items-baseline">
                                                                <label class="mb-1">Special Features</label>
                                                            </div>
                                                            <div class="col-7 form-group mb-1">
                                                                <textarea class="form-control" formControlName="specialFeaturesForCare"></textarea>
                                                            </div>
                                                        </div>

                                                    </div>

                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>


            </form>
        </div>
    </div>


    <div class="d-flex flex-wrap align-items-center justify-content-end mt-2 ">
        <button type="button" class="btn btn-sm btn-primary" (click)="onSubmit(false)">
            <span *ngIf="!submitting">Save</span>
            <span *ngIf="submitting">
                <span class="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
                Submitting
            </span>
        </button>
        <button *ngIf="!patientForm" type="button" class="btn btn-sm btn-primary" (click)="onSubmit(true)">
            <span *ngIf="!redirecting">Save and Next</span>
            <span *ngIf="redirecting">
                <span class="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
                redirecting
            </span>
        </button>
    </div>
</div>



import { Injectable } from "@angular/core";
import { HttpService } from "../../../../shared/services";
import { GenericResponse, GenericStatus } from "../../../../shared/models";
import { ApiResources, DateHelper } from "../../../../shared/helpers";
import { IAdmissionModel } from "../../services/models/admission.model";
import { INote } from "../pages/notes/helpers/helper";
import { IVitals } from "../pages/vitals/helpers/helper";
import { IAssessments } from "../pages/assessments/helpers/helper";
import { Subject, Observable  } from "rxjs";
import { DomSanitizer } from "@angular/platform-browser";

export const PIXEL_PER_LETTER = 9;
export const BUFFER_WIDTH = 50;
export const USER_BUFFER_WIDTH = 80;
export const SNO_WIDTH = 25;
export const UNIT_WIDTH = 70;

export const TIME_USER_BUFFER_WIDTH = 70;

export enum ActiveStatus {
    Active = 1,
    Completed = 2,
    Stopped = 3,
    NotStarted = 4
}

export class Dates {
    min: Date;
    current: Date;
    currentIndex: number;
    max: Date;
    showMin = true;
    showMax = true;
}

export class GlobalProgressReportTimeline<T, F, V,P> {
    records: Array<GlobalAllViewModel<T, F, V,P>>
    currentDayId: number;
}

export class GlobalAllViewModel<T, F, V,P> {
    medications: Array<T>;
    labs: Array<F>;
    notes: Array<INote>;
    vitals: Array<V>;
    infusions: Array<P>
    assessments: Array<IAssessments>;

    date?: Date
    
}

export class GlobalProgressReportMedication<T, F> {
    records: Array<T>;
    currentDayId: number;
    dates: Dates;
    indentMedicines: Array<F>;
}

export interface ICountsModel {
    medicationCount: number;
    labCount: number;
    notesCount: number;
    vitalsCount: number;
    assessmentsCount: number;
}

@Injectable({
    providedIn: "root"
})
export class AdmissionFetchHelper {

    constructor(
        private readonly httpService: HttpService,
        private sanitizer: DomSanitizer
    ) {

    }

    safe = (url: any) => {
        if (url) {
            return this.sanitizer.bypassSecurityTrustResourceUrl(`data:image/png;base64, ${url}`);
        }
    }

    admissionFetch = (admissionId: number, isAdmission: boolean, callback?: Function) => {
        this.httpService
            .post<GenericResponse>(ApiResources.getURI(ApiResources.serviceOrder.base, ApiResources.serviceOrder.getAdmissionBasic),
                { id: admissionId.toString(), isAdmission: isAdmission })
            .subscribe(
                (response: GenericResponse) => {
                    var data: IAdmissionModel;
                    if (response.status === GenericStatus[GenericStatus.Success]) {
                        data = response.data as IAdmissionModel;
                        response.data.thumbnailUrl = response.data.thumbnailUrl ? this.safe(response.data.thumbnailUrl) : null;
                        data = response.data as IAdmissionModel;
                    }
                    if (data != null || data != undefined) {
                        if (data.dischargeDate !== null) {
                            data.isDischarged = true;
                        }
                        if (data.isDischarged) {
                            if (data.dischargeTime && data.dischargeTime["value"] && data.dischargeTime["value"]["ticks"] !== undefined) {
                                data.dischargeTimeString =
                                    DateHelper.getTimeFromTicks(data.dischargeTime["value"]["ticks"]);
                                data.dischargeDate =
                                    new Date(data.dischargeDate.toString().replace("T00:00:00", data.dischargeTimeString));
                            }                      
                        }
                    }
                    callback(data);
                },
                () => {
                    callback(null);
                }
            );
    }
}

@Injectable({
    providedIn: "root"
})
export class TimelineCommunicationService {
    private source = new Subject<boolean>();
    get: Observable<boolean> = this.source.asObservable();
    set(isRefresh: boolean) {
        this.source.next(isRefresh);
    }
}
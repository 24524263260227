export class Counselling {
    counsellingId: number;
    patientId: number;
    patientName: string;
    umrNo: string;
    patientGender: string;
    counsellingType: string;
    payType: string;
    counsellingNo: string;
    locationId: number;
    locationName: string;
    chargeModuleTemplateId: number;
    templateName: string;
    startDate: Date;
    endDate: Date;
    insuranceCompanyId?: number;
    insuranceCompanyName?: string;
    tpaId?: number;
    tpaName?: string;
    patientOrganization?: string;
    patientSignature: string;
    counsellorSignature: string;
    patientSignatureRootPath: string;
    counsellorSignatureRootPath: string;
    attendentBase64Signature: string;
    attendentSignature: string;
    isInUse: boolean;
    isDraft: boolean;
    active: boolean;
    createdBy: number;
    createdByRoleId: number;
    createdByName: string;
    createdDate: Date;
    modifiedBy?: number;
    modifiedDate?: Date;
    modifiedByName: string;
    patientMobile: string;
    patientEmail: string;
    chargeCategoryName: string;
    packageName: string;
    packageNames: Array<string>;
    counsellingPackageId: number;
    statusName: string;
    packageModuleId: number;
    relation: string;
    relativeName: string;
    relativeOccupation: string;
    extraExclusionsNotes: string;

}

export interface ICounsellingPatientInfo {
    patientName: string;
    umrNo: string;
    patientGender: string;
    patientType: string;
}
import { Component, OnInit, EventEmitter, Input, OnDestroy, Output, ViewEncapsulation } from "@angular/core";
import { Page, IUserAccount } from "../../models";
import { takeUntil, finalize } from "rxjs/operators";
import { LabBillHeaderModel } from "../../../areas/admin/labs/pages/models";
import { ApiResources } from "../../helpers";
import { AppData, HttpService, PrintOptionService } from "../../services";
import { Appointment, Patient, Setting } from "../../entities";
import { ServiceBillHeaderModel } from "../../../areas/admin/op-services/pages/models";

@Component({
    templateUrl: "./service-invoice.html",
    selector: "service-invoice",
    styleUrls: ["./service-invoice.css"],
    encapsulation: ViewEncapsulation.None
})

export class ServiceInvoiceWidget implements OnInit, OnDestroy {
    @Input() serviceBookingHeaderId: number;
    @Input() encryptedNewLabBookingHeaderId: string;
    @Input() showFinanceandPrintByDetails: boolean;
    @Output() onClose = new EventEmitter<any>();
    @Input() isNewlabinvoiceId: boolean;
    @Input() isPrintLogo: boolean;
    @Input() isFooter: boolean;
    page: Page;
    loading: boolean;
    //QrCode: string = null;
    currentDate: Date;
    bill: Array<ServiceBillHeaderModel>;
    //isShowTestDurationTime: boolean;
    constructor(
        private readonly appData: AppData,
        private readonly httpService: HttpService,
        private readonly printOptionService: PrintOptionService,
    ) {
        this.page = new Page();
        //this.age = new Age();
        this.bill = new Array<ServiceBillHeaderModel>();
        this.currentDate = new Date();
    }

    ngOnInit() {
        this.appData.userAccount
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe((userAccount: IUserAccount) => {
                if (userAccount) {
                    this.page.userAccount = userAccount;
                    this.fetchInvoice();
                    this.printOptionService.get((is) => { this.isPrintLogo = is; });
                } else {
                    this.page.userAccount = undefined;
                    this.fetchInvoice();
                    this.printOptionService.get((is) => { this.isPrintLogo = is; });
                }
            });
    }

    ngOnDestroy() {
        this.page.unsubscribeAll();
    }

    onCloseModal() {
        this.onClose.emit();
    }

    private fetchInvoice() {
        const request = {
            serviceBookingHeaderId: this.serviceBookingHeaderId

        };
        if (this.showFinanceandPrintByDetails == null && this.showFinanceandPrintByDetails == undefined) {
            this.showFinanceandPrintByDetails == false;
        }
        this.loading = true;
        this.httpService.post(ApiResources.getURI(ApiResources.serviceTransaction.base, ApiResources.serviceTransaction.fetchSelectedInvoiceData), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.loading = false))
            .subscribe(
                (response: Array<ServiceBillHeaderModel>) => {
                    this.bill = response;
                    console.log(this.bill);
                });
        this.currentDate = new Date();
    }

}
export class Admission {
    admissionId: number;
    encryptedAdmissionId: string;
    departmentId: number;
    providerId: number;
    providerName: string;
    providerGender: string;
    providerAge?: number;
    providerThumbnailUrl: string;
    practiceName: string;
    patientId: number;
    encryptedPatientId: string;
    patientFamilyId?: number;
    patientAccountId: number;
    patientName: string;
    patientAge?: number;
    patientGender: string;
    patientMobile: string;
    patientEmail: string;
    floorName: string;
    patientThumbnailUrl: string;
    patientCountryId: number;
    patientCountryCode: string;
    visitType: string;
    roomId: number;
    bedId: number;
    wardId: number;
    bedNumber: string;
    roomName: string;
    wardName: string;
    surgeryTypeId: number;
    isMaternity: string;
    attendantName: string;
    attendantRelationWithPatient: string;
    attendantContactNo: string;
    admissionDate: Date;
    admissionTime: object;
    admissionTimeString: string;
    patientType: string;
    admissionNo: string;
    isDischarged: boolean;
    dischargeDate: Date;
    dischargeTime: object;
    dischargeTimeString: string;
    admissionNotes: string;
    couponId: number;
    amount: number;
    discount: number;
    currencySymbol: string;
    total: number;
    status: string;
    active: boolean;
    expired: boolean;
    createdBy: number;
    createdDate: Date;
    totalItems: number;
    encounterType: string;
    reason: string;
    paidAmount: number;
    finalAmount: number;
    paymentStatus: string;
    isFinalBill: boolean;
    umrNo: string;
    cancelling: boolean;
    pendingMedicines: number;
    progressReportId: number;
    medicationInfo: MedicationInfo;
    followUpDaysForIp?: number;
    editExpectedDischargeDate: boolean;
    expectedDischargeDate: Date;
    expectedDischargeDateView: any;
    isUpdatingDischargeDate: boolean;
    // dischargedBy: number;
    dischargedByRole: string;
    dischargedByName: string;
    visitTypeId: number;
    patientPriorityId: number;
    locationId: number;
    locationName: string;
    serviceOrder: any;
    bedStatusId: number;
    bedStatusName: string;
    floorId: number;
    swap: boolean;
    insuranceCompanyId: number;
    payType: string;
    caseTypeId: number;
    referralDoctorId: number;
    tpaId: number;
    patientOrganization: string;
    bedAssociatedData: string;
    surrogacyData?: SurrogacyInfo;
    emergencyInfo: EmergencyInformation;
    nriData: nriInfo;
    chargeCategoryId: number;
    chargeCategoryName: string;
    doctorUnitMasterId: number;
    packageModuleId?: number;
    admissionPackageId?: number;
    admissionBedChangeRequestId?: number;
    requestComments?: number;
    chargeCategoryNames?: number;
    currentRoomId?: number;
    currentRoomName: string;
    breakfast: object;
    lunch: object;
    dinner: object;
    unitName: string;
    city: string;
    state: string;
    addressLine2: string;
    streetAddress: string;
    paymentResponsibleName: string;
    billingComments; string;
    admissionVisitTypeId: number;
    admissionTypeId: number;
    babyTypeId: number;
    isMLC: boolean;
    isNewBornBaby: boolean;
    currentUnit: string;
    currentBed: string;
    clinicalNotes: string;
    mlcNm: string;
    mlcType: any;
    mlcNo: any;
    motherName: string;
    motherIPNo: any;
    rpRelationId: any;
    rpOccupationId: any;
    paymentRPEmail: string;
    paymentRPMobile: any;
    paymentRPAddress: any;
    bornTypeId: any;
    chargeModuleTemplateId: number;
    hwcName: string;
    roomRent: number;
    dateOfBirth: Date;
    nationality: string;
    religion: string;
    occupation: string;
    email: string;
    relativeAge: number;
    relativeMobileNo: string;
    specializations: Array<number>;
    specializationId: number;
    specializationName: string;
    encounterName: string;
    encounterTypeId: number;
    education: string;
    relativeOccupation: string;
    relativeEducation: string;
    admissionPaymentResponsibleId: number;
    zipcode: number;
    readyforDischarge: boolean;
    currentBedNumber: string;
    currentWardName: string;
    currentFloorName: string;
    messageCount: number;
    cancelledDate: Date;
    internalProviderId: number;
    externalProviderId: number;
    internalDoctor: string;
    externalDoctor: string;
    patientInsuranceId: number
    insuranceTypeId: number
    validity: Date
    cardNo: string
    insuranceCompany: string
    tpaName: string
    insuranceManagementId?: number;
    insuranceReceipts: Array<InsuranceReceipt>;
    insuranceAmount: number;
    surgeryId: number;
    isDischargeSummary: boolean;
    cashPatients: number;
    insurancePatients: number;
    patientsType: string;
    totalPatients: number;
    fullName: string;
    bedCount: number;
    isGatePassGenerated: boolean;
    isCheckOut: boolean;
    roleName: string; 
    address: string;
    dateOfDischarge: Date;
}

class MedicationContentViewModel {
    count: number
}

class MedicationViewModel {
    missed: MedicationContentViewModel;
    pending: MedicationContentViewModel;
    next: MedicationContentViewModel;
}

export class MedicationInfo {
    admissionId: number;
    medications: MedicationViewModel;
    labs: MedicationContentViewModel;
    notes: MedicationContentViewModel;
}
export class SurrogacyInfo {
    geneticMotherName?: string;
    age?: number;
    geneticFatherName?: string;
    address?: string;
    phoneNo?: number;
    bloodGroup?: string;
    rhType?: string;
    fromDate?: Date;
    toDate?: Date;
}

export class nriInfo {
    isNri?: boolean;
    memberIdcardNo?: string;
    unit?: number;
    policyNo?: string;
    cardHolder?: string;
    serviceNo?: string;
    refDate?: Date;
    rank?: number;
    refSerialNo?: string;
    insuranceCode?: string;
    organisation?: string;
    insuranceCo?: string;
    panCardNo?: string;
    passportNo?: string;
    ppAddress?: string;
    phoneNo?: number;
    ppIssuePlace?: string;
    afterDischarge?: string;
}
export class EmergencyInformation {
    emergencyContactNo?: number;
    emergencyTypeId?: string;
}

export class InsuranceReceipt {
    insuranceReceiptId: number;
    insuranceManagementId: number;
    amount: number;
    status: string;
    tpaTxnRef: string;
    tpaDocRef: string;
    documentUrl: string;
    isImage: boolean;
    isVideo: boolean;
    contentType: string;
    formedUrl: any;
    base64: string;
}

import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { supportFilter } from "../../shared/models/support-filter"

@Injectable({
    providedIn: 'root'
})
export class SupportService {
    private supportFilterSubject = new BehaviorSubject<supportFilter | null>(null);
    getFilters$ = this.supportFilterSubject.asObservable();

    setFilters(item: supportFilter): void {
        this.supportFilterSubject.next(item);
    }
}
import { ForgotPasswordPage } from "@account/forgot-password/forgot-password.page";
import { LoginPage } from "@account/login/login.page";
import { PatientWhatsappFeedback } from "@account/patient-whatsapp-Feedback/patient-whatsapp-feedback-page";
import { OPDregistrationPage } from "@account/opd-registration/opd-registration.page";
import { AccountsPage } from "@admin/accounts/accounts.page";
import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { NotFoundPage } from "@error/not-found/not-found.page";
import { ServerErrorPage } from "@error/server-error/server-error.page";
import { AccessGuard, AuthGuard, DeactivateGuard, TelemedicineGuard } from "@shared/guards";
import { LayoutComponent } from "./areas/layout/layout.component";
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from "@angular/forms";
import { OPNutritionalAssessmentPage } from '@src/app/areas/admin/diet-plan-encounter/pages/op-nutritional-assesment/op-nutritional-assesment.page'
import { QueuePage } from "./areas/admin/queue/queue.page";
import { ConsentFromModule } from "./areas/admin/consent-forms/consent-forms.module";

// STARTING PAGES
const startingRoutes = [
    { path: "login", canActivate: [AccessGuard], component: LoginPage, data: { title: "Login", anonymous: true } },
    { path: "queue", canActivate: [AccessGuard], component: QueuePage, data: { title: "Queue", anonymous: true } },
    { path: "forgot-password", canActivate: [AccessGuard], component: ForgotPasswordPage, data: { title: "Forgot Password", anonymous: true } },
    { path: "opd-registration", canActivate: [AccessGuard], component: OPDregistrationPage, data: { title: "OPD Registration", anonymous: true } },
    { path: "opd-registration/:id", canActivate: [AccessGuard], component: OPDregistrationPage, data: { title: "OPD Registration", anonymous: true } },
    { path: "patientForm/diet-plan-encounter/:id/b/nutritionalDiet", canActivate: [AccessGuard], component: OPNutritionalAssessmentPage, data: { title: "Nutritional Form", anonymous: true } },
    { path: "patient-whatsapp-feedback", canActivate: [AccessGuard], component: PatientWhatsappFeedback, data: { title: "Patient-Whatsapp-Feedback", anonymous: true } },
]
const startingRouteComponents = [LoginPage, ForgotPasswordPage, OPDregistrationPage, OPNutritionalAssessmentPage, PatientWhatsappFeedback];

// ERROR PAGES
const errorRoutes = [
    { path: "not-found", component: NotFoundPage, data: { title: "Not Found" } },
    { path: "server-error", component: ServerErrorPage, data: { title: "Server Error" } },
]
const errorRouteComponents = [NotFoundPage, ServerErrorPage];


// HIDDEN PAGES
const hiddenPages = [
    { path: "accounts", component: AccountsPage, data: { title: "Accounts", } }
]
const hiddenPagesComponents = [AccountsPage];

export const routes: Routes = [
    { path: "", redirectTo: "login", pathMatch: "full" },
    ...startingRoutes,
    ...errorRoutes,
    {
        path: "app",
        component: LayoutComponent,
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        children: [
            { path: "", redirectTo: "dashboard", pathMatch: "full" },
            ...hiddenPages,
            {
                path: "masters",
                loadChildren: () => import('./areas/admin/masters/masters.module').then(m => m.MastersModule)
            },
            {
                path: "admissions",
                loadChildren: () => import('./areas/admin/admissions/admissions.module').then(m => m.AdmissionsModule)
            },
            {
                path: "ambulance",
                loadChildren: () => import('./areas/admin/ambulance/ambulance.module').then(m => m.AmbulanceModule)
            },
            {
                path: "pharmacy",
                loadChildren: () => import('./areas/admin/pharmacy/pharmacy.module').then(m => m.PharmacyModule)
            },
            {
                path: "patients",
                loadChildren: () => import('./areas/admin/patients/patients.module').then(m => m.PatientsModule)
            },
            {
                path: "patient/:id",
                loadChildren: () => import('./areas/admin/patient/patient.module').then(m => m.PatientModule)
            },
            {
                path: "patient-multiple-forms",
                loadChildren: () => import('./areas/admin/patient-multiple-forms/scan-notification.module').then(m => m.ScanNotificationModule)
            },
            {
                path: "scan-appointment",
                loadChildren: () => import('./areas/admin/scan-test/scan.module').then(m => m.ScanModule)
            },
            {
                path: "provider-scanappointment-booking",
                loadChildren: () => import('./areas/admin/provider-scanappointment-booking/provider-scanappointment-booking.module').then(m => m.ProviderScanAppointmentModule)
            },
            {
                path: "menus",
                loadChildren: () => import('./areas/admin/menus/menus.module').then(m => m.MenusModule)
            },
            {
                path: "charge-module",
                loadChildren: () => import('./areas/admin/charge-module/charge-module.module').then(m => m.ChargeModuleModule)
            },
            {
                path: "labs",
                loadChildren: () => import('./areas/admin/labs/labs.module').then(m => m.LabsModule)
            },

            {
                path: "diet",
                loadChildren: () => import('./areas/admin/diet/diet.module').then(m => m.DietModule)
            },
            {
                path: "logs",
                loadChildren: () => import('./areas/admin/logs/logs.module').then(m => m.LogsModule)
            },
            {
                path: "patient-reports",
                loadChildren: () => import('./areas/admin/reports/report.module').then(m => m.ReportModule)
            },
            {
                path: "health-card",
                loadChildren: () => import('./areas/admin/health-card/health-card.module').then(m => m.HealthCardModule)
            },
            {
                path: "providers",
                loadChildren: () => import('./areas/admin/providers/providers.module').then(m => m.ProvidersModule)
            },
            {
                path: 'out-patients',
                loadChildren: () => import('./areas/admin/appointments/appointments.module').then(m => m.AppointmentsModule)
            },
            {
                path: 'quick-schedule',
                loadChildren: () => import('./areas/admin/quick-schedule/quick-schedule.module').then(m => m.QuickScheduleModule)
            },

            {
                path: "multiple-session-schedule",
                loadChildren: () => import('./areas/admin/multiple-session-schedule/multiple-session-schedule.module').then(m => m.MultipleSessionScheduleModule)
            },
            {
                path: 'quick-schedule/:type',
                loadChildren: () => import('./areas/admin/quick-schedule/quick-schedule.module').then(m => m.QuickScheduleModule)
            },
            {
                path: 'quick-schedule/:notification',
                loadChildren: () => import('./areas/admin/quick-schedule/quick-schedule.module').then(m => m.QuickScheduleModule)
            },
            {
                path: 'quick-schedule/:type/:ispotential',
                loadChildren: () => import('./areas/admin/quick-schedule/quick-schedule.module').then(m => m.QuickScheduleModule)
            },
            {
                path: 'quick-schedule/:type/:notification',
                loadChildren: () => import('./areas/admin/quick-schedule/quick-schedule.module').then(m => m.QuickScheduleModule)
            },
            {
                path: "gyn-encounter/:id/:type",
                loadChildren: () => import('./areas/admin/gyn-encounter/gyn-encounter.module').then(m => m.GynEncounterModule)
            },
            {
                path: "gyn-ip-encounter/:id/:type",
                loadChildren: () => import('./areas/admin/gyn-encounter/gyn-encounter.module').then(m => m.GynEncounterModule)
            },
            {
                path: "pediatric-encounter/:id/:type",
                loadChildren: () => import('./areas/admin/pediatric-encounter/pediatric-encounter.module').then(m => m.PediatricEncounterModule)
            },
            {
                path: "neonatal-ip-encounter/:id/:type",
                loadChildren: () => import('./areas/admin/neonatal-ip-encounter/neonatal-ip-encounter.module').then(m => m.NeonatalEncounterModule)
            },
            {
                path: 'session-schedule/:type',
                loadChildren: () => import('./areas/admin/session-schedule/session-schedule.module').then(m => m.SessionScheduleModule)
            },
            {
                path: "advance-dashboard",
                loadChildren: () => import('./areas/admin/advance-dashboard/advance-dashboard.module').then(m => m.AdvanceDashboardModule)
            },

            // { path: "dashboard", component: PatientDashboardPage, data: { title: "Patient-Dashboard" } },
            {
                path: "services/:id/:type",
                loadChildren: () => import('./areas/admin/services/services.module').then(m => m.ServicesModule)
            },
            {
                path: "gate-pass/:id/:type",
                loadChildren: () => import('./areas/admin/gate-pass/gate-pass.module').then(m => m.GatepassModule)
            },
            {
                path: "diet-plan-encounter/:id/:type",
                loadChildren: () => import('./areas/admin/diet-plan-encounter/diet-plan-encounter.module').then(m => m.DietPlanEncounterModule)
            },
            {
                path: "registers/:id/:pid/:type",
                loadChildren: () => import('./areas/admin/registers/registers.module').then(m => m.RegisterModule)
            },
            {
                path: "nurse-module",
                loadChildren: () => import('./areas/admin/nurse-module/nurse-module.module').then(m => m.NurseModule)
            },
            {
                path: "rota",
                loadChildren: () => import('./areas/admin/rota/rota.module').then(m => m.RotaModule)
            },
            {
                path: "progress-report/:id/:type",
                loadChildren: () => import('./areas/admin/progress-report/progress-report.module').then(m => m.ProgressReportModule)
            },
            {
                path: "bed-management",
                loadChildren: () => import('./areas/admin/bed-management/bed-management.module').then(m => m.BedManagementModule)
            },
            {
                path: "web-notification",
                loadChildren: () => import('./areas/admin/web-notification/web-notification.module').then(m => m.WebNotificationModule)
            },
            {
                path: "patient-feedback",
                loadChildren: () => import('./areas/admin/patient-feedback/patient-feedback.module').then(m => m.PatientFeedbackPageModule)
            },
            {
                path: "emergency-dashboard",
                loadChildren: () => import('./areas/admin/emergency-dashboard/emergency-dashboard.module').then(m => m.EmergenyEncounterModule)
            },
            {
                path: "appointments-view",
                loadChildren: () => import('./areas/admin/appointments-view/appointments-view.module').then(m => m.AppointmentsViewModule)
            },
            {
                path: "appointments-fetch",
                loadChildren: () => import('./areas/admin/appointments-fetch/appointments-fetch.module').then(m => m.AppointmentsFetchModule)
            },
            {
                path: "appointment-dashboard",
                loadChildren: () => import('./areas/admin/appointment-dashboard/appointment-dashboard.module').then(m => m.AppointmentDashboardModule)
            },
            {
                path: "book-appointment",
                loadChildren: () => import('./areas/admin/book-appointment/book-appointment.module').then(m => m.BookAppointmentModule)
            },
            {
                path: "appointments-view/:patientId/:providerId/:specializationId",
                loadChildren: () => import('./areas/admin/appointments-view/appointments-view.module').then(m => m.AppointmentsViewModule)
            },
            {
                path: "ticket",
                loadChildren: () => import('./areas/admin/ticket/ticket.module').then(m => m.TicketModule)
            },
            {
                path: "tickets",
                loadChildren: () => import('./areas/admin/tickets/tickets.module').then(m => m.TicketsModule)
            },
            {
                path: "ticket-details",
                loadChildren: () => import('./areas/admin/ticket-details/ticket-details.module').then(m => m.TicketDetailsModule)
            }, {
                path: "ticket-details/:id",
                loadChildren: () => import('./areas/admin/ticket-details/ticket-details.module').then(m => m.TicketDetailsModule)
            },
            {
                path: "emergency-encounter/:id/:type",
                loadChildren: () => import('./areas/admin/emergency-encounter/emergency-encounter.module').then(m => m.EmergencyEncounterModule)
            },
            {
                path: "tender",
                loadChildren: () => import('./areas/admin/tender-management/tender-management.module').then(m => m.TenderModule)
            },
            {
                path: "physiotherapy-encounter/:id/:type",
                loadChildren: () => import('./areas/admin/physiotherapy-encounter/physiotherapy-encounter.module').then(m => m.PhysiotherapyEncounterModule)
            },
            {
                path: "template",
                loadChildren: () => import('./areas/admin/template-design/template-design.module').then(m => m.TemplateDesignModule)
            },
            {
                path: "supplier",
                loadChildren: () => import('./areas/admin/supplier/supplier.module').then(m => m.SupplierModule)
            },
            {
                path: "company",
                loadChildren: () => import('./areas/admin/company/company.module').then(m => m.CompanyModule)
            },
            {
                path: "ob-encounter/:id/:type",
                loadChildren: () => import('./areas/admin/ob-encounter/ob-encounter.module').then(m => m.ObEncounterModule)
            },
            {
                path: "ob-ip-encounter/:id/:type",
                loadChildren: () => import('./areas/admin/ob-encounter/ob-encounter.module').then(m => m.ObEncounterModule)
            },
            {
                path: "queue",
                loadChildren: () => import('./areas/admin/queue/queue.module').then(m => m.QueueModule)
            },
            {
                path: "inventory",
                loadChildren: () => import('./areas/admin/inventory/inventory.module').then(m => m.InventoryModule)
            },
            {
                path: "genetic-speciality/:id/:type",
                loadChildren: () => import('./areas/admin/genetic-speciality-encounter/genetic-speciality.module').then(m => m.GeneticSpecialityModule)
            },
            {
                path: "vaccine",
                loadChildren: () => import('./areas/admin/vaccines/vaccines.module').then(m => m.VaccinesModule)
            },
            {
                path: "leave-managment",
                loadChildren: () => import('./areas/admin/leave-managment/leave-managment.module').then(m => m.LeavemanagmentModule)
            },
            {
                path: "cancel-appointment",
                loadChildren: () => import('./areas/admin/cancel-appointment/cancel-appointment.module').then(m => m.CancelAappointmentModule)
            },

            {
                path: "ivf-encounter/:id/:type",
                loadChildren: () => import('./areas/admin/ivf-encounter/ivf-encounter.module').then(m => m.IVFEncounterModule)
            },
            {
                path: "ivf-new-encounter/:id/:type",
                loadChildren: () => import('./areas/admin/ivf-encounter/ivf-encounter.module').then(m => m.IVFEncounterModule)
            },
            {
                path: "internal-medicine/:id/:type",
                loadChildren: () => import('./areas/admin/internal-medicine/internal-medicine.module').then(m => m.InternalMedicineEncounterModule)
            },
            {
                path: "ivf-encounter/:id/:type",
                loadChildren: () => import('./areas/admin/ivf-encounter/ivf-encounter.module').then(m => m.IVFEncounterModule)
            },
            {
                path: "provider-appointment-booking",
                loadChildren: () => import('./areas/admin/provider-appointment-booking/provider-appointment-booking.module').then(m => m.ProviderAppointmentModule)
            },
            {
                path: "provider-ot-appointment",
                loadChildren: () => import('./areas/admin/provider-otappointment-booking/provider-otappointment-booking.module').then(m => m.ProviderOTAppointmentModule)
            },
            {
                path: "appointment-schedule",
                loadChildren: () => import('./areas/admin/appointment-schedule/appointment-schedule.module').then(m => m.AppointmentScheduleModule)
            },
            {
                path: "appointment-schedule/:id",
                loadChildren: () => import('./areas/admin/appointment-schedule/appointment-schedule.module').then(m => m.AppointmentScheduleModule)
            },
            {
                path: "appointment-scheduler",
                loadChildren: () => import('./areas/admin/front-office-scheduler/provider-schedule.module').then(m => m.ProviderScheduleModule)
            },
            {
                path: "practices",
                loadChildren: () => import('./areas/admin/practices/practices.module').then(m => m.PracticesModule)
            },
            {
                path: "provider-medication",
                loadChildren: () => import('./areas/admin/provider-medication/provider-medication.module').then(m => m.ProviderMedicationModule)
            },
            {
                path: "paytm/:id",
                loadChildren: () => import('./areas/admin/paytm/paytm.module').then(m => m.PaytmModule)
            },
            {
                path: "provider/:id",
                loadChildren: () => import('./areas/admin/provider/provider.module').then(m => m.ProviderModule)
            },
            {
                path: "init-payment",
                loadChildren: () => import('./areas/admin/init-payment/init-payment.module').then(m => m.InitPaymentModule)
            },

            {
                path: "operation-theater",
                loadChildren: () => import('./areas/admin/operation-theater/operation-theater.module').then(m => m.OTModule)
            },
            {
                path: "settings",
                loadChildren: () => import('./areas/admin/settings/settings.module').then(m => m.SettingsModule)
            },
            {
                path: "patient-services",
                loadChildren: () => import('./areas/admin/quick-schedule/quick-schedule.module').then(m => m.QuickScheduleModule)
            },
            {
                path: "quick-services",
                loadChildren: () => import('./areas/admin/quick-schedule/quick-schedule.module').then(m => m.QuickScheduleModule)
            },
            {
                path: "dashboard",
                // " { path: "dashboard", component: PatientDashboardPage, data: { title: "Patient - Dashboard" } },"
                loadChildren: () => import('./areas/admin/pateint-dashboard/pateint-dashboard.module').then(m => m.PatientDashboardModule)
            },
            {
                path: "nurse-assessment/:id/:type",
                loadChildren: () => import('./areas/admin/nurse-assessment-forms/nurse-assessment-forms.module').then(m => m.NurseAssessmentModule)
            },
            {
                path: "nurse-dashboard",
                loadChildren: () => import('./areas/admin/nurse-dashboard/nurse-dashboard.module').then(m => m.NurseDashBoardModule)
            },
            {
                path: "ip-money-receipt",
                loadChildren: () => import('./areas/admin/ip-money-receipt/ip-money-receipt.module').then(m => m.IPMoneyReceiptModule)
            },
            {
                path: "hdfc",
                loadChildren: () => import('./areas/admin/hdfc/hdfc.module').then(m => m.HDFCModule)
            },
            {
                path: "register-report",
                loadChildren: () => import('./areas/admin/register-report/register-report.module').then(m => m.RegisterReportModule)
            },
            {
                path: "patient-feedback",
                loadChildren: () => import('./areas/admin/patient-feedback/patient-feedback.module').then(m => m.PatientFeedbackPageModule)
            },
            {
                path: "consent-forms/:id/:pid/:type",
                loadChildren: () => import('./areas/admin/consent-forms/consent-forms.module').then(m => ConsentFromModule)
            },
            {
                path: "counselling-dashboard",
                loadChildren: () => import('./areas/admin/counselling-dashboard/counselling-dashboard.module').then(m => m.CounsellingDashboardModule)
            },
            {
                path: "support-summary",
                loadChildren: () => import('./areas/admin/support-summary/support-summary.module').then(m => m.SupportSummaryModule)
            },
            {
                path: "support-details",
                loadChildren: () => import('./areas/admin/support-details/support-details.module').then(m => m.SupportDetailsModule)
            },
            {
                path: "ivf-encounter-sp/:id/:type",
                loadChildren: () => import('./areas/admin/ivf-encounter/ivf-encounter.module').then(m => m.IVFEncounterModule)
            },
            {
                path: "ophthalmology-encounter/:id/:type",
                loadChildren: () => import('./areas/admin/ophthalmology-encounter/ophthalmology-encounter.module').then(m => m.OphtholmologyEncounterModule)
            },
            { path: "**", redirectTo: "not-found" },
        ]
    },
    {
        path: "new-lab-bills/:id/:billType",
        loadChildren: () => import('./areas/new-lab-bills/new-lab-bills.module').then(m => m.NewLabBillsModule)
    },
    {
        path: "new-lab-webreports/:requisitionNumber",
        loadChildren: () => import('./areas/new-lab-mainreports/new-lab-mainreports.module').then(m => m.NewLabMainReportsModule)
    },

    {
        path: "new-lab-reports/:id",
        loadChildren: () => import('./areas/new-lab-reports/new-lab-reports.module').then(m => m.NewLabReportsModule)
    },

    {
        path: "scan-report-invoice/:appointmentIdReceiptId",
        loadChildren: () => import('./areas/scan-report-invoice/scan-report-invoice.module').then(m => m.ScanReportInvoiceModule)
    },

    {
        path: "patient-salesbill/:id",
        loadChildren: () => import('./areas/pharmacy-billreport-patient/patient-salesbill-report.module').then(m => m.PatientsalesBillReportModule)
    },

    {
        path: "appointment-invoice/:encryptedAppointmentId/:value",
        loadChildren: () => import('./areas/appointment-invoice/appointment-invoice.module').then(m => m.AppointmentInvoiceModule)
    },

    {
        path: "diet-reports/:appointmentId/:value/:id",
        loadChildren: () => import('./areas/diet-report/diet-report.module').then(m => m.DietReportsModule)
    },

    {
        path: "lab-reports/:type/:detailId/:headerId",
        loadChildren: () => import('./areas/lab-reports/lab-reports.module').then(m => m.LabReportssModule)
    },
    {
        path: "full-transcript/:id",
        loadChildren: () => import('./areas/admin/full-transcript/full-transcript.module').then(m => m.FullTranscriptModule)
    },

    {
        path: "print-vaccination/:id",
        loadChildren: () => import('./areas/admin/print-vaccination/print-vaccination.module').then(m => m.PrintVaccinationModule)
    },
    {
        path: "print-vaccination-certificate/:id",
        loadChildren: () => import('./areas/admin/print-vaccination-certificate/print-vaccination-certificate.module').then(m => m.PrintVaccinationCertificateModule)
    },

    {
        path: "full-transcript/:value/:id",
        loadChildren: () => import('./areas/admin/full-transcript/full-transcript.module').then(m => m.FullTranscriptModule)
    },
    {
        path: "full-transcript-mobile/:value/:id/:bypass",
        loadChildren: () => import('./areas/admin/full-transcript/full-transcript.module').then(m => m.FullTranscriptModule)
    },
    {
        path: "print-prescription/:value/:id/:isAdmission",
        loadChildren: () => import('./areas/admin/print-prescription/print-prescription.module').then(m => m.PrintPriscriptionModule)
    },
    {
        path: "print-prescription/:value/:id",
        loadChildren: () => import('./areas/admin/print-prescription/print-prescription.module').then(m => m.PrintPriscriptionModule)
    },
    {
        path: "print-gatepass-invoice/:value",
        loadChildren: () => import('./areas/admin/print-gatepass-invoice/print-gatepass-invoice.module').then(m => m.GatePassReportInvoiceModule)
    },    
    { path: "**", redirectTo: "not-found" },
];






// ----- ROUTING MODULE -----

@NgModule({
    imports: [ReactiveFormsModule, CommonModule, RouterModule.forRoot(routes, { useHash: true, enableTracing: false, onSameUrlNavigation: "reload" })],
    exports: [RouterModule]
})
export class AppRoutingModule { }

export const routePages = [
    ...startingRouteComponents,
    ...errorRouteComponents,
    ...hiddenPagesComponents,


];
export const routeGuards = [AccessGuard, AuthGuard, DeactivateGuard, TelemedicineGuard];
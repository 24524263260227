import { Component, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { NavigationEnd, NavigationStart, Router, Event as RouterEvent } from "@angular/router";
import { NgbDatepickerConfig, NgbTooltipConfig, } from "@ng-bootstrap/ng-bootstrap";
import { AppData, IdentityService, TimelineToggleService } from "@shared/services";


@Component({
    selector: "app-root",
    template: `
        <ngx-loading-bar [color]="'#3283f6'"></ngx-loading-bar>
        <router-outlet></router-outlet>
    `
})
export class AppComponent implements OnInit {
    constructor(
        datePickerConfig: NgbDatepickerConfig,
        toolTipConfig: NgbTooltipConfig,
        private readonly router: Router,
        private readonly titleService: Title,
        private readonly identityService: IdentityService,
        private readonly appData: AppData,
        public timelineService: TimelineToggleService
    ) {
        datePickerConfig.outsideDays = "collapsed";
        datePickerConfig.navigation = "select";
        toolTipConfig.container = "body";
    }

    ngOnInit() {
        this.router.events.subscribe((event: RouterEvent) => {
            if (event instanceof NavigationStart) {
                $("body,html").animate({ scrollTop: 0 });
            }

            if (event instanceof NavigationEnd) {
                let root = this.router.routerState.snapshot.root;
                if (this.router.url.indexOf("/encounter/") > -1) {
                    $("#titleEncounter").show();
                    $("#titleInternalMedicine").hide();
                    $("#titlePatient").hide();
                    $("#navAppointments").addClass("selected");
                    $("#navPatients").removeClass("selected");
                }
                else if (this.router.url.indexOf("/internal-medicine/") > -1 || this.router.url.indexOf("/behavioral-health/") > -1) {
                    $("#titleEncounter").hide();
                    $("#titlePatient").hide();
                    $("#titleInternalMedicine").show();
                    $("#navAppointments").addClass("selected");
                    $("#navPatients").removeClass("selected");
                }
                else if (this.router.url.indexOf("/patient/") > -1) {
                    $("#titleEncounter").hide();
                    $("#titleInternalMedicine").hide();
                    $("#titlePatient").show();
                    $("#navAppointments").removeClass("selected");
                    $("#navPatients").addClass("selected");
                } else {
                    $("#titleEncounter").hide();
                    $("#titleInternalMedicine").hide();
                    $("#titlePatient").hide();
                    $("#navAppointments").removeClass("selected");
                    $("#navPatients").removeClass("selected");
                }

                while (root) {
                    if (root.children && root.children.length) {
                        root = root.children[0];
                    } else if (root.data && root.data["title"]) {

                        let showSidebar: boolean;
                        let showTimeline: boolean;
                        try {
                            showSidebar = root.parent.data["showSidebar"] || this.router.url.indexOf("/internal-medicine/") > -1;
                            showTimeline = root.parent.data["showTimeline"] || this.router.url.indexOf("/internal-medicine/") > -1;
                        } catch (e) {
                            showSidebar = false;
                            showTimeline = false;
                        }

                        $("body").attr("data-sidebar-size", (showSidebar || this.router.url.indexOf("app") === -1) ? "default" : "condensed");
                        $("body").attr("data-timeline-size", ((showTimeline && this.timelineService.showBox == true) || this.router.url.indexOf("app") === -1) ? "timeline" : "timelineabsent");

                        this.titleService.setTitle(root.data["title"] + " | Careaxes");
                        return;
                    } else {
                        return;
                    }
                }
            }
        });

        this.identityService.get().subscribe(userAccount => {
            if (userAccount) {
                this.appData.setAccount(userAccount);
            }
        });
    }
}
export class OutPatient {
    providerId: string;
    departmentId: number;
    fromDate: Date;
    toDate: Date;
    appointmentDate: Date;
    appointmentTime: object;
    appointmentTimeString: string;
    visitType: string;
    totalAppointments: number;
    totalAmount: number;
    appointmentNo: string;
    departmentName: string;
    patientId: number;
    patientName: string;
    patientAge: number;
    patientGender: string;
    providerAge: number;
    providerName: string;
    totalItems: number;
    mobile: string;
    followUpForAppointmentId: number;
    appointmentType: string;
    referredBy: string;
    referredByName: string;
    paymentType: string;
    receiptCreatedByName: string;
    receiptDate: Date;
    receiptId: number;
    receiptNo: string;
    umrNo: string;
    fullAddress: string;
    streetAdress: string;
    city: string;
    state: string;
    zipcode: string;
    fatherOrHusband: string;
    totAmt: number;
    name: string;
    appointmentTypeName: string;
    paymentStatus: boolean;
    EncounterType: string;
    status: string;
    locationName: string;
    firstName: string;
    middleName: string;
    lastName: string;
    dateOfBirth?: Date;
    appointmentId: number;
    total: number;
    freeFollowUpDaysLimit: number;
    freeFollowUpValidityDate: Date;
    salucro: string;
    encryptedAppointmentId?: string;
    specializationName: string;
    surgeryName: string;
    surgeryId?: number;
    visitTypeId?: number;
    consultationTypeId?: number;
    fullName: string;
    encounterName: string;
    encounterTypeId: number;
    typeOfPayment: string;
    admissionNo?: string;
    admissionTime?: string;
    admissionDate?: string;
    isHealthCard?: boolean = false;
    reminder: string;
    roleName: string;
    reason: string;
}
/* eslint-disable @typescript-eslint/no-inferrable-types */
import { Component, OnDestroy, OnInit, ViewChild, NgZone, ElementRef, HostListener } from "@angular/core";
import { Router } from "@angular/router";

import { CommunicationType } from "@src/app/shared/enums";
import { AppData } from "@src/app/app.data";
import { Subscription } from "rxjs";
import { IUserAccount, CommunicationMessageModel } from "@src/app/shared/models";
import { JitsiService, NotifyService, CommunicationService, HttpService, AppointmentRefreshService, RedirectAppointmentService } from "@src/app/shared/services";    

import { ApiResources } from "@src/app/shared/helpers/api.helper";
import { HttpErrorResponse } from "@angular/common/http";

// ReSharper disable UnusedParameter
// ReSharper disable InconsistentNaming
declare const JitsiMeetExternalAPI: any;

class PushNotificationOptions {
    body: string;
    icon: string;
    sound: string;
    data: any;
    tag: string;
    dir: NotificationDirection;
    lang: string;
    renotify: boolean;
    sticky: boolean;
    vibrate: Array<number>;
    noscreen: boolean;
    silent: boolean;
}

//class TelemedicineTemplateModel {
//    id: number;
//    name: string;
//}

class BasicAppointmentDetails {
    encryptedAppointmentId: string;
    encounterType: string;

    patientFullName: string;
    providerFullName: string;
    patientFirstName: string;
    providerFirstName: string;
    patientShortName: string;
}

class TelemedicineUsers {
    accountId: number;
    fullName: boolean;
    roleId: number;
    isNotifying: boolean;
}

class PatientsImageUrl {
    roomNumber: string;
    patientThumbnailUrl: string;
}

class ActiveParticipant {
    id: string;
    name: string;
}

enum InfoMessageType {
    Connecting = 1,
    Template = 2,
    Others = 3
}

enum InfoMessageLiveType {
    Temp = 1,
    Permanent = 2
}

class InfoMessage {
    message: string;
    type: InfoMessageType;
    liveType: InfoMessageLiveType;
}

class JitsiRoom {
    roomNumber: number;
    accountId: number;
    isShow: boolean;
    roomName: string;
    parentNode: string;
    jitsiApi: any;
    isNotifyingDoctor: boolean;
    isNotifyingPatient: boolean;
    isRinging: boolean;
    current: CommunicationMessageModel;
    telemedicineUsers: Array<TelemedicineUsers>;
    isUsersLoading: boolean;
    showUsersContainer: boolean;
    appointmentDetails: BasicAppointmentDetails;
    activeParticipants: Array<ActiveParticipant>;
    interval: any;
    telemedicineId: number;
    position: number;
    isAudioMuted: boolean;
    isVideoMuted: boolean;
    isShowPatientBtn: boolean;
    patientThumbnailUrl: string;
    isWaiting: boolean;
    isPatientJoined: boolean;
    startAudioOnly: boolean;
    isTitleView: boolean;
    isFirstInterval: any;
    infoMessages: Array<InfoMessage>;
}

@Component({
    selector: "telemedicine-widget",
    styleUrls: ["./sinch-telemedicine.css", "./telemedicine.css"],
    templateUrl: "./telemedicine.html"
})
export class TelemedicineWidget implements OnDestroy, OnInit {

    @ViewChild("jitsiMeetContainer0", { static: false }) _jitsiMeetContainer0: any;
    @ViewChild("jitsiMeetContainer1", { static: false }) _jitsiMeetContainer1: any;
    @ViewChild("jitsiMeetContainer2", { static: false }) _jitsiMeetContainer2: any;
    @ViewChild("jitsiMeetContainer3", { static: false }) _jitsiMeetContainer3: any;

    audio: HTMLAudioElement;
    @ViewChild('audioContainer', { static: false }) set playerRef(ref: ElementRef<HTMLAudioElement>) {
        this.audio = ref.nativeElement;
    }

    callAudio: HTMLAudioElement;
    @ViewChild('callAudioContainer', { static: false }) set callPlayerRef(ref: ElementRef<HTMLAudioElement>) {
        this.callAudio = ref.nativeElement;
    }

    infoMessageType = InfoMessageType;

    appDataSubscription: Subscription;
    logs: Array<string>;
    jitsiSubscription: Subscription;
    jitsiCloseSubscription: Subscription;
    appointmentRefreshSubscription: Subscription;
    joinCallSubscription: Subscription;
    endCallSubscription: Subscription;
    patientDevices: Array<{}>;

    userAccount: IUserAccount;

    isPermissionGranted: boolean;

    /*Mobile Related*/
    patientsImageUrl: Array<PatientsImageUrl>;
    appointmentNumbers: Array<string>;
    showContainer: boolean;

    /* Communication */
    communicationSubscription: Subscription;
    activeRooms: Array<JitsiRoom>;
    activeEncounterRoom: number;
    isWindowBlur: boolean;
    callTimeout: any;
    frequentTimeoutCount = 3;
    frequentTimeoutCounter = 0;
    frequentTimeout: any;
    titleViewTimeout: any;
    totalParticipants: number = 1;

    // telemedicineTemplates: Array<TelemedicineTemplateModel>;
    // @ViewChild('templateTelemedicineTemplate', { static: false }) templateTelemedicineTemplate: TemplateRef<any>;
    // modalRef: NgbModalRef;
    // roomTemplateIndex: number;
    // templateForm: FormGroup;

    constructor(
        private readonly appData: AppData,
        private readonly jitsiService: JitsiService,
        private readonly notification: NotifyService,
        private readonly ngZone: NgZone,
        private readonly httpService: HttpService,
        private readonly communicationService: CommunicationService,
        private readonly appointmentRefreshService: AppointmentRefreshService,
        private readonly redirectAppointmentService: RedirectAppointmentService,
        private readonly router: Router,
        // private readonly modalService: NgbModal,
        // private readonly formBuilder: FormBuilder,
    ) {
        // this.telemedicineTemplates = new Array<TelemedicineTemplateModel>();
        // this.buildTemplateForm();

        this.activeEncounterRoom = -1;
        this.activeRooms = new Array<JitsiRoom>();

        // ROOMS
        for (let i = 0; i <= 3; i++) {
            const room = new JitsiRoom();
            room.isShow = false;
            room.telemedicineId = 0;
            room.infoMessages = new Array<InfoMessage>();
            room.parentNode = "#jitsi-meet-" + i;
            room.activeParticipants = new Array<ActiveParticipant>();
            this.activeRooms.push(room);
        }

        this.logs = new Array<string>();
        this.patientDevices = new Array<{}>();

        this.appointmentNumbers = new Array<string>();
        this.patientsImageUrl = new Array<PatientsImageUrl>();
    }

    //openModal() {
    //    this.modalRef = this.modalService.open(this.templateTelemedicineTemplate, {
    //        backdrop: "static",
    //        keyboard: false,
    //        centered: true,
    //        windowClass: "custom-modal invoice-modal effect-scale"
    //    });
    //}

    //closeModal() {
    //    try {
    //        this.modalRef.close();
    //        this.modalRef = undefined;
    //    } catch (e) {
    //        // ignored;
    //    }
    //}

    //private buildTemplateForm() {
    //    this.templateForm = this.formBuilder.group({
    //        id: [null, [Validators.required]]
    //    });
    //}

    get isRoomActiveAlt() {
        var index = -1;
        for (let i = 0; i < this.activeRooms.length; i++) {
            if (this.activeRooms[i].roomName) {
                index = i;
                break;
            }
        }

        return index !== -1;
    }

    handleRedirect() {
        var index = -1;
        for (let i = 0; i < this.activeRooms.length; i++) {
            if (this.activeRooms[i].roomName) {
                index = i;
                break;
            }
        }

        if (index !== -1) {
            const count = this.activeRooms[index].jitsiApi.getNumberOfParticipants();
            if (count > 1) {
                this.notification.info("There is a active video/audio call is going on, please end it and try again.");
                return;
            } else {
                const notifyModel = {
                    type: CommunicationType.CloseAll,
                    accountId: this.userAccount.accountId,
                    content: this.activeRooms[index].roomName,
                    message: this.userAccount.fullName,
                    telemedicineId: this.activeRooms[index].telemedicineId,
                };
                this.sendNotificationAlt(notifyModel, -1, true);
                this.closeRoom(index, false, true);
                clearTimeout(this.callTimeout);
                clearInterval(this.frequentTimeout);
                //this.router.navigate(["/app/out-patients"]);
            }
        }

        //this.router.navigate(["/app/out-patients"]);
    }

    ngOnInit() { 

        Notification.requestPermission().then((permission: any) => {
            if ("granted" === permission) {
                this.isPermissionGranted = true;
            } else {
                Notification.requestPermission();
            }
        });

        this.appDataSubscription = this.appData.userAccount.subscribe((userAccount: IUserAccount) => {
            if (userAccount) {
                this.userAccount = userAccount;
                this.getAppointments();
            }
        });

        this.appointmentRefreshSubscription = this.appointmentRefreshService.jitsiAccount.subscribe((isRefreshed: boolean) => {
            if (isRefreshed) {
                this.getAppointments();
            }
        });

        this.jitsiCloseSubscription = this.jitsiService.closeAccount.subscribe((is: boolean) => {
            if (is) {
                this.handleRedirect();
            }
        });

        this.jitsiSubscription = this.jitsiService.jitsiAccount.subscribe((account: {}) => {
            if (!account) return;

            if (account["type"] === 2) return;

            if (!this.patientsImageUrl.find(x => x.roomNumber === account["appointmentNo"])) {
                this.patientsImageUrl.push({
                    roomNumber: account["appointmentNo"],
                    patientThumbnailUrl: account["patientThumbnailUrl"]
                });
            }

            if (account["type"] !== 3) {
                this.startJitsi(account["appointmentNo"], account["accountId"], false, account["isPreLoad"] || false, account["startAudioOnly"]);
            } else {
                // Initiate Call
                const notifyModel = {
                    type: CommunicationType.Initiate,
                    accountId: this.userAccount.accountId,
                    content: account["appointmentNo"],
                    message: this.userAccount.fullName,
                    telemedicineId: 0,
                    startAudioOnly: false
                };
                this.sendNotificationAlt(notifyModel, -1, true);
            }

        });

        this.joinCallSubscription = this.jitsiService.callAccount.subscribe((model: {}) => {
            if (!model || !model["roomName"]) return;

            const index = this.activeRooms.findIndex(x => x.roomName === model["roomName"]);
            if (index !== -1) {
                this.toggleVideo(index);
            } else {
                this.startJitsi(model["roomName"], this.userAccount.accountId, true, false, model["startAudioOnly"]);
            }
        });

        this.endCallSubscription = this.jitsiService.endAccount.subscribe((roomNumber: string) => {
            const index = this.activeRooms.findIndex(x => x.roomName === roomNumber);
            // End Call
            const notifyModel = {
                type: CommunicationType.CloseAll,
                accountId: this.userAccount.accountId,
                content: roomNumber,
                message: this.userAccount.fullName,
                telemedicineId: 0
            };
            this.sendNotificationAlt(notifyModel, -1, true);
            if (index !== -1) {
                this.closeRoom(index, false);
            }
        });

        // this.sendNotificationTest();
        // this.subscribeToCommunication();

        let myInterval = setInterval(() => {
            if (this.communicationService && this.communicationService.hubConnection && this.communicationService.hubConnection.state && this.communicationService.hubConnection.state === 'Connected') {
                this.communicationService.hubConnection.on("Communication", (data: any) => {
                    let communication = data as CommunicationMessageModel;
                    this.communicationHelper(communication);
                    clearInterval(myInterval);
                });
            }
        }, 100);



        this.jitsiService.isRoomActive = this.isRoomActiveAlt;

        // this.getTelemedicineTemplates();
    }

    ngOnDestroy(): void {
        this.activeRooms.forEach((x) => {
            if (x.jitsiApi) {
                x.jitsiApi.dispose();
            }
        });

        this.jitsiService.call(null);
        this.redirectAppointmentService.set(null);
        this.appDataSubscription.unsubscribe();
        this.jitsiSubscription.unsubscribe();
        this.jitsiCloseSubscription.unsubscribe();
        this.appointmentRefreshSubscription.unsubscribe();
        this.endCallSubscription.unsubscribe();
        this.joinCallSubscription.unsubscribe();
        this.activeRooms = new Array<JitsiRoom>();

        this.jitsiService.isRoomActive = this.isRoomActiveAlt;
    }

    jitsiHelper(roomName: string, accountId: number = 0, isJoining: boolean = false, isPreLoad: boolean = false, startAudioOnly: boolean = false) {
        const isRoomExists = this.activeRooms.findIndex(x => x.roomName === roomName);
        if (isRoomExists !== -1) {
            this.toggleVideo(isRoomExists);
            return;
        }

        var index = -1;
        for (let i = 0; i < this.activeRooms.length; i++) {
            if (!this.activeRooms[i].roomName) {
                index = i;
                break;
            }
        }

        if (index === -1) {
            // index = 0;
            // this.notification.info("All rooms are filled");
            return;
        }

        this.activeRooms[index].position = 2;
        this.activeRooms[index].roomName = roomName;
        this.activeRooms[index].isPatientJoined = false;
        this.activeRooms[index].startAudioOnly = startAudioOnly;

        //this.activeRooms.forEach(room => {
        //    room.isShow = false;
        //});

        for (let i = 0; i < this.activeRooms.length; i++) {
            if (i !== index) {
                this.activeRooms[i].isShow = false;

                if (this.activeRooms[i].jitsiApi) {
                    this.muteAudioAndVideo(i);
                }
            }
        }

        if (!isPreLoad) {
            this.activeRooms[index].isShow = true;
        } else {
            this.activeRooms[index].isShow = false;
        }
        this.showContainer = true;

        let model = { roomName: roomName, accountId: this.userAccount.accountId };
        this.httpService.post<{}>(ApiResources.getURI(ApiResources.webTelemedicine.base, ApiResources.webTelemedicine.getAuthInfo),
            model, true)
            .subscribe(
                (response: {}) => {
                    const options = {
                        roomName: response["roomName"],
                        height: 400,
                        parentNode: document.querySelector(this.activeRooms[index].parentNode),
                        openBridgeChannel: true,
                        configOverwrite: {
                            startAudioOnly: startAudioOnly,
                            openBridgeChannel: true,
                            SHOW_JITSI_WATERMARK: false,
                            VIDEO_LAYOUT_FIT: "both",
                            enableLocalRecording: true,
                            //fileRecordingsEnabled: false,
                            localRecording: {
                                // Whether to disable local recording or not.
                                disable: false,

                                // Whether to notify all participants when a participant is recording locally.
                                notifyAllParticipants: true,

                                // Whether to disable the self recording feature (only local participant streams).
                                disableSelfRecording: true,
                            },
                            TOOLBAR_BUTTONS: [
                                "microphone", "camera", "desktop", "fullscreen", "hangup",
                                "chat", "filmstrip", "settings", "recording", "tileview"
                            ],
                            MAIN_TOOLBAR_BUTTONS: ["microphone", "camera", "desktop", "tileview", "recording"],
                        },
                        interfaceConfigOverwrite: {
                            SHOW_JITSI_WATERMARK: false,
                            SHOW_WATERMARK_FOR_GUESTS: false,
                            enableLocalRecording: true,
                            //fileRecordingsEnabled: false,
                            //recording_directory: "D:\publish\recordings",
                            DEFAULT_REMOTE_DISPLAY_NAME: 'Participant',
                            TOOLBAR_BUTTONS: [
                                "microphone", "camera", "desktop", "fullscreen",
                                "recording", "tileview"
                            ],
                            MAIN_TOOLBAR_BUTTONS: ["microphone", "camera", "desktop", "tileview", "recording"],
                        },
                        userInfo: {
                            displayName: this.userAccount.fullName
                        },

                        noSsl: false,
                        //onload: (_event: any) => {
                        //    const count = this.activeRooms[index].jitsiApi.getNumberOfParticipants();
                        //    alert("onload Event Count: " + count);
                        //}
                    }

                    if (response["isAuthenticationRequired"]) {
                        options["jwt"] = response["token"];
                    }

                    try {
                        this.activeRooms[index].jitsiApi = new JitsiMeetExternalAPI(response["domain"], options);
                    } catch (ex) {
                        this.showContainer = false;
                        this.activeRooms.forEach(room => {
                            room.isShow = false;
                        });
                        return;
                    }
                    this.registerEvents(index);
                    this.activeRooms[index].accountId = accountId;
                    this.activeRooms[index].showUsersContainer = false;
                    this.activeEncounterRoom = index;

                    this.activeRooms[index].jitsiApi.isAudioMuted().then((_muted: any) => {
                        if (this.activeRooms[index].jitsiApi) {
                            this.activeRooms[index].isFirstInterval = setInterval(() => {
                                if (this.activeRooms[index].jitsiApi._numberOfParticipants > 0) {
                                    clearInterval(this.activeRooms[index].isFirstInterval);

                                    // The code
                                    const count = this.activeRooms[index].jitsiApi.getNumberOfParticipants();
                                    if (!isJoining) {
                                        const patientImage = this.patientsImageUrl.find(x => x.roomNumber === roomName);
                                        if (patientImage) {
                                            this.activeRooms[index].patientThumbnailUrl = patientImage.patientThumbnailUrl;
                                        }
                                        if (count === 1) {
                                            this.activeRooms[index].isShowPatientBtn = true;
                                            if (!isPreLoad) {
                                                this.intimatePatient(index);
                                            }
                                        }
                                    }

                                    if (count > 1) {
                                        this.activeRooms[index].isPatientJoined = true;
                                    }
                                }
                            }, 1000);
                        }

                    });

                    setTimeout(() => {
                        this.getAppointmentBasicDetails(index, isPreLoad);
                        if (isJoining) {
                            const notifyModel = {
                                type: CommunicationType.Join,
                                accountId: this.userAccount.accountId,
                                content: roomName,
                                message: this.userAccount.fullName,

                                telemedicineId: 0
                            };
                            this.sendNotificationAlt(notifyModel, index, true);
                        }
                    });

                    this.jitsiService.isRoomActive = this.isRoomActiveAlt;

                },
                (_error: HttpErrorResponse) => {
                    this.closeRoom(index);
                    this.notification.info("Authentication failed, please contact administrator");
                }
            );
    }

    /* Jitsi */
    startJitsi(roomName: string, accountId: number = 0, isJoining: boolean = false, isPreLoad: boolean = false, startAudioOnly: boolean = false) {
        this.jitsiHelper(roomName, accountId, isJoining, isPreLoad, startAudioOnly);
    }

    registerEvents(index: number) {
        this.activeRooms[index].jitsiApi.on(
            "participantJoined", (response: any) => {
                this.activeRooms[index].infoMessages = new Array<InfoMessage>();

                const activeParticipant = new ActiveParticipant();
                activeParticipant.id = response["id"];
                activeParticipant.name = response["displayName"];
                this.activeRooms[index].activeParticipants.push(activeParticipant);

                const count = this.activeRooms[index].jitsiApi.getNumberOfParticipants();
                if (count > 1) {
                    this.activeRooms[index].isPatientJoined = true;
                }

                clearTimeout(this.titleViewTimeout);
                this.titleViewTimeout = setTimeout(() => {
                    if (!this.activeRooms[index].isTitleView) {
                        try {
                            this.activeRooms[index].jitsiApi.executeCommand('toggleTileView');
                        } catch (_e) {
                            // ignore
                        }
                    }
                }, 2000);
            }
        );

        this.activeRooms[index].jitsiApi.on(
            "participantLeft", (response: any) => {
                clearTimeout(this.titleViewTimeout);
                this.titleViewTimeout = setTimeout(() => {
                    if (!this.activeRooms[index].isTitleView) {
                        this.activeRooms[index].jitsiApi.executeCommand('toggleTileView');
                    }
                }, 2000);

                //if (this.activeRooms[index].isShow) {
                //    const count = this.activeRooms[index].jitsiApi.getNumberOfParticipants();
                //    if (count === 1) {
                //        const leftUser = this.activeRooms[index].activeParticipants.find(x => x.id === response["id"]);
                //        this.notification.advancedConfirm(
                //            (leftUser ? leftUser.name : 'unknown') + " left the call, do you want to end the call?",
                //            "End Call",
                //            () => {
                //                this.closeRoomAll(index);
                //            });
                //    }
                //}

                this.activeRooms[index].activeParticipants = this.activeRooms[index].activeParticipants.filter(id => id !== response["id"]);
            },
        );

        this.activeRooms[index].jitsiApi.on(
            "tileViewChanged", (response: any) => {
                this.activeRooms[index].isTitleView = response["enabled"];
            },
        );
    }

    //1	"Super Admin"	true
    //3	"Provider"	true
    //4	"Patient"	true
    //2	"Administrator"	true
    //6	"Nurse"	true
    //7	"Receptionsit"	true
    //5	"Support"	true

    sendMessage(message: string, communicationType: CommunicationType, content: any, roleId: number, accountId: number = 0): void {
        const communication = new CommunicationMessageModel();
        communication.uniqueId = this.communicationService.uniqueId;
        communication.message = message;
        communication.type = communicationType;
        communication.content = content;
        communication.roleId = roleId;
        communication.accountId = accountId;
        this.communicationService.sendMessage(communication);
    }

    subscribeToCommunication(): void {
        this.communicationSubscription = this.communicationService.messageReceived.subscribe((communication: CommunicationMessageModel) => {
            this.ngZone.run(() => {
                this.communicationHelper(communication);
            });
        });
    }

    closeCover(i: number) {
        this.callAudio.pause();
        clearTimeout(this.callTimeout);
        clearInterval(this.frequentTimeout);
        this.activeRooms[i].isShowPatientBtn = false;
        this.activeRooms[i].isNotifyingPatient = false;
        this.activeRooms[i].showUsersContainer = false;
        this.activeRooms[i].isWaiting = false;
    }

    ringer(is: boolean, index: number) {
        const ringerBox = document.getElementById("ringerBox_" + index);
        ringerBox.classList.remove(is ? "ringer-hide" : "ringer-show");
        ringerBox.classList.add(is ? "ringer-show" : "ringer-hide");
    }

    communicationHelper(communication: CommunicationMessageModel) {
        if (communication.uniqueId !== this.communicationService.uniqueId) {
            if (communication.type === 2) {
                if ((this.appointmentNumbers.indexOf(communication.content.toString()) !== -1 &&
                    this.userAccount.roleId === communication.roleId) || this.userAccount.accountId === communication.notifyToId) {

                    const mainIndex = this.activeRooms.findIndex(x => x.roomName === communication.content.toString());

                    if (mainIndex === -1) {
                        const index = this.activeRooms.findIndex(x => x.isShow === false);

                        //if ((communication.roleId === 6 || communication.roleId === 7) &&
                        //    this.userAccount.accountId !== communication.accountId) {
                        //    return;
                        //}

                        if (index === -1) {
                            //this.notification.info("You are getting a call, but you don't have a free room to join");
                            return;
                        } else {
                            this.audio.play();
                            this.activeRooms[index].current = communication;
                            this.activeRooms[index].isRinging = true;
                            this.ringer(true, index);
                            setTimeout(() => {
                                this.onSelfCancel(index);
                            }, 30 * 1000);
                            // this.activeRooms[index].roomName = communication.content.toString();

                            let roleName = communication.roleId === 4
                                ? 'Patient'
                                : communication.roleId === 6
                                    ? 'Nurse'
                                    : 'Receptionist';

                            this.sendDesktopNotification("Call from " + roleName, "Telemedicine Call");
                        }
                    }
                }
            } else if (communication.type === 7 || communication.type === 8) {
                if (this.appointmentNumbers.indexOf(communication.content.toString()) !== -1) {
                    this.appointmentRefreshService.refreshAppointment(true);
                }
                //setTimeout(() => {
                //    this.appointmentRefreshService.conditionalRefreshAppointment(communication);
                //}, 500);
            }
            //else if (communication.type === 11) {
            //    // Initiates
            //    if (this.appointmentNumbers.indexOf(communication.content.toString()) !== -1) {
            //        setTimeout(() => {
            //            this.appointmentRefreshService.refreshAppointment(true);
            //        });
            //    }
            //}
            else {
                if (!communication.content) return;
                if (this.appointmentNumbers.indexOf(communication.content.toString()) === -1) return;

                const index = this.activeRooms.findIndex(x => x.roomName === communication.content.toString());
                if (index !== -1) {
                    if (communication.type === 3) {
                        // Call Rejected
                        //let roleName = communication.roleId === 3
                        //    ? 'Doctor' : communication.roleId === 4
                        //        ? 'Patient'
                        //        : communication.roleId === 6
                        //            ? 'Nurse'
                        //            : 'Receptionist';

                        if (communication.roleId === 4) {
                            this.activeRooms[index].isWaiting = false;
                            this.stopIntimatingPatient(index);
                            clearTimeout(this.callTimeout);
                            clearInterval(this.frequentTimeout);
                        }

                        //let notifyMessage = `${roleName} (${communication.message}) rejected your call`;
                        let notifyMessage = `${communication.message} rejected your call`;
                        this.notification.hideAll();
                        this.notification.info(notifyMessage);
                        if (this.isPermissionGranted) {
                            this.sendDesktopNotification(notifyMessage, "Telemedicine Call Rejected", true);
                        }
                    } else if (communication.type === 10) {
                        // Call Cancelled
                        this.audio.pause();
                        this.activeRooms[index].isRinging = false;
                        this.ringer(false, index);
                        try {
                            // Close All
                            clearTimeout(this.callTimeout);
                            clearInterval(this.frequentTimeout);
                            this.onSelfCancel(index);
                            this.closeRoom(index, false);
                        } catch (e) {
                            console.error(e);
                        }
                    } else if (communication.type === 4) {
                        // Close All
                        clearTimeout(this.callTimeout);
                        clearInterval(this.frequentTimeout);
                        this.onSelfCancel(index);
                        this.closeRoom(index, false);

                        this.appointmentRefreshService.refreshAppointment(true);
                    } else if (communication.type === 5) {
                        // Someone joining

                        this.callAudio.pause();
                        clearTimeout(this.callTimeout);
                        clearInterval(this.frequentTimeout);
                        this.activeRooms[index].isShowPatientBtn = false;
                        this.activeRooms[index].isNotifyingPatient = false;
                        this.activeRooms[index].showUsersContainer = false;
                        this.activeRooms[index].isWaiting = false;
                        this.activeRooms[index].infoMessages = new Array<InfoMessage>();

                        let join = new InfoMessage();
                        join.message = communication.message;
                        join.type = InfoMessageType.Connecting;
                        join.liveType = InfoMessageLiveType.Permanent;
                        //let isExists = this.activeRooms[index].infoMessages.find(x =>
                        //    x.type === InfoMessageType.Connecting &&
                        //    x.message === communication.message
                        //);
                        //if (!isExists) {
                        //    this.activeRooms[index].infoMessages.unshift(join);
                        //}

                        setTimeout(() => {
                            this.appointmentRefreshService.refreshAppointment(true);
                        },
                            1000);
                    } else if (communication.type === 6) {
                        // Someone left the call
                        //const count = this.activeRooms[index].jitsiApi.getNumberOfParticipants();
                        //if (count === 1) {
                        //    this.notification.hideAll();
                        //    this.notification.advancedConfirm(
                        //        communication.message + " left the call, do you want to end the call?",
                        //        "End Call",
                        //        () => {
                        //            this.closeRoomAll(index);
                        //        });
                        //}

                        if (communication.templateName) {
                            let template = new InfoMessage();
                            template.message = communication.templateName;
                            template.type = InfoMessageType.Template;
                            template.liveType = InfoMessageLiveType.Permanent;
                            this.activeRooms[index].infoMessages.unshift(template);
                        }

                        this.appointmentRefreshService.refreshAppointment(true);
                    }
                }
            }
        }
    }

    closeRoom(index: number, _isRefresh: boolean = true, redirectToAppointments: boolean = false) {
        this.ngZone.run(() => {

            setTimeout(() => {

                while (this["_jitsiMeetContainer" + index].nativeElement.hasChildNodes()) {
                    this["_jitsiMeetContainer" + index].nativeElement.removeChild(this["_jitsiMeetContainer" + index].nativeElement.lastChild);
                }

                this.activeRooms[index].jitsiApi.dispose();
                this.activeRooms[index].jitsiApi = null;

            }, 1000);

            try {
                this.activeRooms[index].jitsiApi.executeCommand("hangup");
            } catch (ex) {
                console.error(ex);
            }

            this.callAudio.pause();
            clearTimeout(this.callTimeout);
            clearInterval(this.frequentTimeout);
            this.activeRooms[index].roomName = null;
            this.activeRooms[index].isShow = false;
            this.activeRooms[index].telemedicineId = 0;
            this.activeRooms[index].isShowPatientBtn = false;
            this.activeRooms[index].infoMessages = new Array<InfoMessage>();

            this.redirectAppointmentService.set(null);
            const activeRooms = this.activeRooms.filter(x => x.isShow);
            if (activeRooms.length <= 0) {
                this.showContainer = false;
            }

            //if (isRefresh) {
            //    this.appointmentRefreshService.refreshAppointment(true);
            //}

            this.appointmentRefreshService.refreshAppointment(true);

            if (redirectToAppointments) {
                this.router.navigate(["/app/out-patients"]);
            }

            this.jitsiService.isRoomActive = this.isRoomActiveAlt;

        });
    }

    //onTemplateSelect = () => {
    //    if (this.templateForm.invalid) return;

    //    this.closeModal();
    //    const notifyModel = {
    //        type: CommunicationType.Close,
    //        accountId: this.userAccount.accountId,
    //        content: this.activeRooms[this.roomTemplateIndex].roomName,
    //        message: this.userAccount.fullName,
    //        telemedicineId: this.activeRooms[this.roomTemplateIndex].telemedicineId,
    //        roleId: this.userAccount.roleId,
    //        templateId: +this.templateForm.value.id
    //    };
    //    this.sendNotificationAlt(notifyModel, -1, true);
    //    this.closeRoom(this.roomTemplateIndex, false);
    //}

    // Notification Pending
    closeRoomAll(index: number) {

        let singleUserHandler = () => {
            this.stopIntimatingPatient(index);
            clearTimeout(this.callTimeout);
            clearInterval(this.frequentTimeout);

            const notifyModel = {
                type: CommunicationType.CloseAll,
                accountId: this.userAccount.accountId,
                content: this.activeRooms[index].roomName,
                message: this.userAccount.fullName,

                telemedicineId: this.activeRooms[index].telemedicineId,
            };
            this.sendNotificationAlt(notifyModel, -1, true);
            this.closeRoom(index, false);
        }

        let multipleUserHandler = () => {
            this.notification.endCallConfirm(
                "Are you sure, you want to end the call? this will end the call for all the participants",
                () => {
                    singleUserHandler();
                    if (this.activeRooms[index].isNotifyingPatient) {
                        this.cancelIntimatePatient(index);
                    }
                }, () => {
                    // Leave Call
                    // this.openModal();
                    const notifyModel = {
                        type: CommunicationType.Close,
                        accountId: this.userAccount.accountId,
                        content: this.activeRooms[index].roomName,
                        message: this.userAccount.fullName,
                        telemedicineId: this.activeRooms[index].telemedicineId,
                        roleId: this.userAccount.roleId
                        // templateId: +this.templateForm.value.id
                    };
                    this.sendNotificationAlt(notifyModel, -1, true);
                    this.closeRoom(index, false);
                });
        }

        let oneToOneHandler = () => {
            this.notification.advancedConfirm(
                "Are you sure, you want to end the call?",
                "End Call",
                () => {
                    singleUserHandler();

                    if (this.activeRooms[index].isNotifyingPatient) {
                        this.cancelIntimatePatient(index);
                    }
                });
        }

        const count = this.activeRooms[index].jitsiApi.getNumberOfParticipants();
        if (count > 1) {
            multipleUserHandler();
        } else if (this.activeRooms[index].activeParticipants.length <= 0 && (!this.activeRooms[index].isNotifyingPatient)) {
            singleUserHandler();

            if (this.activeRooms[index].isNotifyingPatient) {
                this.cancelIntimatePatient(index);
            }
        } else {
            oneToOneHandler();
        }
        //if (count === 2) {
        //    // One to One call
        //    oneToOneHandler();
        //} else if (count > 2) {
        //    // More than 2 are are found
        //    multipleUserHandler();
        //} else if (count === 1) {
        //    // Single person
        //    singleUserHandler();
        //} else {
        //    this.closeRoom(index, false);
        //}

        //this.notification.advancedConfirm(
        //    "Are you sure, you want to end the call? this will end the call for all the participants",
        //    "End Call",
        //    () => {
        //        this.stopIntimatingPatient(index);
        //        clearTimeout(this.callTimeout);
        //        clearInterval(this.frequentTimeout);
        //        const count = this.activeRooms[index].jitsiApi.getNumberOfParticipants();
        //        if (this.activeRooms[index].isPatientJoined || count === 1) {
        //            const notifyModel = {
        //                type: CommunicationType.CloseAll,
        //                accountId: this.userAccount.accountId,
        //                content: this.activeRooms[index].roomName,
        //                message: this.userAccount.fullName,

        //                telemedicineId: this.activeRooms[index].telemedicineId,
        //            };
        //            this.sendNotificationAlt(notifyModel, -1, true);
        //        } else {
        //            if (this.activeRooms[index].isNotifyingPatient) {
        //                this.cancelIntimatePatient(index);
        //            }
        //            else {
        //                if (this.activeRooms[index].isPatientJoined) {
        //                    const notifyModel = {
        //                        type: CommunicationType.Close,
        //                        accountId: this.userAccount.accountId,
        //                        content: this.activeRooms[index].roomName,
        //                        message: this.userAccount.fullName,
        //                        telemedicineId: this.activeRooms[index].telemedicineId,
        //                        roleId: this.userAccount.roleId,
        //                    };
        //                    this.sendNotificationAlt(notifyModel, -1, true);
        //                }
        //            }
        //        }

        //        this.closeRoom(index, false);
        //    });
    }
    callFrequently = (startTime: any, index: number, subIndex: number = -1) => {

        const currentTime = Date.now();
        const elapsedTime = currentTime - startTime;

        const count = this.activeRooms[index].jitsiApi.getNumberOfParticipants();
        this.totalParticipants = count;
        if (count > 1 || elapsedTime > 60000) {

            console.log("running " + count);
            this.stopIntimatingPatient(index, subIndex);
            this.activeRooms[index].isWaiting = true;

            setTimeout(() => {
                this.activeRooms[index].showUsersContainer = false;
            }, 1000);

            setTimeout(() => {
                this.appointmentRefreshService.refreshAppointment(true);
            }, 5000);

            return;
        }

        setTimeout(() => {
            this.callFrequently(startTime, index, subIndex);
        }, 300);


    }
    intimatePatient(index: number, subIndex: number = -1) {
        this.activeRooms[index].isWaiting = false;
        const count = this.activeRooms[index].jitsiApi.getNumberOfParticipants();
        if (count >= 0 && this.activeRooms[index].isShow) {
            this.activeRooms[index].isNotifyingPatient = true;
            if (subIndex !== -1) {
                this.activeRooms[index].telemedicineUsers[subIndex].isNotifying = true;
            }

            const notifyModel = {
                type: CommunicationType.Call,
                accountId: this.userAccount.accountId,
                content: this.activeRooms[index].roomName,
                message: this.userAccount.fullName,
                telemedicineId: this.activeRooms[index].telemedicineId,
                startAudioOnly: this.activeRooms[index].startAudioOnly
            };
            if (this.activeRooms[index].telemedicineId <= 0) {
                this.sendNotificationAlt(notifyModel, index, false, true);
            } else {
                this.sendNotificationAlt(notifyModel, -1, false, true);
            }

            this.callAudio.play();

            clearTimeout(this.callTimeout);
            this.callFrequently(Date.now(), index, subIndex);
            this.callTimeout = setTimeout(() => {
                this.stopIntimatingPatient(index, subIndex);
                this.activeRooms[index].isWaiting = true;
                setTimeout(() => {
                    this.activeRooms[index].showUsersContainer = false;
                }, 1000);

                setTimeout(() => {
                    this.appointmentRefreshService.refreshAppointment(true);
                }, 5000);
            }, 30000);
        
             //this.sendMessage(this.userAccount.fullName, CommunicationType.Call, this.activeRooms[index].roomName, this.communicationService.uniqueId, this.userAccount.roleId);
        }
    }

    stopIntimatingPatient = (index: number, subIndex: number = -1) => {
        this.activeRooms[index].isNotifyingPatient = false;
        if (subIndex !== -1) {
            this.activeRooms[index].telemedicineUsers[subIndex].isNotifying = false;
        }
        this.callAudio.pause();
    }

    cancelIntimatePatient(index: number) {

        this.activeRooms[index].isNotifyingPatient = false;
        this.activeRooms[index].isWaiting = true;
        this.callAudio.pause();
        clearTimeout(this.callTimeout);
        clearInterval(this.frequentTimeout);

        const notifyModel = {
            type: CommunicationType.Cancel,
            accountId: this.userAccount.accountId,
            content: this.activeRooms[index].roomName,
            message: this.userAccount.fullName,
            telemedicineId: this.activeRooms[index].telemedicineId
        };
        console.info(notifyModel);
        this.sendNotificationAlt(notifyModel);
    }

    intimateNurseOrReceptionist(index: number, subIndex: number) {
        const count = this.activeRooms[index].jitsiApi.getNumberOfParticipants();
        if (count >= 0 && this.activeRooms[index].isShow) {
            this.activeRooms[index].telemedicineUsers[subIndex].isNotifying = true;

            const notifyModel = {
                type: CommunicationType.Notify,
                accountId: this.userAccount.accountId,
                content: this.activeRooms[index].roomName,
                message: this.userAccount.fullName,

                roleId: this.activeRooms[index].telemedicineUsers[subIndex].roleId,
                notifyToId: this.activeRooms[index].telemedicineUsers[subIndex].accountId,

                telemedicineId: this.activeRooms[index].telemedicineId,
                startAudioOnly: this.activeRooms[index].startAudioOnly
            };
            if (this.activeRooms[index].telemedicineId <= 0) {
                this.sendNotificationAlt(notifyModel, index);
            } else {
                this.sendNotificationAlt(notifyModel);
            }

            this.callAudio.play();
            setTimeout(() => {
                this.activeRooms[index].telemedicineUsers[subIndex].isNotifying = false;
                this.callAudio.pause();
                setTimeout(() => {
                    this.activeRooms[index].showUsersContainer = false;
                }, 1000);
                setTimeout(() => {
                    this.appointmentRefreshService.refreshAppointment(true);
                }, 5000);
            }, 5000);
            // this.sendMessage(this.userAccount.fullName, CommunicationType.Notify, this.activeRooms[index].roomName, this.communicationService.uniqueId, this.activeRooms[index].telemedicineUsers[subIndex].roleId, this.activeRooms[index].telemedicineUsers[subIndex].accountId);
        }
    }

    intimateDoctor(index: number, subIndex: number = -1) {
        const count = this.activeRooms[index].jitsiApi.getNumberOfParticipants();
        if (count >= 0 && this.activeRooms[index].isShow) {
            this.activeRooms[index].isNotifyingDoctor = true;
            if (subIndex !== -1) {
                this.activeRooms[index].telemedicineUsers[subIndex].isNotifying = true;
            }
            const notifyModel = {
                type: CommunicationType.Notify,
                accountId: this.userAccount.accountId,
                content: this.activeRooms[index].roomName,
                message: this.userAccount.fullName,

                telemedicineId: this.activeRooms[index].telemedicineId,
                startAudioOnly: this.activeRooms[index].startAudioOnly,
                notifyToId: this.activeRooms[index].telemedicineUsers[subIndex].accountId,
                roleId: 3
            };

            if (this.activeRooms[index].telemedicineId <= 0) {
                this.sendNotificationAlt(notifyModel, index);
            } else {
                this.sendNotificationAlt(notifyModel);
            }

            this.callAudio.play();
            setTimeout(() => {
                this.activeRooms[index].isNotifyingDoctor = false;
                if (subIndex !== -1) {
                    this.activeRooms[index].telemedicineUsers[subIndex].isNotifying = false;
                }
                this.callAudio.pause();
                setTimeout(() => {
                    this.activeRooms[index].showUsersContainer = false;
                }, 1000);
                setTimeout(() => {
                    this.appointmentRefreshService.refreshAppointment(true);
                }, 5000);
            }, 5000);
            // this.sendMessage(this.userAccount.fullName, CommunicationType.Notify, this.activeRooms[index].roomName, this.communicationService.uniqueId, 3);
        }
    }

    onSelfCancel(index: number) {
        try {
            if (this.activeRooms[index].current) {
                this.activeRooms[index].isRinging = false;
                this.ringer(false, index);
                this.audio.pause();
            }
        } catch (e) {
            console.error(e);
        }
    }

    onAnswer(index: number) {
        if (this.activeRooms[index].current) {
            this.activeRooms[index].isRinging = false;
            this.ringer(false, index);
            this.audio.pause();

            const notifyModel = {
                type: CommunicationType.Join,
                accountId: this.userAccount.accountId,
                content: this.activeRooms[index].current.content.toString(),
                message: this.userAccount.fullName,

                telemedicineId: this.activeRooms[index].current.telemedicineId
            };

            this.sendNotificationAlt(notifyModel, -1, true);

            this.startJitsi(this.activeRooms[index].current.content.toString(), this.userAccount.accountId, true, false, this.activeRooms[index].current.startAudioOnly);
        }
    }

    onHangup(index: number) {
        if (this.activeRooms[index].current) {
            this.activeRooms[index].isRinging = false;
            this.ringer(false, index);
            this.audio.pause();

            const notifyModel = {
                type: CommunicationType.Reject,
                accountId: this.userAccount.accountId,
                content: this.activeRooms[index].current.content.toString(),
                message: this.userAccount.fullName,
                roleId: this.userAccount.roleId,
                telemedicineId: this.activeRooms[index].current.telemedicineId
            };
            this.sendNotificationAlt(notifyModel, -1, true);

            this.activeRooms[index].roomName = null;
            this.activeRooms[index].isShow = false;
            this.activeRooms[index].telemedicineId = 0;

            // this.sendMessage(this.userAccount.fullName, CommunicationType.Reject, this.activeRooms[index].current.content.toString(), this.activeRooms[index].current.uniqueId, this.userAccount.roleId);
        }
    }

    removeInfoMessage(index: number) {
        this.activeRooms[index].infoMessages.shift();
    }

    toggleVideoContainer() {
        this.showContainer = !this.showContainer;
    }

    togglePosition(index: number) {

        const iframe = document.querySelector("#jitsi-meet-" + index + " > iframe") as HTMLElement;
        if (this.activeRooms[index].position === 1) {
            // center full screen
            this.activeRooms[index].position = 2;
            iframe.style.width = "100%";
            iframe.style.height = "400px";
        } else {
            // corner small screen
            this.activeRooms[index].position = 1;
            iframe.style.width = "339px";
            iframe.style.height = "200px";
        }

        //setTimeout(() => {
        //    iframe.style.transform = "translate(0,0)";
        //}, 1000);
    }

    muteAudioAndVideo(index: number) {
        this.activeRooms[index].jitsiApi.isAudioMuted().then((muted: any) => {
            if (!muted) {
                this.activeRooms[index].jitsiApi.executeCommand('toggleAudio');
            } else {
                this.activeRooms[index].isAudioMuted = muted;
            }
        });

        this.activeRooms[index].jitsiApi.isVideoMuted().then((muted: any) => {
            if (!muted) {
                this.activeRooms[index].jitsiApi.executeCommand('toggleVideo');
            } else {
                this.activeRooms[index].isVideoMuted = muted;
            }
        });
    }

    toggleVideo(index) {
        for (let i = 0; i < this.activeRooms.length; i++) {
            if (i !== index) {
                this.activeRooms[i].isShow = false;

                if (this.activeRooms[i].jitsiApi) {
                    this.muteAudioAndVideo(i);
                }
            }
        }
        this.activeRooms[index].isShow = !this.activeRooms[index].isShow;

        if (this.activeRooms[index].jitsiApi) {
            this.activeRooms[index].jitsiApi.isAudioMuted().then((muted: any) => {
                if (this.activeRooms[index].isShow) {
                    if (muted && !this.activeRooms[index].isAudioMuted) {
                        this.activeRooms[index].jitsiApi.executeCommand('toggleAudio');
                    }
                } else {
                    this.activeRooms[index].isAudioMuted = muted;
                }
            });

            this.activeRooms[index].jitsiApi.isVideoMuted().then((muted: any) => {
                if (this.activeRooms[index].isShow) {
                    if (muted && !this.activeRooms[index].isVideoMuted) {
                        this.activeRooms[index].jitsiApi.executeCommand('toggleVideo');
                    }
                } else {
                    this.activeRooms[index].isVideoMuted = muted;
                }
            });
        }

        if (this.activeRooms[index].isShow) {
            this.activeEncounterRoom = index;
            // this.goToEncounterPage(index);
        } else {
            this.redirectAppointmentService.set(null);
        }
    }

    get isRoomActive() {
        return this.activeRooms.filter(x => x.isShow).length > 0;
    }

    isSingleRoomActive(index) {
        return this.activeRooms[index].roomName;
    }

    get isAnyRoomActive() {
        return (this.activeRooms.filter(x => x.roomName)).length > 0;
    }

    hideUsersContainer(index: number) {
        this.activeRooms[index].showUsersContainer = false;
    }

    getAppointments() {

        var request = {
            providerId: this.userAccount.referenceId
        }

        this.httpService
            .post<{}>(ApiResources.getURI(ApiResources.webTelemedicine.base, ApiResources.webTelemedicine.getAppointments), request, true)
            .subscribe(
                (response: Array<string>) => {
                    if (response && response.length > 0) {
                        this.appointmentNumbers = response;
                    }
                },
                (_error: HttpErrorResponse) => { }
            );
    }

    getAppointmentBasicDetails(index: number, isPreLoad: boolean = false) {
        const request = { appointmentNo: this.activeRooms[index].roomName };
        this.httpService
            .post<{}>(ApiResources.getURI(ApiResources.webTelemedicine.base, ApiResources.webTelemedicine.getAppointmentDetails), request, true)
            .subscribe(
                (response: BasicAppointmentDetails) => {
                    if (response) {
                        const record = response as BasicAppointmentDetails;
                        record.patientShortName = record.patientFirstName.length > 8
                            ? record.patientFirstName.substring(0, 6) + ".."
                            : record.patientFirstName;
                        this.activeRooms[index].appointmentDetails = record;
                        if (!isPreLoad) {
                            // this.goToEncounterPage(index);
                        }

                    }
                },
                (_error: HttpErrorResponse) => { }
            );
    }

    goToEncounterPage(index: number) {
        if (this.activeRooms[index].appointmentDetails) {
            if (this.activeRooms[index].appointmentDetails.encounterType) {
                // Go to encounter
                let encounterType = null;
                if (this.activeRooms[index].appointmentDetails.encounterType === "Encounter") {
                    encounterType = "encounter";
                } else if (this.activeRooms[index].appointmentDetails.encounterType === "InternalMedicine") {
                    encounterType = "internal-medicine";
                } else if (this.activeRooms[index].appointmentDetails.encounterType === "BehavioralHealth") {
                    encounterType = "behavioral-health";
                } else if (this.activeRooms[index].appointmentDetails.encounterType === "Homeopathy") {
                    encounterType = "homeopathy";
                } else if (this.activeRooms[index].appointmentDetails.encounterType === "DentalEncounter") {
                    encounterType = "dental-encounter";
                }

                let urlTokens = this.router.url.split('/');
                if (urlTokens.length >= 4 && urlTokens[2] === encounterType) {
                    let id = decodeURIComponent(urlTokens[3]);
                    if (id !== this.activeRooms[index].appointmentDetails.encryptedAppointmentId) {
                        this.goToEncounterHelper(index);
                    }
                } else {
                    this.goToEncounterHelper(index);
                }
            } else {
                // Go to appointment
                const urlTokens = this.router.url.split('/');
                if (urlTokens.length >= 3 && urlTokens[2] !== "appointments") {

                    const routerEid = decodeURIComponent(urlTokens[3]);
                    const eid = this.activeRooms[index].appointmentDetails.encryptedAppointmentId;

                    if (routerEid !== eid) {
                        //this.router.navigate(["/app/out-patients"]);
                        setTimeout(() => {
                            this.redirectAppointmentService.set(this.activeRooms[index].roomName);
                        }, 1000);
                    }
                } else {
                    this.redirectAppointmentService.set(this.activeRooms[index].roomName);
                }
            }
        } else {
            this.getAppointmentBasicDetails(index);
        }
    }

    goToEncounterHelper(index: number) {
        if (this.activeRooms[index].appointmentDetails.encounterType === "Encounter") {
            this.router.navigate(["/app/encounter", this.activeRooms[index].appointmentDetails.encryptedAppointmentId]);
        } else if (this.activeRooms[index].appointmentDetails.encounterType === "InternalMedicine") {
            this.router.navigate(["/app/internal-medicine", this.activeRooms[index].appointmentDetails.encryptedAppointmentId]);
        } else if (this.activeRooms[index].appointmentDetails.encounterType === "BehavioralHealth") {
            this.router.navigate(["/app/behavioral-health", this.activeRooms[index].appointmentDetails.encryptedAppointmentId]);
        } else if (this.activeRooms[index].appointmentDetails.encounterType === "Homeopathy") {
            this.router.navigate(["/app/homeopathy", this.activeRooms[index].appointmentDetails.encryptedAppointmentId]);
        } else if (this.activeRooms[index].appointmentDetails.encounterType === "DentalEncounter") {
            this.router.navigate(["/app/dental-encounter", this.activeRooms[index].appointmentDetails.encryptedAppointmentId]);
        }
    }

    getNurseOrReceptionists(index: number) {

        this.activeRooms[index].showUsersContainer = true;
        this.activeRooms[index].isUsersLoading = true;

        const model = {
            "appointmentNo": this.activeRooms[index].roomName
        }

        this.httpService
            .post<{}>(ApiResources.getURI(ApiResources.webTelemedicine.base, ApiResources.webTelemedicine.getNursesOrReceptionists), model, true)
            .subscribe(
                (response: Array<TelemedicineUsers>) => {
                    this.activeRooms[index].isUsersLoading = false;
                    if (response && response.length > 0) {

                        let records = response as Array<TelemedicineUsers>;
                        records.sort((a, b) => {
                            let comparison = 0;
                            if (a.roleId > b.roleId) {
                                comparison = 1;
                            } else if (a.roleId < b.roleId) {
                                comparison = -1;
                            }
                            return comparison;
                        });
                        this.activeRooms[index].telemedicineUsers = records;
                    }
                },
                (_error: HttpErrorResponse) => {
                    this.activeRooms[index].isUsersLoading = false;
                }
            );
    }

    sendNotificationAlt(model: {}, index: number = -1, isRefresh: boolean = false, isFrequent: boolean = false) {
        model["uniqueId"] = this.communicationService.uniqueId;
        model["clientName"] = navigator.userAgent || null;
        this.httpService.post<{}>(ApiResources.getURI(ApiResources.webTelemedicine.base, ApiResources.webTelemedicine.notification),
            model, true)
            .subscribe(
                (response: {}) => {
                    if (index !== -1 && +response["telemedicineId"]) {
                        this.activeRooms[index].telemedicineId = response["telemedicineId"];
                    }

                    if (isRefresh) {
                        this.appointmentRefreshService.refreshAppointment(true);
                    }

                    if (isFrequent) {
                        this.frequentTimeoutCounter = 0;
                        const frequentModel = JSON.parse(JSON.stringify(model));
                        this.frequentTimeout = setInterval(() => {
                            ++this.frequentTimeoutCounter;
                            if (this.frequentTimeoutCounter > this.frequentTimeoutCount) {
                                clearInterval(this.frequentTimeout);
                            } else {
                                this.sendFrequentNotification(frequentModel);
                            }
                        }, 8000);
                    }
                },
                (_error: HttpErrorResponse) => { }
            );
    }

    //getTelemedicineTemplates() {
    //    this.httpService.post<{}>(ApiResources.getURI(ApiResources.webTelemedicine.base, ApiResources.webTelemedicine.getTelemedicineTemplates),
    //        {}, true)
    //        .subscribe(
    //            (response: Array<TelemedicineTemplateModel>) => {
    //                this.telemedicineTemplates = response;
    //            },
    //            (error: HttpErrorResponse) => {
    //                console.error(error);
    //            }
    //        );
    //}

    sendFrequentNotification(model: {}) {
        this.httpService.post<{}>(ApiResources.getURI(ApiResources.webTelemedicine.base, ApiResources.webTelemedicine.iOsFrequentNotification),
            model, true)
            .subscribe(
                (_response: {}) => { },
                (_error: HttpErrorResponse) => { }
            );
    }

    sendDesktopNotification(title: string, body: string, isClose: boolean = false) {

        const options = new PushNotificationOptions();
        options.body = body;
        var notification = new Notification(title, options);

        if (isClose) {
            notification.onshow = () => {
                setTimeout(() => {
                    notification.close();
                }, 3000);
            }
        }

        notification.onclick = () => {
            window.focus();
            notification.close();
        }

        notification.onerror = (_error: any) => { }
    }

    @HostListener('window:beforeunload', ['$event'])
    onBeforeUnload(_event: any): void {
        //var message = 'Important: Please click on \'Save\' button to leave this page.';
        //if (typeof event == 'undefined') {
        //    event = window.event;
        //}
        //if (event) {
        //    event.returnValue = message;
        //}
        //return message;

        // Close room End or Leave based on 
        const model = this.activeRooms.filter(x => x.isShow);
        if (model.length > 0) {
            const count = this.activeRooms[0].jitsiApi.getNumberOfParticipants();
            if (count > 0) {
                let notifyModel = {
                    accountId: this.userAccount.accountId,
                    content: this.activeRooms[0].roomName,
                    message: this.userAccount.fullName,
                    telemedicineId: this.activeRooms[0].telemedicineId,
                };

                if (count > 2) {
                    // More than 2 are are found
                    notifyModel["type"] = CommunicationType.Close;
                    notifyModel["roleId"] = this.userAccount.roleId;
                } else {
                    // Single person
                    notifyModel["type"] = CommunicationType.CloseAll;
                }
                this.sendNotificationAlt(notifyModel, -1, true);
            }
        }


        //const model = this.activeRooms.filter(x => x.isShow);
        //if (model.length > 0) {
        //    var data = [];
        //    model.forEach((room: JitsiRoom) => {
        //        data.push({
        //            content: room.current ? room.current.content.toString() : room.roomName,
        //            telemedicineId: room.current ? room.current.telemedicineId : room.telemedicineId,
        //            callerId: this.userAccount.accountId
        //        });
        //    });
        //    this.httpService.post(ApiResources.getURI(ApiResources.webTelemedicine.base, ApiResources.webTelemedicine.closeRooms),
        //        data, true)
        //        .subscribe(() => { });
        //}
    }

    @HostListener('window:focus', ['$event'])
    onFocus(_event: any): void {
        if (this.isWindowBlur) {
            this.appointmentRefreshService.refreshAppointment(true);
            this.isWindowBlur = false;
        }
    }

    @HostListener('window:blur', ['$event'])
    onBlur(_event: any): void {
        this.isWindowBlur = true;
    }

}
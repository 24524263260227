<div class="card mb-0" *ngIf="loadingCounselling || loadingPatientInfo">
    <div class="card-body p-2">
        <div class="d-flex align-items-center">
            <span class="spinner-grow text-warning" role="status" aria-hidden="true"></span>
            <span class="ml-2">Please wait while loading {{loadingCounselling ? 'Counselling' : 'Patient'}} Details ...</span>
        </div>
    </div>
</div>
<div class="card mb-0" *ngIf="!loadingCounselling && !counselling && counsellingId">
    <div class="card-body p-2">
        <div class="mh-300 position-relative">
            <no-data [title]="'Counselling Details'"></no-data>
        </div>
    </div>
</div>
<div class="card mb-0" *ngIf="!loadingPatientInfo && !patientInfo && !counsellingId">
    <div class="card-body p-2">
        <div class="mh-300 position-relative">
            <no-data [title]="'Patient Details'"></no-data>
        </div>
    </div>
</div>
<form [formGroup]="counsellingForm" *ngIf="!loadingCounselling && !loadingPatientInfo && (counselling || patientInfo)">
    <div class="modify-counselling-patient badge font-weight-normal font-13 badge-info" *ngIf="patientInfo">
        <span class="text-uppercase" [textContent]="patientInfo.patientName"></span>
        <span *ngIf="patientInfo.patientGender" [textContent]="'(' + patientInfo.patientGender + ')'"></span>
        <span class="mx-1">|</span>
        <span [textContent]="patientInfo.umrNo"></span>
    </div>
    <div class="modify-counselling-patient badge font-weight-normal font-13 badge-info" *ngIf="counselling">
        <span class="text-uppercase" [textContent]="counselling.patientName"></span>
        <span *ngIf="counselling.patientGender" [textContent]="'(' + counselling.patientGender + ')'"></span>
        <span class="mx-1">|</span>
        <span [textContent]="counselling.umrNo"></span>
    </div>
    <div class="card mb-0">
        <div class="card-body p-2">
            <fieldset class="form-fieldset">
                <legend class="text-primary">Counselling Details</legend>
                <div class="row row-sm">
                    <div class="col-2 col">
                        <div class="form-group" [class.mb-0]="form.payType?.value !== 'I'">
                            <label class="font-13 font-weight-bold mb-0">Counselling Type</label>
                            <ng-select formControlName="counsellingType" class="font-13" [clearable]="false" [ngClass]="{ 'is-invalid': isSubmitted && form.counsellingType.errors }" placeholder="Select Counselling Type">
                                <ng-option [value]="'E'">Estimation</ng-option>
                                <ng-option [value]="'F'">Finance</ng-option>
                            </ng-select>
                        </div>
                    </div>
                    <div class="col-2 col">
                        <div class="form-group" [class.mb-0]="form.payType?.value !== 'I'">
                            <label class="font-13 font-weight-semibold mb-0">Pay Type</label>
                            <ng-select (change)="onChangePayType($event)" formControlName="payType" class="font-13" [clearable]="false" [ngClass]="{ 'is-invalid': isSubmitted && form.payType.errors }" placeholder="Select Pay Type">
                                <ng-option [value]="'C'">Cash</ng-option>
                                <ng-option [value]="'I'">Insurance</ng-option>
                            </ng-select>
                        </div>
                    </div>
                    <div class="col-4 col">
                        <div class="form-group" [class.mb-0]="form.payType?.value !== 'I'">
                            <label class="font-13 font-weight-bold mb-0">Location</label>
                            <ng-select [items]="locations" [loading]="loadingLocations" [clearable]="true" bindLabel="name" bindValue="id" placeholder="Select location" formControlName="locationId" (change)="onChangeLocation($event)">
                                <ng-template ng-option-tmp let-item="item">
                                    <span class="font-13" [textContent]="item.name"></span>
                                </ng-template>
                                <ng-template ng-option-tmp let-item="item">
                                    <span class="font-13" [textContent]="item.name"></span>
                                </ng-template>
                            </ng-select>
                        </div>
                    </div>
                    <ng-container *ngIf="form.payType?.value === 'I'">
                        <div class="col-4 col">
                            <div class="form-group" [class.mb-0]="form.payType?.value !== 'I'">
                                <label class="font-13 font-weight-bold mb-0">Charge Template</label>
                                <ng-select (change)="onChangeChargeModuleTemplate($event)" [items]="chargeModuleTemplates" [ngClass]="{ 'is-invalid': isSubmitted && form.chargeModuleTemplateId?.errors }" [loading]="loadingChargeModuleTemplates" [clearable]="false" bindLabel="name" bindValue="id" [notFoundText]="'No Charge Template found'" placeholder="Select charge template" formControlName="chargeModuleTemplateId">
                                    <ng-template ng-option-tmp let-item="item">
                                        <span class="font-weight-normal font-13" [textContent]="item.name"></span>
                                    </ng-template>
                                    <ng-template ng-label-tmp let-item="item">
                                        <span class="font-weight-normal font-13" [textContent]="item.name"></span>
                                    </ng-template>
                                </ng-select>
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="form.payType?.value === 'I'">
                        <div class="col-4 col">
                            <div class="form-group mb-0">
                                <label class="font-13 font-weight-bold mb-0">Insurance Company</label>
                                <ng-select [items]="insuranceCompanies" [loading]="loadingInsuranceCompanies" [clearable]="false" bindLabel="value" bindValue="id" placeholder="Select Insurance Type" formControlName="insuranceCompanyId">
                                    <ng-template ng-option-tmp let-item="item">
                                        <span class="font-weight-normal font-13" [textContent]="item.value"></span>
                                    </ng-template>
                                    <ng-template ng-label-tmp let-item="item">
                                        <span class="font-weight-normal font-13" [textContent]="item.value"></span>
                                    </ng-template>
                                </ng-select>
                            </div>
                        </div>
                        <div class="col-4 col">
                            <div class="form-group mb-0">
                                <label class="font-13 font-weight-bold mb-0">TPA Organization</label>
                                <ng-select [items]="tpas" [loading]="loadingTPAs" [clearable]="false" bindLabel="value" bindValue="id" placeholder="Select TPA Organization" formControlName="tpaId">
                                    <ng-template ng-option-tmp let-item="item">
                                        <span class="font-weight-normal font-13" [textContent]="item.value"></span>
                                    </ng-template>
                                    <ng-template ng-label-tmp let-item="item">
                                        <span class="font-weight-normal font-13" [textContent]="item.value"></span>
                                    </ng-template>
                                </ng-select>
                            </div>
                        </div>
                        <div class="col-4 col">
                            <div class="form-group mb-0">
                                <label class="font-13 font-weight-bold mb-0">Patient Organization</label>
                                <input type="text" class="form-control" formControlName="patientOrganization" maxlength="50" titleOnly block autocomplete="nope" placeholder="Enter Patient Working Organization" />
                            </div>
                        </div>
                    </ng-container>
                </div>
            </fieldset>
            <div class="table-responsive mt-3">
                <table class="table table-sm table-striped table-bordered mb-0 font-13">
                    <thead>
                        <tr>
                            <th style="width: 280px;">Package</th>
                            <th style="width: 220px;">Charge Category</th>
                            <th>Package Details</th>
                            <th style="width: 50px;" class="text-right position-relative">
                                <a href="javascript:;" class="action-icon text-primary action-icon-th" (click)="onAddCounsellingPackage()" placement="left" ngbTooltip="Add Package"><i class="mdi mdi-plus-circle"></i></a>
                            </th>
                        </tr>
                    </thead>
                    <tbody class="font-13">
                        <tr *ngFor="let item of counsellingPackages; trackBy: trackByCounsellingPackage">
                            <td>
                                <ng-select [ngModelOptions]="{standalone: true}"
                                           [(ngModel)]="item.packageModuleId"
                                           (change)="onChangePackageModule(item)"
                                           [items]="packageModules"
                                           [loading]="loadingPackageModules"
                                           [clearable]="false"
                                           bindLabel="name"
                                           bindValue="id"
                                           placeholder="Select package">
                                    <ng-template ng-option-tmp let-item="item">
                                        <span class="font-weight-normal font-13 text-capitalize" [textContent]="item.name"></span>
                                    </ng-template>
                                    <ng-template ng-label-tmp let-item="item">
                                        <span class="font-weight-normal font-13 text-capitalize" [textContent]="item.name"></span>
                                    </ng-template>
                                </ng-select>
                            </td>
                            <td>
                                <ng-select [ngModelOptions]="{standalone: true}"
                                           [(ngModel)]="item.chargeCategoryId"
                                           (change)="onChangeChargeCategory(item, $event?.id)"
                                           [items]="item.chargeCategories"
                                           [loading]="item.loadingChargeCategories"
                                           [clearable]="false" bindLabel="name"
                                           bindValue="id"
                                           placeholder="Select charge category"
                                           [notFoundText]="item.packageModuleId ? 'No Charge Categories found' : 'Please select a Package to see Charge Categories'">
                                    <ng-template ng-option-tmp let-item="item">
                                        <span class="font-weight-normal font-12" [textContent]="item.name"></span>
                                    </ng-template>
                                    <ng-template ng-label-tmp let-item="item">
                                        <span class="font-weight-normal font-12" [textContent]="item.name"></span>
                                    </ng-template>
                                </ng-select>
                            </td>
                            <td>
                                <div class="counselling-package-discounts-container">
                                    <div class="counselling-package-discounts-container-item">
                                        <span>Sub Total</span>
                                        <span [textContent]="item.subTotal | currency :'₹ ':'symbol':'1.2-2'"></span>
                                    </div>
                                    <div class="counselling-package-discounts-container-item">
                                        <span>Package Discount</span>
                                        <span [textContent]="item.packageDiscount | currency :'₹ ':'symbol':'1.2-2'"></span>
                                    </div>
                                    <div class="counselling-package-discounts-container-item">
                                        <span>Counselling Discount</span>
                                        <div style="width: calc(100% - 142px);">
                                            <div class="row row-sm">
                                                <div class="col-6 col">
                                                    <select (change)="onChangeDiscount(item, 'discountType')" [ngModelOptions]="{standalone: true}" [(ngModel)]="item.discountType" placeholder="Select discount type" class="form-control-sm font-12 px-0 form-control w-100">
                                                        <option value="A">Amount</option>
                                                        <option value="P">Percentage</option>
                                                    </select>
                                                </div>
                                                <div class="col-6 pl-0 col">
                                                    <input *ngIf="item.discountType === 'P'" class="form-control-sm font-12 form-control px-1 w-100" type="number" (input)="onChangeDiscount(item, 'discountPercentage', $event.target.value)" #discountPercentage min="1" max="100" step="1" block [value]="item.discountPercentage" placeholder="###.##">
                                                    <input *ngIf="item.discountType === 'A'" class="form-control-sm font-12 form-control px-1 w-100" type="number" (input)="onChangeDiscount(item, 'discountAmount', $event.target.value)" #discountAmount min="1" max="1000" step="1" block [value]="item.discountAmount" placeholder="###.##">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr class="my-1" />
                                    <div class="counselling-package-discounts-container-item font-weight-bold">
                                        <span>Total</span>
                                        <span [textContent]="item.total | currency :'₹ ':'symbol':'1.2-2'"></span>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div class="d-flex align-items-center justify-content-end">
                                    <a href="javascript:;" class="action-icon text-info" (click)="onViewCounsellingPackage(item, templateViewPackage)" placement="left" ngbTooltip="View Package"><i class="fe-eye"></i></a>
                                    <a href="javascript:;" class="action-icon text-danger ml-1" (click)="onRemoveCounsellingPackage(item)" placement="left" ngbTooltip="Delete"> <i class="fe-trash-2"></i></a>
                                </div>
                            </td>
                        </tr>
                        <tr><td colspan="4" class="font-13 font-weight-bold mb-0"> <div>Exclusion Notes</div></td></tr>
                        <tr>
                            <td colspan="4">
                                <ckeditor [editor]="notesEditor" [config]="config2" formControlName="extraExclusionsNotes"></ckeditor>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="row row-sm mt-3">
                <div class="col-4 col">
                    <div class="form-group mb-0 d-flex flex-column">
                        <label class="font-13 font-weight-bold mb-0 text-primary">Counselling Given To</label>
                        <div class="d-flex font-13 align-items-center mb-1" *ngIf="patientInfo && !counselling">
                            <p class="mb-0 font-weight-semibold text-capitalize">
                                <span class="text-uppercase" [textContent]="patientInfo.patientName"></span>
                                <small *ngIf="patientInfo.patientGender" [textContent]="' (' + patientInfo.patientGender + ')'"></small>
                            </p>
                            <small class="text-mute ml-1" [textContent]="'/ ' + patientInfo.umrNo"></small>
                        </div>
                        <div class="d-flex font-13 align-items-center mb-1" *ngIf="counselling && !patientInfo">
                            <p class="mb-0 font-weight-semibold text-capitalize">
                                <span class="text-uppercase" [textContent]="counselling.patientName"></span>
                                <small *ngIf="counselling.patientGender" [textContent]="' (' + counselling.patientGender + ')'"></small>
                            </p>
                            <small class="text-mute ml-1" [textContent]="'/ ' + counselling.umrNo"></small>
                        </div>
                        <div class="d-flex font-13 align-items-center mb-1" *ngIf="patientInfo && counselling">
                            <p class="mb-0 font-weight-semibold text-capitalize">
                                <span class="text-uppercase" [textContent]="patientInfo.patientName"></span>
                                <small *ngIf="patientInfo.patientGender" [textContent]="' (' + patientInfo.patientGender + ')'"></small>
                            </p>
                            <small class="text-mute ml-1" [textContent]="'/ ' + patientInfo.umrNo"></small>
                        </div>
                        <div class="signature-container" [class.has-image]="form.patientBase64Signature?.value || form.patientSignature?.value" (click)="onOpenModel(templateSignature, 'Counselling Given To')">
                            <img class="img-fluid" alt="Patient Signature" *ngIf="form.patientBase64Signature?.value" [src]="form.patientBase64Signature?.value" />
                            <img class="img-fluid" alt="Patient Signature" *ngIf="!form.patientBase64Signature?.value && counselling?.patientSignatureRootPath && form.patientSignature?.value" [src]="page.getImage(counselling.patientSignatureRootPath + form.patientSignature?.value)" />
                            <p><span class="mdi mdi-pencil-outline mr-1"></span> Signature</p>
                        </div>
                    </div>
                </div>


                <div class="col-4 col">
                    <div class="form-group mb-2 d-flex flex-row align-items-center justify-content-between">
                        <label class="font-13 font-weight-bold mb-0">AttendentName<code>*</code></label>
                        <input type="text" class="form-control ml-2" formControlName="attendantName" [ngClass]="{ 'is-invalid': isSubmitted && form.attendantName.errors }" />
                        <div *ngIf="submitted && form.attendantName.errors" class="invalid-feedback show-must">
                            <p *ngIf="form.attendantName.error.required">This field is required.</p>
                        </div>

                    </div>

                    <div class="form-group mb-2 d-flex flex-row align-items-center justify-content-between">
                        <label class="font-13 font-weight-bold mb-0">Relationship <code>*</code></label>
                        <div class="pl-3 w-200p">
                            <ng-select [ngClass]="{ 'is-invalid': isSubmitted && form.attendantRelationship.errors }"
                                       formControlName="attendantRelationship"
                                       [items]="relations"
                                       bindLabel="value"
                                       bindValue="value"
                                       [loading]="loadingRelations"
                                       placeholder="Select relation">
                                <ng-template ng-option-tmp *ngIf="loadingRelations">
                                    Loading...
                                </ng-template>
                            </ng-select>
                        </div>
                    </div>
                    <div class="form-group mb-2 d-flex flex-row align-items-center justify-content-between">
                        <label class="font-13 font-weight-bold mb-0">Contact No <code>*</code></label>
                        <input type="text" class="form-control ml-2" formControlName="attendantContactNo"
                               [ngClass]="{ 'is-invalid': isSubmitted && form.attendantContactNo.errors }"
                               maxlength="10" numbersOnly block autocomplete="nope" placeholder="Enter mobile number" />
                    </div>
                  

                    <div class="form-group mb-0 d-flex flex-row align-items-center justify-content-between">
                        <label class="font-13 font-weight-bold mb-0 text-primary">Attendent Signature</label>
                        <div class="signature-container"
                             [class.has-image]="form.attendentBase64Signature?.value || form.attendentSignature?.value"
                             (click)="onOpenModel(templateSignature, 'Attendent')">
                            <img class="img-fluid" alt="Attendent Signature"
                                 *ngIf="form.attendentBase64Signature?.value"
                                 [src]="form.attendentBase64Signature?.value" />
                            <img class="img-fluid" alt="Attendent Signature"
                                 *ngIf="!form.attendentBase64Signature?.value && counselling?.attendentSignatureRootPath && form.attendentSignature?.value"
                                 [src]="page.getImage(counselling.attendentSignatureRootPath + form.attendentSignature?.value)" />
                            <p><span class="mdi mdi-pencil-outline mr-1"></span> Signature</p>
                        </div>
                    </div>
                </div>


                    <div class="col-4 col">
                        <div class="form-group mb-0 d-flex flex-column align-items-end">
                            <label class="font-13 font-weight-bold mb-0 text-primary">Counsellor<small class="ml-1">(Counselling Given By)</small></label>
                            <div class="d-flex font-13 align-items-center mb-1" *ngIf="patientInfo && !counselling">
                                <p class="mb-0 font-weight-semibold text-capitalize" [textContent]="page.userAccount.fullName"></p>
                                <small class="text-mute ml-1" [textContent]="' / ' + page.userAccount.roleName"></small>
                            </div>
                            <div class="d-flex font-13 align-items-center mb-1" *ngIf="counselling && !patientInfo">
                                <p class="mb-0 font-weight-semibold text-capitalize" [textContent]="counselling.createdByName"></p>
                                <small class="text-mute ml-1" [textContent]="' / ' + (counselling.createdDate | amDateFormat : 'DD-MM-YYYY hh:mm a')"></small>
                            </div>
                            <div class="d-flex font-13 align-items-center mb-1" *ngIf="counselling && patientInfo">
                                <p class="mb-0 font-weight-semibold text-capitalize" [textContent]="counselling.createdByName"></p>
                                <small class="text-mute ml-1" [textContent]="' / ' + (counselling.createdDate | amDateFormat : 'DD-MM-YYYY hh:mm a')"></small>
                            </div>
                            <div class="signature-container" [class.has-image]="form.counsellorBase64Signature?.value || form.counsellorSignature?.value" (click)="onOpenModel(templateSignature, 'Counsellor')">
                                <img class="img-fluid" alt="Counsellor Signature" *ngIf="form.counsellorBase64Signature?.value" [src]="form.counsellorBase64Signature?.value" />
                                <img class="img-fluid" alt="Counsellor Signature" *ngIf="!form.counsellorBase64Signature?.value && counselling?.counsellorSignatureRootPath && form.counsellorSignature?.value" [src]="page.getImage(counselling.counsellorSignatureRootPath + form.counsellorSignature?.value)" />
                                <p><span class="mdi mdi-pencil-outline mr-1"></span> Signature</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <ng-container>
                <hr class="my-2" />
                <div class="d-flex mb-2 mr-2 align-items-center justify-content-end">
                    <button type="button" (click)="onSaveAsDraft()" [disabled]="isSubmitting || isSavingDraft" class="btn btn-sm mb-0 ml-0 reset-btn">
                        <span *ngIf="isSavingDraft">
                            <span class="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
                            Please wait..
                        </span>
                        <span *ngIf="!isSavingDraft">Save Draft</span>
                    </button>
                    <button type="button" (click)="onSubmit()" [disabled]="isSubmitting || isSavingDraft" class="btn btn-sm mb-0 ml-1 btn-primary">
                        <span *ngIf="isSubmitting">
                            <span class="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
                            Please wait..
                        </span>
                        <span *ngIf="!isSubmitting">Publish</span>
                    </button>
                    <button type="button" (click)="onCancel()" [disabled]="isSubmitting || isSavingDraft" class="btn btn-sm mb-0 ml-1 btn-secondary">Cancel</button>
                </div>
            </ng-container>
        </div>
</form>
<ng-template #templateSignature>
    <div class="modal-header">
        <h4 class="modal-title">
            <span class="btn-group">
                <button type="button" class="btn btn-xs" [class.reset-btn]="signatureType === 'signature'" placement="left" ngbTooltip="Signature" [class.btn-outline-light]="signatureType === 'digital'" (click)="signatureType = 'signature'">Draw</button>
                <button type="button" class="btn btn-xs" [class.reset-btn]="signatureType === 'digital'" placement="left" ngbTooltip="Digital" [class.btn-outline-light]="signatureType === 'signature'" (click)="signatureType = 'digital'">Upload</button>
            </span>
            <span style="position: relative; top: 1px; left: 10px;">{{signatureFor}} Signature</span>
        </h4>
        <button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="onCloseModal();">×</button>
    </div>
    <div class="modal-body">
        <div class="d-flex align-items-center" *ngIf="isSaveSignature">
            <span class="spinner-grow text-warning" role="status" aria-hidden="true"></span>
            <span class="ml-2">Please wait while saving uploaded signature...</span>
        </div>
        <img-crop *ngIf="signatureType === 'digital'" [image]="null" [aspectRatio]="4/4" [resizeToWidth]="250" (croppedImageEmitter)="onReceiveDrewSignature($event)"></img-crop>
        <!--<image-drawing #imageId *ngIf="signatureType === 'signature'" src="assets/images/whiteBackground.png" outputQuality="0.5" borderCss="1px solid black" [showCancelButton]="false" [drawingSizes]="{ small: 3, medium: 5 }" width="400" height="250" (save)="onSaveUploadedSignature($event)">
        </image-drawing>-->
        <signature-widget *ngIf="signatureType === 'signature'" width="500" height="200" (done)="onSignatureDone($event)"></signature-widget>
    </div>
    <div class="modal-footer" *ngIf="signatureType === 'digital'">
        <button type="button" class="btn btn-sm btn-secondary mr-1" (click)="onCloseModal();">Close</button>
        <button type="button" (click)="onCloseModal()" class="btn btn-sm btn-primary">Submit</button>
    </div>
</ng-template>
<ng-template #templateViewPackage>
    <div class="modal-header">
        <h4 class="modal-title">Package Details</h4>
        <button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="onCloseModal();">×</button>
    </div>
    <div class="modal-body">
        <view-package-module [packageModuleId]="selectedCounsellingPackage.packageModuleId" [chargeCategory]="selectedCounsellingPackage.chargeCategory" [isCounselling]="true" [chargeCategoryId]="selectedCounsellingPackage.chargeCategoryId"></view-package-module>
    </div>
</ng-template>
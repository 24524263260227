export class MealTypes {
    mealTypeId: number;
    mealType: string;
    mealInstruction: string;
    active: boolean;
    totalItems: number;
    modifiedByName: string;
    createdBy: number;
    createdByName: string;
    loginRoleId: number;
    isSelected: boolean;
    endDate: Date;
    isMorning: boolean;
    isAfternoon: boolean;
    isNight: boolean;
    dietFrequency: Array<DietFrequency>;

    locationIds: string;
    modifiedByRole: string;
    createdByRole: string;
}

export class DietFrequency {
    dietInstructionTypeId: number;
    dietInstructionDeepTypeId: number;
    dietInstructionName: string;
    progressReportdietFrequencyId: string;
    dietDate: Date;
    status: boolean

}
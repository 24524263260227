export enum GYNType {
    Abdomen = 1,
    AdditionalLabs,
    BirthControl,
    Breast,
    BreastScreening,
    CancerHistory,
    Cardiovascular,
    GynHistory,
    GynLabs,
    GynUltraSound,
    Heent,
    LastBreastUltraSound,
    LastDexaScan,
    LastMammogram,
    LastPelvicUltraSound,
    Lymphatic,
    MenopausalHistory,
    MensutralHistory,
    Musculoskeletal,
    PatientDeferred,
    Pelvic,
    PhysicalExam,
    Respiratory,
    Skin,
    Vitals,
    GyneacCard,
    GynaecOrders,
    GynIUI,
    GynaecVisit,
    Procedure,
    GyneacPartner,
    ProformaForOasi,
    familyHistory,
    ProblemList,
    surgeries, birthHistory,
    Allergies,
    OrderPrescription,
    GyneacAdmissionSheet,
    GyneacSurgery,
    GyneacDiscargeSummary,
    Colposcopic,
    Ectopic,
    SpeacialFeature,
    ReferralForm,
    Measure,
    RefferalOrder,
    MeasureCommonData,
    ScanAppointmentDetails,
    OutSideTests,
    Reminder,
    DoctorsReviewForm,
    AddendumForm,
    surgerySlip,
    AdmssionSlip,
    Confidential,
    NursingERForm,
    FutureAppointments,
    OtAppointmentDetails,
    ScanTests,
    TelePhoneTriageAssessment,
    AnaesthesiaRecordPostOP,
    AldreteScore,
}
<div class="bg-white margin-left-14px margin-right-14px">
    <div class="border-soft-secondary border-left-0 border-right-0 border  justify-content-between bg-white">
        <div class="align-items-center d-flex gap5px headerHeight textColorEncounter white-space-nowrap">
            <div class="gap5px align-items-center d-flex">
                <div class="align-self-start avatar-sm">
                    <img *ngIf="appointment && appointment.patientThumbnailUrl" [src]="safe(appointment.patientThumbnailUrl)" class="rounded-circle avatar-sm" [alt]="appointment.patientName">
                    <span *ngIf="appointment && !appointment.patientThumbnailUrl" class="avatar-title rounded-circle bg-primary text-white" [textContent]="appointment.patientName | initials"></span>
                </div>
                <h4 class="font-16 white-space-nowrap"><a href="javascript:;" class="mb-0 mt-0  text-uppercase fontAt1366px text-primary" *ngIf="appointment && appointment.patientName">{{appointment.patientName}}</a></h4>
                <h4 class="font-16 white-space-nowrap">UMRNO:<a href="javascript:;" class="mb-0 mt-0  text-uppercase text-primary fontAt1366px" *ngIf="appointment && appointment.umrNo">{{appointment.umrNo}}</a></h4><br />
            </div>
            <div class="d-flex heading-color1 font-18 font-weight-bold gap10px" placement="left" ngbTooltip="title-mc">
                <div>
                    <span *ngIf="ancCards && ancCards.ga">GA</span>
                    <sub class="font-15 line-height-normal" style="margin-right: 3px;" *ngIf="ancCards && ancCards.ga" [textContent]="ancCards.ga"></sub>
                </div>
                <div class="no-padding" *ngIf="ancCards">
                    <span *ngIf="ancCards && ancCards.gravida && ancCards.gravida != null">G</span><sub class="font-15" style="margin-right: 3px;" [textContent]="ancCards.gravida"></sub>
                    <span *ngIf="ancCards && ancCards.para && ancCards.para != null">P</span><sub class="font-15" style="margin-right: 3px;" [textContent]="ancCards.para"></sub>
                    <span *ngIf="ancCards && ancCards.living && ancCards.living != null">L</span><sub class="font-15" style="margin-right: 3px;" [textContent]="ancCards.living"></sub>
                    <span *ngIf="ancCards && ancCards.abortion && ancCards.abortion != null">A</span><sub class="font-15" style="margin-right: 3px;" [textContent]="ancCards.abortion"></sub>
                </div>
            </div>
            <div class="heading-color1 font-weight-bold" *ngIf="ancCards && ancCards.edd &&  roleId != 63">
                <span class="font-17">EDD:{{ancCards.edd}}</span>
            </div>
            <div class="heading-color1 font-weight-bold" *ngIf="visit">
                <span class="font-17">Visit No:{{visit}}</span>
            </div>
            <div class="d-flex gap5px h-100 p-1 align-items-center" *ngIf="appointment">
                <div class="width30px">
                    <img class="w-100" src="assets/images/timelineSvg/encounter.svg" />
                </div>
                <div *ngIf="!isAdmission">
                    <h4 class="font-16 white-space-nowrap">Encounter by <a href="javascript:;" class="mb-0 mt-0  text-uppercase text-primary fontAt1366px">{{appointment.providerName}}</a> on &nbsp;<span class="text-primary fontAt1366px" [textContent]="appointment.appointmentDate|date:'dd/MM/yyyy'"></span><span class="text-primary fontAt1366px">&nbsp;{{appointment.appointmentTimeString}}</span></h4>
                </div>
                <div *ngIf="isAdmission">
                    <h4 class="font-16 white-space-nowrap">Encounter by <a href="javascript:;" class="mb-0 mt-0  text-uppercase text-primary fontAt1366px">{{appointment.providerName}}</a> on &nbsp;<span class="text-primary fontAt1366px" [textContent]="appointment.appointmentDate|date:'dd/MM/yyyy'"></span><span class="text-primary fontAt1366px">&nbsp;{{appointment.admTime}}</span></h4>
                </div>
            </div>

            <div *ngIf="isAdmission">
                <span class="heading-color1 font-weight-bold" *ngIf="patient">
                    <span class="font-17">IP No:{{patient.patient.admissionId}}</span>&nbsp;
                </span>
                <span class="heading-color1 font-weight-bold" *ngIf="appointment">
                    <span class="font-17">Room Name:{{appointment.roomName}}</span>
                </span>
            </div>
        </div>

        <div class="d-flex pt-1 pb-1">
            <div class="border d-flex encounterWedgets flex-grow-1 mx-1" *ngIf=" roleId != 63 && roleId != 82 ">
                <div class="align-items-center justify-content-center d-flex w-100-px w-75p" *ngIf="!antinantalRisk">
                    <img class="w-75" src="../../../../assets/images/timelineSvg/riskfactor-c1.svg" />
                </div>
                <div class="flex-grow-1 m-1" *ngIf="!antinantalRisk">
                    <h5 class="heading-color1 mb-0" *ngIf="!antinantalRisk">Risk Factor:</h5>
                    <div class="p-0 d-flex font-weight-medium w-100" *ngIf="roleId != 63 && roleId != 82">
                        <div *ngIf="!antinantalRisk">
                            <span class="line-height-20px white-space-nowrap heading-color1" style="font-size: medium;"><i class="rotatingExclamation mdi mdi-exclamation text-danger mr-1"></i>Section is not reviewed yet</span>
                        </div>
                    </div>
                </div>
                <div class="flex-grow-1 overflow-auto" *ngIf="antinantalRisk && antinantalRisk !== null &&  roleId != 63 && roleId != 82 ">
                    <div class="d-flex align-items-center p-2px pl-0">
                        <h5 class="d-inline font-14 heading-color2 line-height-1 m-0 mx-1 white-space-nowrap">Risk Factor</h5><span class="font-14 text-black" *ngIf="antinantalRisk && antinantalRisk.noRisk">&nbsp;:<span>&nbsp;No Risk</span></span>

                        <!--<button class="btn text-primary d-block line-height-1px font-12 px-1 text-center"><i class=" mdi mdi-arrow-expand-vertical"></i> See more</button>-->
                    </div>
                    <div class="encounterWedgetsContent pr-1" *ngIf="antinantalRisk && !antinantalRisk.noRisk">
                        <div *ngIf="antinantalRisk">
                            <div *ngIf="antinantalRisk &&( antinantalRisk.maternalmedical.length > 0|| antinantalRisk.uterineRiskFactor.length > 0 || antinantalRisk.pregnancyComplications.length > 0 || antinantalRisk.fetusComplications.length > 0 || antinantalRisk.characteristics.length > 0 || antinantalRisk.details.length > 0)">
                                <div class="d-flex align-items-baseline font-weight-medium w-100">
                                    <h6 class="white-space-nowrap heading-color3 m-0 mb-1 d-inline line-height-1 mx-1">Antenatal Risk :</h6>
                                    <div class="w-100 mb-0 dashboard-break font-14">
                                        <div style="column-gap: 35px;" class="mb-0 line-height-1 text-black">
                                            <span *ngFor="let item of antinantalRisk.characteristics; let i=index;">
                                                <span class="pr-1" *ngIf="item && item !=''" [textContent]="item"></span>
                                                <span *ngIf="(i != antinantalRisk.characteristics.length-1) && (item && item !='')">,</span>
                                            </span>
                                            <span *ngFor="let item of antinantalRisk.details; let i=index;">
                                                <span class="pr-1" *ngIf="item && item !=''" [textContent]="item"></span>
                                                <span *ngIf="(i != antinantalRisk.details.length-1) && (item && item !='')">,</span>
                                            </span>
                                            <span *ngFor="let item of antinantalRisk.maternalmedical; let i=index;">
                                                <span class="pr-1" *ngIf="item && item !=''" [textContent]="item"></span>
                                                <span *ngIf="(i !=antinantalRisk.maternalmedical.length-1) && (item && item !='')">,</span>
                                            </span>
                                            <span *ngFor="let item of antinantalRisk.uterineRiskFactor; let i=index;">
                                                <span class="pr-1" *ngIf="item && item !=''" [textContent]="item"></span>
                                                <span *ngIf="(i !=  antinantalRisk.uterineRiskFactor.length-1) && (item && item !='')">,</span>
                                            </span>
                                            <span *ngFor="let item of antinantalRisk.pregnancyComplications; let i=index;">
                                                <span class="pr-1" *ngIf="item && item !=''" [textContent]="item"></span>
                                                <span *ngIf="(i !=  antinantalRisk.pregnancyComplications.length-1) && (item && item !='')">,</span>
                                            </span>
                                            <span *ngFor="let item of antinantalRisk.fetusComplications; let i=index;">
                                                <span class="pr-1" *ngIf="item && item !=''" [textContent]="item"></span>
                                                <span *ngIf="(i != antinantalRisk.fetusComplications.length-1) && (item && item !='')">,</span>
                                            </span>
                                        </div>
                                    </div>
                                </div>


                            </div>
                            <h6 *ngIf="antinantalRisk && !( antinantalRisk.maternalmedical.length > 0|| antinantalRisk.uterineRiskFactor.length > 0 || antinantalRisk.pregnancyComplications.length > 0 || antinantalRisk.fetusComplications.length > 0 || antinantalRisk.characteristics.length > 0 || antinantalRisk.details.length > 0)" class="white-space-nowrap heading-color3 m-0 mb-1 d-inline line-height-1 mx-1">Antenatal Risk :</h6>

                            <div class="d-flex align-items-baseline font-weight-medium w-100" *ngIf="antinantalRisk && (antinantalRisk.covidInfection !=null && antinantalRisk.covidInfection !='')">
                                <h6 class="white-space-nowrap heading-color3 m-0 mb-1 d-inline line-height-1 mx-1"> Covid-19 Infection :</h6>
                                <span [textContent]="antinantalRisk.covidInfection"></span>
                            </div>
                            <div class="d-flex align-items-baseline font-weight-medium w-100" *ngIf="antinantalRisk && (antinantalRisk.otherComplaints !=null && antinantalRisk.otherComplaints !='')">
                                <h6 class="white-space-nowrap heading-color3 m-0 mb-1 d-inline line-height-1 mx-1"> Other :</h6>
                                <span [textContent]="antinantalRisk.otherComplaints"></span>
                            </div>
                        </div>

                        <div *ngIf="antinantalRisk &&( antinantalRisk.meternall.length > 0|| antinantalRisk.latePragnancy.length > 0 || antinantalRisk.currentPragnancy.length > 0 || antinantalRisk.funtalFetaus.length > 0 || antinantalRisk.maternalMedicalHistory.length > 0 || antinantalRisk.previousPregency.length > 0)">
                            <div class="d-flex align-items-baseline font-weight-medium w-100">
                                <h6 class="white-space-nowrap heading-color3 m-0 mb-1 d-inline line-height-1 mx-1">SGA Screening   : </h6>
                                <div class="w-100 mb-0 dashboard-break font-14">
                                    <div style="column-gap: 35px;" class="mb-0 line-height-1 text-black">
                                        <span *ngFor="let item of antinantalRisk.meternall; let i=index;">
                                            <span class="pr-1" *ngIf="item && item !=''" [textContent]="item"></span>
                                            <span *ngIf="(i != antinantalRisk.meternall.length-1) && (item && item !='')">,</span>
                                        </span>
                                        <span *ngFor="let item of  antinantalRisk.previousPregency; let i=index;">
                                            <span class="pr-1" *ngIf="item && item !=''" [textContent]="item"></span>
                                            <span *ngIf="(i !=  antinantalRisk.previousPregency.length-1) && (item && item !='')">,</span>
                                        </span>

                                        <span *ngFor="let item of antinantalRisk.maternalMedicalHistory; let i=index;">
                                            <span class="pr-1" *ngIf="item && item !=''" [textContent]="item"></span>
                                            <span *ngIf="(i !=  antinantalRisk.maternalMedicalHistory.length-1) && (item && item !='')">,</span>
                                        </span>
                                        <span *ngFor="let item of antinantalRisk.funtalFetaus; let i=index;">
                                            <span class="pr-1" *ngIf="item && item !=''" [textContent]="item"></span>
                                            <span *ngIf="(i != antinantalRisk.funtalFetaus.length-1) && (item && item !='')">,</span>
                                        </span>
                                        <span *ngFor="let item of antinantalRisk.currentPragnancy; let i=index;">
                                            <span class="pr-1" *ngIf="item && item !=''" [textContent]="item"></span>
                                            <span *ngIf="(i != antinantalRisk.currentPragnancy.length-1) && (item && item !='')">,</span>
                                        </span>
                                        <span *ngFor="let item of antinantalRisk.latePragnancy; let i=index;">
                                            <span class="pr-1" *ngIf="item && item !=''" [textContent]="item"></span>
                                            <span *ngIf="(i != antinantalRisk.latePragnancy.length-1) && (item && item !='')">,</span>
                                        </span>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="antinantalRisk && (antinantalRisk.highRisk.length > 0||antinantalRisk.intermediateRisk.length > 0|| antinantalRisk.lowRisk.length > 0  )">
                            <div class="d-flex align-items-baseline font-weight-medium w-100">
                                <h6 class="white-space-nowrap heading-color3 m-0 mb-1 d-inline line-height-1 mx-1">VTE Risk         : </h6>
                                <div class="w-100 mb-0 dashboard-break font-14">
                                    <div style="column-gap: 35px;" class="line-height-1 mb-0 text-black">
                                        <span *ngFor="let item of antinantalRisk.highRisk; let i=index;">
                                            <span class="pr-1" *ngIf="item && item !=''" [textContent]="item"></span>
                                            <span *ngIf="(i != antinantalRisk.highRisk.length-1) && (item && item !='')">,</span>
                                        </span>
                                        <span *ngFor="let item of antinantalRisk.intermediateRisk; let i=index;">
                                            <span class="pr-1" *ngIf="item && item !=''" [textContent]="item"></span>
                                            <span *ngIf="(i !=  antinantalRisk.intermediateRisk.length-1) && (item && item !='')">,</span>
                                        </span>
                                        <span *ngFor="let item of antinantalRisk.lowRisk; let i=index;">
                                            <span class="pr-1" *ngIf="item && item !=''" [textContent]="item"></span>
                                            <span *ngIf="(i != antinantalRisk.lowRisk.length-1) && (item && item !='')">,</span>
                                        </span>

                                    </div>
                                </div>
                            </div>

                        </div>
                        <div class="d-flex align-items-baseline font-weight-medium w-100" *ngIf="antinantalRisk && (antinantalRisk.comments && antinantalRisk.comments !='')">
                            <h6 class="white-space-nowrap heading-color3 m-0 mb-1 d-inline line-height-1 mx-1"> VTE Risk Other       : </h6>
                            <span [textContent]="antinantalRisk.comments"></span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-4 min-fit-width" *ngIf="!allergies && roleId != 63 && roleId != 82">
                <div class="d-flex border encounterWedgets">
                    <div class="align-items-center justify-content-center d-flex w-100-px w-75p">
                        <img class="w-75" src="../../../../assets/images/timelineSvg/allergies-c2.svg" />
                    </div>
                    <div class="flex-grow-1 m-1">
                        <h5 class="heading-color1 mb-0" *ngIf="!allergies">Allergies Sections:</h5>
                        <div class="p-0 d-flex font-weight-medium w-100" *ngIf="roleId != 63 && roleId != 82">
                            <div *ngIf="!allergies">
                                <span class="line-height-20px heading-color1" style="font-size: medium;"><i class="rotatingExclamation mdi mdi-exclamation text-danger mr-1"></i>Section is not reviewed yet</span><br>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-4" *ngIf="specialFetures  && roleId != 63 && roleId != 82">
                <div class="d-flex border encounterWedgets pr-1">
                    <div class="align-items-center justify-content-center d-flex w-100-px w-75p">
                        <img class="w-75" src="../../../../assets/images/timelineSvg/special-features-c1.svg" />
                    </div>
                    <div class="flex-grow-1" *ngIf="!specialFetures">
                        <h5 class="heading-color2 mb-0">Special Features:</h5>
                    </div>
                    <div class="flex-grow-1" *ngIf="specialFetures">
                        <h5 class="d-inline font-14 heading-color2 line-height-1 m-0 white-space-nowrap">Special Features:</h5>
                        <div class="encounterWedgetsContent pr-1 text-black font-14" [textContent]="specialFetures"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

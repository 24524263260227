export class DietGuidlinesModel {
    dietGuidLinesId: number;
    dietGuidLinesName: string;
    pdfLink: string;
    active: boolean;
    createdBy: number;
    createdDate: Date;
    modifiedBy?: number;
    modifiedDate?: Date;
    createdByName: string;
    createdByRole: string;
    modifiedByName: string;
    modifiedByRole: string;
    totalItems: number;
    contentType: string;
    maxFileSize: string;
    base64: string;
    formedUrl: any;
    isImage: boolean;
    isVideo: boolean;
    documentUrl: string;
    dietTypeId: number;
    dietTypeName: string;
    roleName: string;
}
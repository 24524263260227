export class LabBillHeaderModel {
    newLabBookingHeaderId: number;
    patientId?: number;
    doctorId?: number;
    employeeId?: number;
    locationId: number;
    overallTotalAmount: number;
    overallDiscount: number;
    overallNetAmount: number;
    overallDiscountPercentage?: number;
    payTypeId: number;
    createdBy: number;
    modifiedBy?: number;
    age?: number;
    requisitionNumber: string;
    type: string;
    paymentNumber: string;
    createdByName: string;
    modifiedByName: string;
    patientName: string;
    mobile: string;
    doctorName: string;
    patientThumbnailUrl: string;
    employeeName: string;
    payTypeName: string;
    locationName: string;
    umrNo: string;
    gender: string;
    createdDate: Date;
    modifiedDate?: Date;
    stastatusDate?: Date;
    totalItems: number;
    index?: number;
    newLabCancelBookingHeaderId?: number;
    totalReturnAmount?: number;
    active?: boolean;
    dateOfBirth: Date;
    encryptedId: string;
    isSelected: boolean;
    labs: Array<LabBillDetailModel>;
    status: string;
    testName: string;
    discountAmount?: number;
    totalAmount: number;
    netAmount: number;
    grandTotal?: number;
    isSalucroBill: boolean;
    isShow?: boolean;
    bookingType: string;
    typeOfPayment: string;
    paidAmount: number;
    paymentStatus: boolean;
    paymentType: string;
    createdByRole?: string;
    modifiedByRole?: string;
    lapayTypesbs: Array<LabBillDetailModel>;
    payTypes: Array<PayTypes>;
    consentFormUrl: string;
    encounterType: string;
    encryptedAppointmentId: string;
    encryptedAdmissionId: string;
    encryptedNewLabBookingHeaderId: string;
    removedamount: number;
    labResponce: Array<LabBillHeaderModel>;
    verifiedDate: Date;
    rowColor: string;
    email: string;
    sampleUsage: string;
    labVacutainerName: string;
    admissionId: number;
    isAccepted: boolean;
    overallTotalAmountInWord: string;
    newLabBookingDetailId: number;
    isExternalLab?: boolean;
    isInternalLab?: boolean;
    transferredToExternal: boolean
    emergency: boolean
    quickReport: boolean
    labServicesId: number;
    roomName: string;
    wardName: string;
    bedNumber: string;
    packageModuleId: number;
    constructor() {
        this.labs = new Array<LabBillDetailModel>();
        this.payTypes = new Array<PayTypes>();
    }
   
}
export class PayTypes {
    payTypeId: number;
    payTypeName: string;
    amountPaid: number;
    paymentDetails: string;
    createdDate: Date;
    receiptTypeId: number;
    receiptDate: Date;
    removedAmount: number;
}
export class LabBillDetailModel {
    newLabBookingDetailId: number;
    newLabBookingHeaderId: number;
    labMainDetailId: number;
    chargeCategoryId: number;
    labBookingStatusId: number;
    discountPercentage?: number;
    discountAmount?: number;
    totalAmount: number;
    netAmount: number;
    status: string;
    testName: string;
    testCode: string;
    departmentName: string;
    chargeCategoryName: string;
   
    collectedByName: string;
    collectionDate: Date;
    url: string;
    reportUrl: string;
    uploadedByName: string;
    uploadedDate?: Date;
    emergency: boolean;
    isExternalLab?: boolean;
    homeSample: boolean;
    technicianId?: number;
    technicianComment: string;
    technicianVerificationDate?: Date;
    submitting?: boolean; 
    verifiedDate?: Date;
    verifiedBy?: string;
    encryptedNewLabBookingDetailId: string;
    transferredToExternal?: boolean;
    quickReport: boolean;
    rowColor: string;
    encryptedLabMainDetailId: string;
    patientId: number;
    encryptedNewLabBookingHeaderId: string;
    role: string;
    isFromPackage: boolean;
}
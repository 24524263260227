/* eslint-disable @typescript-eslint/no-inferrable-types */
/* eslint-disable no-debugger */
import { Component, Input, OnChanges, SimpleChanges, TemplateRef, ElementRef, ViewChild, OnInit } from "@angular/core";
import { TimelineToggleService, HttpService, AppConfig, JitsiService, NotifyService, AppData, EncounterCommunication } from "@src/app/shared/services";
import { Timeline, Appointment, PatientDocument, ScanDocument } from "@src/app/shared/entities";
import { ApiResources, UtilHelper, DateHelper } from "@shared/helpers";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { finalize, takeUntil } from "rxjs/operators";
import { ProgressData, ObEncounter, Page, IUserAccount, GenericResponse, GenericStatus } from "../../models";
import { Router } from "@angular/router";
import { DomSanitizer } from '@angular/platform-browser';
import { LabBillDetailModel } from "src/app/areas/admin/labs/pages/models/lab-booking-model";
import { OB } from "@src/app/areas/admin/full-transcript/pages/ob-report/ob.namespace";
import { GYN } from "../../../areas/admin/gyn-encounter/gyn.namespace";
import { LabReportPatientModel } from "../../../areas/admin/labs/pages/models/lab-report-patient.model";
import { LabParameterInputModel } from "../../../areas/admin/labs/pages/models";
import { Callbacks } from "jquery";
import { FormGroup, FormBuilder } from "@angular/forms";
import { HttpErrorResponse } from "@angular/common/http";
import moment from "moment";
enum TimelineAction {
    MedicationsAdded = 8,
    MedicationsUpdated = 9,
    NotesAdded = 10,
    NotesUpdated = 11,
    FollowUpAdded = 12,
    FollowUpUpdated = 13
}

class ViewModel {
    documents: Array<any>;
    data: string;
    medications: Array<any>;
    notes: any;
    followUp: any;
}
class InvestigationCheckList {
    testName: string;
    labMainDetailId: number;
    encryptedLabMainDetailId: string;
    approvedDate: Date;
    isDone: boolean;
}

@Component({
    selector: "timeline-widget",
    styleUrls: ["./timeline.widget.css"],
    templateUrl: "./timeline.widget.html"
})
export class TimelineWidget implements OnChanges {

    @Input() isAdmission: boolean;
    @Input() eid: string;
    @Input() encounterType: string;
    @Input() encrypPatId: string;
    @Input() pid: number;

    @ViewChild("videoPlayer", { static: false }) videoPlayer: ElementRef;
    @ViewChild("templateLabReport", { static: true }) templateLabReport: TemplateRef<any>;
    @ViewChild("templatePacsImage", { static: true }) templatePacsImage: TemplateRef<any>;
    @ViewChild("templateArchiveViewDocument", { static: true }) templateArchiveViewDocument: TemplateRef<any>;

    isload = true
    modalRef: NgbModalRef;
    selected: Timeline;
    viewData: ViewModel;
    loadingDocument: boolean;
    documentError: boolean;
    showTranscript = false;
    selectedSpecializationName: string = 'All';
    checklEventId: number
    testNames: string[] = ["Blood Group", "Hiv", "Hsb Ag", "Cbc", "Cue", "Urine Culture Sensitivity", "Tsh", "Hba 1c", "Ogtt", "Fbs", "Plbs", "Rubella lgg", "Vitamin D3", "Hb Variants Estimation By H.p.l.c", "Vdrl", "Creatinine", "Sgpt", "Ldh"];
    appointment: Appointment;
    ob: Array<ObEncounter>;
    appointmentCounts: Array<Appointment>;
    showOpdVisit = false;
    showDocument = false;
    showPacs = false;
    documentsRecord: Array<PatientDocument>;
    showPac = false;
    pacsImg: ScanDocument;
    selectedDocument: PatientDocument;
    selectedPacsImg: ScanDocument;
    document: PatientDocument;
    loadingPacsImg: boolean;
    labBookingDetail: Array<LabBillDetailModel>;
    showLabs = false;
    selectedLabBookingDetail: LabBillDetailModel;
    anaesthesia: Array<ObEncounter>;
    scanreport: ScanDocument;
    reports: Array<ScanDocument>;
    openAllFlag = false;
    showAll = true;
    showOpdPrescription = false;
    showOpdVisits = false;
    isDocument = false;
    filterText: string;
    defaultImgUrl = 'assets/images/broken.png';
    isLoadAppointment = true;
    obEncounter: ObEncounter;
    ancCards: OB.AncCards;
    ancCardGeneration: OB.AncCardGeneration;
    obPrescriptionRecords: Array<ObEncounter>;
    showMessageBox: boolean;
    loadMessageWindow: boolean;
    gynCardGeneration: GYN.GynCardGeneration;
    dropdownlist: any[] = [];
    specializationlist: any[] = [];
    specialityId: any;
    parentId: any;
    submenulist: any[] = [];
    selectedSpecializationId: any;
    name: any;
    timelineData: any[] = [];
    isAll = false;
    showData = false;
    dropdownValue = false;
    subMenuValue = false;
    eventId: any;
    encryptedNewLabBookingDetailId: string;
    encriptPatientId: string;
    page: Page;
    records: Array<LabReportPatientModel>;
    labs: Array<any>;
    parameters: LabParameterInputModel;
    selectedlab: LabBillDetailModel;
    routingValue: string;
    encryptedAppointmentId: string;
    isComingFrom: any = "";
    isOb = false;
    isGyne = false;
    isPed = false;
    encryptedPatientId: any;
    encryprtedAppId: string
    patient: any;
    data: Array<any>;
    ids: any;
    specializationId: any
    Id: any
    submitted: boolean
    submitting: boolean
    charges: any;
    emergencyId: any;
    date: Date;
    chargetypesDetail: any;
    visittypesDetail: any
    investigationCheckList: Array<InvestigationCheckList>;
    PathReportString: any = []
    errorMessage: any
    archiveStatus: boolean = false;
    ancCardDetails: OB.AncCardGeneration;
    check: any
    checkroute: any;
    obReportLoding: boolean = false;
    isExternal: boolean = false;
    reportData: any;
    reportDataWithoutAuth: any;
    isPrintLogo: boolean;
    PathReportStrings: any = [];
    documentNames: any = []
    multiview: boolean = false;
    openAllSpecalities: boolean = false;
    constructor(
        public toggleService: TimelineToggleService,
        private readonly modalService: NgbModal,
        private readonly httpService: HttpService,
        private readonly router: Router,
        private readonly sanitizer: DomSanitizer,
        private readonly jitsiService: JitsiService,
        private readonly appData: AppData,
        private readonly encounterCommunication: EncounterCommunication,
        private readonly notifyService: NotifyService,
    ) {
        this.page = new Page();
        this.toggleService.encryptedAppointmentId = this.eid;
        this.toggleService.fetch();
        this.appointment;
        this.labBookingDetail = new Array<LabBillDetailModel>();
        this.documentsRecord = new Array<PatientDocument>();
        this.ob = new Array<ObEncounter>();
        this.anaesthesia = new Array<ObEncounter>();
        this.reports = new Array<ScanDocument>();
        this.showMessageBox = false;
        this.loadMessageWindow = false;
        this.records = new Array<LabReportPatientModel>();
        this.labs = new Array<any>;
        this.date = new Date();
    }


    onSubmit() {
        if (this.specializationId == null || this.emergencyId == null) {
            var message = ''
            if (this.specializationId == null) {
                message = 'Specialization'
            } else if (this.emergencyId == null) {
                message = 'VisitType'
            }
            return this.notifyService.warning("Please select " + message)
        } else {
            this.notifyService.confirm("Are you sure you want book an appointment ", () => {
                var encounter = this.data.filter((x) => x.specializationId == this.specializationId)
                var encounterName = encounter[0].encounterName
                var duration = 5
                var time = moment(this.date).format('HH:mm:ss')
                var date = moment(this.date).format('YYYY-MM-DD')
                let abc = moment(date + 'T' + time)
                let future = abc.add(duration, 'minutes');
                var times = future.format("HH:mm:ss")
                var visitype = this.visittypesDetail.filter((x) => x.visitorName == this.emergencyId)
                var chargetype = this.chargetypesDetail.filter((x) => x.chargeName == this.emergencyId)
                let request = { isUnidentified: false, appointmentTypeId: 2 };
                request["patientId"] = this.patient.patientId ? this.patient.patientId : this.appointment.patientId
                request["gender"] = this.patient.gender ? this.patient.gender : this.appointment.patientGender
                request["firstName"] = this.patient.firstName ? this.patient.firstName : this.appointment.patientName
                request["lastName"] = this.patient.lastName
                request["mobile"] = this.patient.mobile
                request["payTypeId"] = 1
                request["visitTypeId"] = visitype[0].visitTypeId
                request["gender"] = this.patient.gender
                request["createdByName"] = this.page.userAccount.fullName;
                request["LocationId"] = this.page.userAccount.locationId;
                request["appointmentDate"] = date,
                    request["charges"] = +this.charges;
                request["specializationId"] = this.specializationId,
                    request["consultationTypeId"] = 1,
                    request["createdBy"] = this.page.userAccount.accountId;
                request["providerAvailabilityId"] = 0
                request["providerId"] = this.ids[0].providerId
                request["updatePatient"] = false;
                request["departmentId"] = this.ids[0].departmentId
                request["appointmentEndTime"] = times,
                    request["appointmentTime"] = time,
                    request["chargeTypesId"] = chargetype[0].chargeTypesId

                var json = JSON.stringify(request);
                const formData = UtilHelper.prepareFormData(UtilHelper.clone(request));
                this.httpService
                    .postFile(ApiResources.getURI(ApiResources.appointments.base, ApiResources.appointments.quickSchedule), formData)
                    .pipe(finalize(() => { this.submitted = false; }))
                    .pipe(takeUntil(this.page.unSubscribe))
                    .subscribe((response: GenericResponse) => {
                        var encryptedAppointmentId = response.id
                        this.onCloseModal();
                        this.emergencyId = null;
                        this.specializationId = null;
                        if (response.status === GenericStatus[GenericStatus.Error]) {
                            this.notifyService.warning(response.message)
                        }
                        else if (response.status === GenericStatus[GenericStatus.Success]) {
                            const data = response.data;
                            if (typeof (data) === "number") {
                                this.submitting = false;
                                this.notifyService.successToast("Appointment scheduled successfully.");

                                if (visitype[0].visitorName == "Emergency") {
                                    this.router.navigate(["/app/emergency-encounter", encryptedAppointmentId, 'b']);
                                } else {
                                    this.onClickEncounter(encryptedAppointmentId, encounterName);
                                }
                            }
                        }


                    }, (error: HttpErrorResponse) => {
                        this.submitting = false;
                        const errorMessage = UtilHelper.handleError(error);
                        if (errorMessage) {
                            this.notifyService.warning(errorMessage);
                        } else {
                            this.notifyService.defaultError();
                        }
                    });
            })

        }
    }

    private fetchPatientRegistrationCharges() {

        this.httpService.get<number>(ApiResources.getURI(ApiResources.patients.base, ApiResources.patients.patientRegistrationCharges), {})
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe((response: any) => {
                this.charges = response;

            });
    }

    private fetchVisitTypes() {

        this.httpService.post<any>(ApiResources.getURI(ApiResources.timeline.base, ApiResources.timeline.fetchVisitDetail), {})
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe((response: any) => {
                this.visittypesDetail = response

            });
    }

    private fetchChargTypes() {

        this.httpService.post<any>(ApiResources.getURI(ApiResources.timeline.base, ApiResources.timeline.fetchChargeDetail), {})
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe((response: any) => {
                this.chargetypesDetail = response;

            });
    }

    fetchdoctorDetail() {
        const request = {
            providerId: this.page.userAccount.referenceId
        }
        this.httpService
            .post<Array<any>>(ApiResources.getURI(ApiResources.timeline.base, ApiResources.timeline.fetchDoctorDetail), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe((response: Array<any>) => {
                this.ids = response;

                this.fetchSpecialization();

            })
    }

    onClickEncounter(encryptedAppointmentId: string, value: string) {
        switch (value) {
            case "Internal Medicine":
                this.router.navigate(["/app/internal-medicine", encryptedAppointmentId, 'b']);
                break;
            case "Gyne Encounter":
                this.router.navigate(["/app/gyn-encounter", encryptedAppointmentId, 'b']);
                break;
            case "OB Encounter":
                this.router.navigate(["/app/ob-encounter", encryptedAppointmentId, 'b']);
                break;
            case "Pediatric Encounter":
                this.router.navigate(["/app/pediatric-encounter", encryptedAppointmentId, 'b'])
                break;
            case "Diet Encounter":
                this.router.navigate(["/app/diet-plan-encounter", encryptedAppointmentId, 'b'])
                break;
            case "IVF Encounter":
                this.router.navigate(["/app/ivf-encounter", encryptedAppointmentId, 'b'])
                break;
            case "GynEncounter":
                this.router.navigate(["/app/gyn-encounter", encryptedAppointmentId, 'b']);
                break;
            case "OBEncounter":
                this.router.navigate(["/app/ob-encounter", encryptedAppointmentId, 'b']);
                break;
            case "InternalMedicine":
                this.router.navigate(["/app/internal-medicine", encryptedAppointmentId, 'b']);
                break;
            case "EmergencyEncounter":
                this.router.navigate(["/app/emergency-encounter", encryptedAppointmentId, 'b']);
                break;
            case "Physiotherapy Encounter":
                this.router.navigate(["/app/physiotherapy-encounter", encryptedAppointmentId, 'b']);
                break;
            case "Genetic Specialty Encounter":
                this.router.navigate(["/app/genetic-speciality", encryptedAppointmentId, 'b']);
                break;
            case "IVF New Encounter":
                this.router.navigate(["/app/ivf-new-encounter", encryptedAppointmentId, 'b']);
                break;
            case "PediatricEncounter":
                this.router.navigate(["/app/pediatric-encounter", encryptedAppointmentId, 'b'])
                break;
            default:
                this.router.navigate(["/app/emergency-encounter", encryptedAppointmentId, 'b']);
        }
    }

    fetchSpecialization() {
        const request = {
            specializations: this.ids[0].specialization
        }
        this.httpService
            .post<Array<any>>(ApiResources.getURI(ApiResources.admissions.base, ApiResources.admissions.fetchspeciallization), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe((response: Array<any>) => {
                this.data = response;
            })
    }

    onOpen(content: TemplateRef<any>, size: string) {
        this.modalRef = this.modalService.open(content, {
            backdrop: "static",
            keyboard: false,
            centered: false,
            size: size,
            windowClass: "custom-modal effect-scale"
        });
    }


    ngOnChanges(changes: SimpleChanges) {
        this.archiveStatus = false;
        if (this.encrypPatId) {
            this.specialityId = 0;
            this.dropdownList();
            this.getSpecializationList();
            this.fetchData(this.encrypPatId)

        }
        if (this.encounterType !== "patient") {
            sessionStorage.setItem("encryptedId", this.eid);//this.eid//MzA0MTc%253D
            sessionStorage.setItem("isAdmission", this.isAdmission.toString());//this.eid//MzA0MTc%253D
        }

        this.appData.userAccount
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe((userAccount: IUserAccount) => {
                if (userAccount) {
                    this.page.userAccount = userAccount;
                    const url = this.router.url;
                    if (this.check) {
                        this.Id = url.split("/")[url.split("/").length - 2];
                        if (this.Id) {
                            this.findPatient(this.Id);
                        }
                    }
                } else {
                    this.page.userAccount = undefined;
                }
                if (this.page.userAccount && this.page.userAccount.roleName == "DOCTOR") {
                    this.fetchdoctorDetail();
                    this.fetchChargTypes();
                    this.fetchVisitTypes();
                    this.fetchPatientRegistrationCharges();
                }
            });
        if (changes.eid?.currentValue) {
            this.toggleService.encryptedAppointmentId = this.eid;
            this.toggleService.isAdmission = this.isAdmission;
            this.toggleService.fetch();
            if (!this.check) {
                this.findAppointment(this.eid);
            }
            this.findDashboard();
        }
    }

    private findPatient(id: any) {

        const request = { encryptedPatientId: id };
        this.httpService.post<any>(ApiResources.getURI(ApiResources.patients.base, ApiResources.patients.find), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.page.loading = false))
            .subscribe((response: any) => {
                this.patient = response;
            });


    }

    onProgress(progressData: ProgressData) {
        if (progressData.loaded === progressData.total) {
            this.loadingDocument = false;
        }
    }

    onProgressImg(progressData: ProgressData) {
        if (progressData.loaded === progressData.total) {
            this.loadingPacsImg = false;
        }
    }

    onDocumentLoad() {
        this.loadingDocument = false;
    }

    onPacsLoad() {
        this.loadingPacsImg = false;
    }

    onDocumentError() {
        this.loadingDocument = false;
        this.documentError = true;
    }

    toggleVideo() {
        this.videoPlayer.nativeElement.play();
    }

    onCloseModal() {
        if (this.modalRef) {
            this.modalRef.close();
            this.modalRef = undefined;
            this.loadingDocument = undefined;
            this.documentError = undefined;
        }
    }

    safe = (url: any) => {
        if (url) {
            return `${ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.getProfileImage)}?imagePath=${url}`;
        }
        return this.defaultImgUrl;
    }

    view(item: Timeline, content?: TemplateRef<any>) {

        this.selected = item;
        this.selected.viewLoading = true;
        this.modalRef = this.modalService.open(content,
            {
                backdrop: "static",
                keyboard: false,
                centered: true,
                windowClass: "custom-modal effect-scale",
                size: "xl"
            });

        setTimeout(() => {
            this.httpService
                .post<ViewModel>(ApiResources.getURI(ApiResources.timeline.base, ApiResources.timeline.view), { id: item.timelineId.toString() })
                .pipe(finalize(() => this.selected.viewLoading = false))
                .subscribe(
                    (response: ViewModel) => {

                        this.viewData = response;

                        if (this.viewData.documents) {
                            this.viewData.documents.forEach(document => {
                                document.formedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(`${ApiResources.getURI(ApiResources.documents.base, ApiResources.documents.downloadFileGet)}?id=${document.patientDocumentId}&url=${document.documentUrl}`);

                                document.isImage = document.contentType.indexOf("pdf") < 0;
                            });
                        }

                        if (this.viewData.data) {
                            switch (this.selected.timelineActionId as TimelineAction) {
                                case TimelineAction.MedicationsAdded:
                                case TimelineAction.MedicationsUpdated:
                                    this.viewData.medications = JSON.parse(this.viewData.data);
                                    break;
                                case TimelineAction.NotesAdded:
                                case TimelineAction.NotesUpdated:
                                    this.viewData.notes = JSON.parse(this.viewData.data);
                                    break;
                                case TimelineAction.FollowUpAdded:
                                case TimelineAction.FollowUpUpdated:
                                    this.viewData.followUp = JSON.parse(this.viewData.data);
                                    break;
                                default:
                            }
                        }
                    }
                );
        });
    }

    findAppointment(appointmentId: string) {
        if (appointmentId) {

            const request = {
                encryptedAppointmentId: appointmentId
            };
            request["isAdmission"] = this.isAdmission;
            this.httpService.post<Appointment>(ApiResources.getURI(ApiResources.appointments.base, ApiResources.appointments.findAppointment), request)
                .subscribe((response: Appointment) => {
                    this.appointment = response;
                    this.loadMessageWindow = true;
                    if (this.isload) {
                        this.dropdownList();
                        this.getSpecializationList();
                    }
                    this.findAncCard();

                });
        }

    }
    toggleIt() {
        this.toggleService.toggle();
        if (this.toggleService.showBox == false) {
            $("body")[0].setAttribute("data-timeline-size", "timelineabsent");
        } else {
            $("body")[0].setAttribute("data-timeline-size", "timeline");
        }
    }
    private findOrderPrescription() {
        let PatientId: number = this.appointment.patientId;
        this.httpService.get(ApiResources.getURI(ApiResources.obEncounter.base, ApiResources.obEncounter.fetchFullTrascript), { patientId: PatientId })
            .subscribe((response: Array<ObEncounter>) => {
                if (response.length > 0) {
                    this.obPrescriptionRecords = response;
                }
            });
    }

    close() {
        try {
            this.modalRef.close();
            this.modalRef = undefined;
        } catch (e) {
            // ignored;
        }
    }

    onClickPrescription(item: ObEncounter) {

        this.router.navigate(['app', 'ob-encounter', item.encryptedAppointmentId, 'b', 'reports']);
    }

    onClickVisit(value: Appointment) {

        switch (value.specializationName) {
            case "OB":
                this.router.navigate([]).then(() => { window.open(`${AppConfig.settings.baseWeb}/#/full-transcript/ob-encounter/${value.encryptedAppointmentId}`, "_blank"); });
                break;

        }
    }

    onViewChange(item: Timeline, content?: TemplateRef<any>) {

        if (item.eventID == 18 || item.eventID == 26 || item.eventID == 27) {
            if (item.eventID == 26) {
                switch (item.specializationName.toUpperCase()) {
                    case "OB":
                    case "MATERNITY":
                        this.router.navigate(['app', 'ob-encounter', item.encryptedAppointmentId, 'b', 'reports']);
                        break;
                    case "GYNAECOLOGY":
                        this.router.navigate(['app', 'gyn-encounter', item.encryptedAppointmentId, 'b', 'reports']);
                        break;
                    case "PAEDIATRICS":
                        this.router.navigate(['app', 'pediatric-encounter', item.encryptedAppointmentId, 'b', 'prescription-reports', 'T']);
                        break;
                    case "PHYSIOTHERAPY":
                        this.router.navigate(['app', 'physiotherapy-encounter', item.encryptedAppointmentId, 'b', 'reports-counselling']);
                        break;
                    case "Genetic Specialization":
                        this.router.navigate(['app', 'genetic-speciality', item.encryptedAppointmentId, 'b', 'genetic-counselling-summary']);
                        break;
                    case "Diet":
                        this.router.navigate(['app', 'diet-plan-encounter', item.encryptedAppointmentId, 'b', 'reports', 'N']);
                        break;
                }
            }
            else {
                switch (item.specializationName.toUpperCase()) {
                    case "OB":
                    case "MATERNITY":
                        this.router.navigate([]).then(() => { window.open(`${AppConfig.settings.baseWeb}/#/full-transcript/ob-encounter/${item.encryptedAppointmentId}`, "_blank"); });
                        break;
                    case "GYNAECOLOGY":
                        this.router.navigate([]).then(() => { window.open(`${AppConfig.settings.baseWeb}/#/full-transcript/gyn-encounter/${item.encryptedAppointmentId}`, "_blank"); });
                        break;
                    case "PAEDIATRICS":
                        this.router.navigate([]).then(() => { window.open(`${AppConfig.settings.baseWeb}/#/full-transcript/pediatric-encounter/${item.encryptedAppointmentId}`, "_blank"); });
                        break;
                    case "PHYSIOTHERAPY":
                        this.router.navigate([]).then(() => { window.open(`${AppConfig.settings.baseWeb}/#/full-transcript/physiotherapy-encounter/${item.encryptedAppointmentId}`, "_blank"); });
                        break;
                    case "Genetic Specialization":
                        this.router.navigate([]).then(() => { window.open(`${AppConfig.settings.baseWeb}/#/full-transcript/genetic-speciality-encounter/${item.encryptedAppointmentId}`, "_blank"); });
                        break;
                    case "Diet":
                        this.router.navigate([]).then(() => { window.open(`${AppConfig.settings.baseWeb}/#/full-transcript/diet-plan-encounter/${item.encryptedAppointmentId}`, "_blank"); });
                        break;

                }
            }
        }

        else if (item.eventID == 40 || item.eventID == 22 || item.eventID == 39) {
            if (item.eventID == 40) { this.view(item, content); }
            else if (item.eventID == 39) {
                this.fetchScanDocuments(item);
            }
        }
        else if (item.eventID == 20 || item.eventID == 32) {
            this.findLabBookingHeader();
            this.onOpenModel(this.templateLabReport, null, item);

        }
        else if (item.eventID == 37 || item.eventID == 24) {
            this.router.navigate(['app', 'nurse-assessment', item.encryptedAdmissionId, 'a', 'nurse-notes-report']);
        }
        else if (item.eventID == 31 || item.eventID == 19) {
            this.router.navigate(['app', 'ob-ip-encounter', item.encryptedAppointmentId, 'b', 'admission-detail-report']);
        }
        else if (item.eventID == 28 || item.eventID == 17) {
            this.router.navigate(['app', 'ob-encounter', item.encryptedAppointmentId, 'b', 'anc-report']);
        }
        else if (item.eventID == 21 || item.eventID == 29) {
            this.router.navigate(['app', 'ob-ip-encounter', item.encryptedAdmissionId, 'a', 'discharge-report']);
        }
        else if (item.eventID == 41) {
            this.router.navigate(['app', 'operation-theater', 'ot-master', 'ot-reports', this.appointment.encryptedPatientId, 1, btoa(item.otRegisterId.toString())]);
        }

    }

    viewReport() {
        this.router.navigate(['app', 'ob-encounter', this.appointment.encryptedAppointmentId, 'b', 'investigation-checklist']);
    }

    private fetchAppointmentCounts() {
        const request = {
            encryptedPatientId: this.appointment.encryptedPatientId
        }
        this.httpService
            .post<Array<Appointment>>(ApiResources.getURI(ApiResources.appointments.base, ApiResources.appointments.fetch), request)
            .subscribe(
                (response: Array<Appointment>) => {
                    if (response.length > 0) {
                        this.appointmentCounts = response;

                    }
                }
            );
    }

    openAll() {
        if (this.openAllFlag === true) {
            this.showTranscript = false;
            this.showOpdVisit = false;
            this.showDocument = false;
            this.showPac = false;
            this.showLabs = false;
            this.showPacs = false;
            this.openAllFlag = false;
            return;
        }
        this.showTranscript = true;
        this.onClickTranscript();
        this.showOpdVisit = true;
        this.onClickOpdVisit();
        this.showDocument = true;
        this.onClickOpdVisit();
        this.showPac = true;
        this.onPac();
        this.showLabs = true;
        this.onClickLabs();
        this.showPacs = true;
        this.onClickImgReport();
        this.openAllFlag = true;
    }

    showSection(flag: string) {
        if (flag == 'opdPres') {
            this.onClickTranscript();
            this.showTranscript = !this.showTranscript;
        } else {
            if (this.openAllFlag === false) {
                this.showTranscript = false;
            }
        }
        if (flag == 'opdVisit') {
            this.onClickOpdVisit();
            this.showOpdVisit = !this.showOpdVisit;
        } else {
            if (this.openAllFlag === false) {
                this.showOpdVisit = false;
            }
        }
        if (flag == 'attachments') {
            this.onClickAttachments();
            this.showDocument = !this.showDocument;
        } else {
            if (this.openAllFlag === false) {
                this.showDocument = false;
            }
        }
        if (flag == 'PAC') {
            this.onPac();
            this.showPac = !this.showPac;
        } else {
            if (this.openAllFlag === false) {
                this.showPac = false;
            }
        }
        if (flag == 'investigation') {
            this.onClickLabs();
            this.showLabs = !this.showLabs;
        } else {
            if (this.openAllFlag === false) {
                this.showLabs = false;
            }
        }
        if (flag == 'pacsImages') {
            this.onClickImgReport();
            this.showPacs = !this.showPacs;
        } else {
            if (this.openAllFlag === false) {
                this.showPacs = false;
            }
        }
    }

    onClickTranscript() {
        if (this.showTranscript) {
            this.findAppointment(this.eid);

        }
    }

    onClickOpdVisit() {
        if (this.showOpdVisit) {
            this.findAppointment(this.eid);
            setTimeout(() => {
                this.fetchAppointmentCounts();
            })
        }

    }

    onClickImgReport() {
        if (this.showPacs) {
            this.findAppointment(this.eid);
            //setTimeout(() => {
            //    this.fetchPacsImages();
            //})
        }
    }

    onClickAttachments() {
        if (this.showDocument) {
            this.findAppointment(this.eid);
            setTimeout(() => {
                this.fetchDocuments();
            })
        }
    }
    onPac() {
        if (this.showPac) {
            this.findAppointment(this.eid);
            setTimeout(() => {
                this.findPac();
            })
        }
    }

    onClickLabs() {
        if (this.showLabs) {
            this.findAppointment(this.eid);
            setTimeout(() => {
                this.findLabBookingHeader();
            })
        }
    }

    private fetchScanDocuments(item?) {
        let PatientId: number = this.appointment.patientId;
        this.httpService.post(ApiResources.getURI(ApiResources.bookScanAppointment.base, ApiResources.bookScanAppointment.fetchScanDocument), { patientId: PatientId })
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe((response: Array<ScanDocument>) => {
                this.reports = response;
                this.onOpenPacsModel(this.templatePacsImage, item);
            });
    }

    fetchDocuments() {
        const request =
            { encryptedPatientId: this.appointment.encryptedPatientId }
        this.httpService.post(ApiResources.getURI(ApiResources.documents.base, ApiResources.documents.fetch), request)
            .subscribe((response: Array<PatientDocument>) => {
                if (response[0].patientDocumentId != 0) {
                    this.documentsRecord = response;

                }
            });
    }

    fetchPacsImages() {
        this.httpService.post(ApiResources.getURI(ApiResources.bookScanAppointment.base, ApiResources.bookScanAppointment.fetchPacsImage), this.appointment.patientId)
            .subscribe((response: Array<ScanDocument>) => {
                this.reports = response;
            });
    }

    private findPac() {
        let PatientId: number = this.appointment.patientId;
        this.httpService.get(ApiResources.getURI(ApiResources.obEncounter.base, ApiResources.obEncounter.fetchFullTrascript), { patientId: PatientId })
            .subscribe((response: Array<ObEncounter>) => {
                if (response.length > 0) {
                    this.anaesthesia = [];
                    this.ob = response;
                    this.ob.forEach((item: ObEncounter) => {
                        if (item.anaesthesiaRecord != null) {
                            this.anaesthesia.push(item);
                        }
                    })
                }
            });
    }

    onOpenModel(content: TemplateRef<any>, record?: PatientDocument, labRecords?: Timeline) {
        if (record) {
            this.selectedDocument = this.documentsRecord.find(s => s.patientDocumentId == record.patientDocumentId);
            this.prepareDocument(record);
        }
        if (labRecords) {
            this.encryptedNewLabBookingDetailId = labRecords.encryptedNewLabBookingDetailId;
        }
        this.modalRef = this.modalService.open(content, {
            keyboard: false,
            centered: true,
            windowClass: "document-view-modal custom-modal effect-scale"
        });


    }

    onOpenPacsModel(content: TemplateRef<any>, report?: ScanDocument) {
        this.modalRef = this.modalService.open(content, {
            keyboard: false,
            centered: true,
            windowClass: "document-view-modal custom-modal effect-scale"
        });

        if (report) {
            this.selectedPacsImg = this.reports.find(x => x.scanDocumentId == report.scanDocumentId);
            this.preparePacsImg(this.selectedPacsImg);
        }
    }

    private prepareDocument(document?: PatientDocument) {
        if (document)
            this.loadingDocument = true;
        this.document = document;
        this.documentError = false;
        this.document.isImage = this.document.contentType.indexOf("pdf") < 0 && this.document.contentType.indexOf("video") < 0;
        this.document.isVideo = this.document.contentType.indexOf("video") >= 0;

        if (this.document.isImage || this.document.isVideo) {
            this.document.formedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(`${ApiResources.getURI(ApiResources.documents.base, ApiResources.documents.downloadFileGet)}?id=${this.document.patientDocumentId}&url=${this.document.documentUrl}`);
        }

        if (!this.document.isImage && !this.document.isVideo) {
            this.httpService
                .post(ApiResources.getURI(ApiResources.documents.base, ApiResources.documents.downloadFile), { ...document })
                .pipe(finalize(() => { this.loadingDocument = false; }))
                .subscribe(
                    (response: PatientDocument) => {
                        this.document.formedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(`data:application/pdf;base64, ${response.base64}`);
                    }
                );
        } else {
            this.loadingDocument = false;
        }
    }

    private preparePacsImg(document: ScanDocument) {
        this.loadingPacsImg = true;
        this.pacsImg = document;
        this.documentError = false;
        this.pacsImg.isImage = this.pacsImg.contentType.indexOf("pdf") < 0 && this.pacsImg.contentType.indexOf("video") < 0;
        this.pacsImg.isVideo = this.pacsImg.contentType.indexOf("video") >= 0;

        if (this.pacsImg.isImage || this.pacsImg.isVideo) {
            this.pacsImg.formedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(`${ApiResources.getURI(ApiResources.documents.base, ApiResources.documents.downloadFileGet)}?id=${this.pacsImg.scanDocumentId}&url=${this.pacsImg.documentUrl}&type=${'ScanReports'}`);
        }

        if (!this.pacsImg.isImage && !this.pacsImg.isVideo) {
            this.httpService
                .post(ApiResources.getURI(ApiResources.documents.base, ApiResources.documents.downloadFile), { ...document })
                .pipe(finalize(() => { this.loadingPacsImg = false; }))
                .subscribe(
                    (response: ScanDocument) => {
                        this.pacsImg.formedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(`data:application/pdf;base64, ${response.base64}`);
                    }
                );

        } else {
            this.loadingPacsImg = false;
        }
    }

    private findLabBookingHeader() {
        let PatientId: number;
        if (this.appointment) {
            PatientId = this.appointment.patientId;
        } else {
            PatientId = Number(this.encrypPatId)
        }

        this.httpService.get(ApiResources.getURI(ApiResources.obEncounter.base, ApiResources.obEncounter.labReports), { patientId: PatientId })
            .subscribe((response: Array<LabBillDetailModel>) => {
                if (response.length > 0) {
                    this.labBookingDetail = response;
                }
            });
    }

    onSelect(type: string) {
        switch (type) {
            case "Appointments":
                this.showAll = true;
                this.showOpdPrescription = false;
                this.showOpdVisit = false;
                this.showTranscript = false;
                this.showDocument = false;
                this.showPac = false;
                this.showLabs = false;
                this.showPacs = false;
                this.toggleService.fetch(undefined, undefined, 7);
                //this.toggleService.records=this.toggleService.records.filter(s => s.timelineActionId == 7);
                break;
            case "OP Encounter":
                this.showAll = true;
                this.showOpdPrescription = false;
                this.showTranscript = false;
                this.showOpdVisit = false;
                this.showDocument = false;
                this.showPac = false;
                this.showLabs = false;
                this.showPacs = false;
                this.toggleService.fetch(undefined, undefined, 14);
                //this.toggleService.records = this.toggleService.records.filter(s => s.timelineActionId == 14);
                break;
            case "Documents":
                this.showAll = true;
                this.showOpdPrescription = false;
                this.showTranscript = false;
                this.showOpdVisit = false;
                this.showDocument = false;
                this.showPac = false;
                this.showLabs = false;
                this.showPacs = false;
                this.toggleService.fetch(undefined, undefined, 1);
                //this.toggleService.records =this.toggleService.records.filter(s => s.timelineActionId == 1);
                break;
            case "OPD Prescription":
                this.showOpdPrescription = true;
                this.showAll = false;
                this.showTranscript = true;
                this.showOpdVisit = false;
                this.showDocument = false;
                this.showPac = false;
                this.showLabs = false;
                this.showPacs = false;
                this.findOrderPrescription();
                break;
            case "OPD Visits":
                this.showOpdPrescription = false;
                this.showAll = false;
                this.showTranscript = false;
                this.showOpdVisit = true;
                this.showDocument = false
                this.showPac = false;
                this.showLabs = false;
                this.showPacs = false;
                this.fetchAppointmentCounts();
                break;
            case "Attachments":
                this.showOpdPrescription = false;
                this.showAll = false;
                this.showTranscript = false;
                this.showOpdVisit = false;
                this.showDocument = true
                this.showPac = false;
                this.showLabs = false;
                this.showPacs = false;
                this.fetchDocuments();
                break;
            case "PAC":
                this.showOpdPrescription = false;
                this.showAll = false;
                this.showTranscript = false;
                this.showOpdVisit = false;
                this.showDocument = false
                this.showPac = false;
                this.showLabs = false;
                this.showPacs = true;
                this.findPac();
                break;
            case "Investigation":
                this.showOpdPrescription = false;
                this.showAll = false;
                this.showTranscript = false;
                this.showOpdVisit = false;
                this.showDocument = false
                this.showPac = false;
                this.showLabs = true;
                this.showPacs = false;
                this.findLabBookingHeader();
                break;
            case "Pacs Images":
                this.showOpdPrescription = false;
                this.showAll = false;
                this.showTranscript = false;
                this.showOpdVisit = false;
                this.showDocument = false
                this.showPac = false;
                this.showLabs = false;
                this.showPacs = true;
                this.fetchPacsImages();
                break;
            case "All":
                this.showAll = true;
                this.showOpdPrescription = false;
                this.showOpdVisit = false;
                this.showTranscript = false;
                this.showDocument = false;
                this.showPac = false;
                this.showLabs = false;
                this.showPacs = false;
                this.toggleService.fetch();
                break;
            default:
                break;

        }
    }

    onSerach() {
        if (this.filterText && this.filterText.length > 2) {
            var temp = [];

            for (let row of this.toggleService.records) {
                //var stk = `^${this.filterText}`;
                //const regexp = new RegExp(stk.toLowerCase());

                //if (regexp.test(row.description)) { temp.push(row); }
                if (row.description.includes(this.filterText)) { temp.push(row); }

            }
            this.toggleService.records = temp;

        }
    }

    private findDashboard() {

        const request = { encryptedAppointmentId: this.eid };
        request["isAdmission"] = this.isAdmission;

        this.httpService.post<[ObEncounter]>(ApiResources.getURI(ApiResources.obEncounter.base, ApiResources.obEncounter.findEncounterData), request)
            .subscribe((response: [ObEncounter]) => {
                if (response) {
                    this.obEncounter = response["dashboard"];
                    if (this.obEncounter && this.obEncounter.obEncounterId) {
                        this.ancCards = this.obEncounter.ancCard ? JSON.parse(this.obEncounter.ancCard) : null;
                        this.ancCardGeneration = this.obEncounter.ancCardGeneration ? JSON.parse(this.obEncounter.ancCardGeneration) : null;
                    }
                }

            });

    }


    private findAncCard() {
        const request = { patientId: this.appointment.patientId };
        this.httpService.post(ApiResources.getURI(ApiResources.obEncounter.base, ApiResources.obEncounter.fetchPatientAllAncCard), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.page.loading = false))
            .subscribe((response: OB.AncCardGeneration) => {
                if (response != null) {
                    this.ancCardGeneration = response;
                }

            });
    }

    onClickFullTranscipt(value: Appointment) {

        switch (value.encounterType) {
            case "GynEncounter":
                this.router.navigate([]).then(() => { window.open(`${AppConfig.settings.baseWeb}/#/full-transcript/gyn-encounter/${value.encryptedAppointmentId}`, "_blank"); });
                break;
            case "OBEncounter":
                this.router.navigate([]).then(() => { window.open(`${AppConfig.settings.baseWeb}/#/full-transcript/ob-encounter/${value.encryptedAppointmentId}`, "_blank"); });
                break;
            case "OB Encounter":
                this.router.navigate([]).then(() => { window.open(`${AppConfig.settings.baseWeb}/#/full-transcript/ob-encounter/${value.encryptedAppointmentId}`, "_blank"); });
                break;

        }
    }

    toggleMessageBox() {
        this.showMessageBox = !this.showMessageBox;
    }

    onCall(isPreLoad = false) {
        this.jitsiService.call({
            accountId: this.appointment.patientAccountId,
            appointmentId: this.appointment.appointmentId,
            appointmentNo: this.appointment.appointmentNo,
            patientThumbnailUrl: this.appointment.patientThumbnailUrl,
            type: 1,
            isPreLoad: isPreLoad
        });
    }

    getSpecializationList() {
        this.httpService.post(ApiResources.getURI(ApiResources.specialization.base, ApiResources.specialization.fetchall), {})
            .subscribe((response: any) => {
                this.specializationlist = response;
                if (this.appointment) {
                    if (sessionStorage.getItem("glbAppointmentId") != this.appointment.appointmentId.toString()) {
                        this.selectedSpecializationName = this.getSpecializationNameById(this.appointment.specializationId);
                        sessionStorage.setItem("glbSelectedSpecializationname", this.selectedSpecializationName);
                        sessionStorage.setItem("glbAppointmentId", this.appointment.appointmentId.toString());
                    } else {
                        if (sessionStorage.getItem("glbSelectedSpecializationname") != "" || sessionStorage.getItem("glbSelectedSpecializationname") != undefined) {
                            this.selectedSpecializationName = sessionStorage.getItem("glbSelectedSpecializationname");
                        }

                    }

                    this.showObOrGynIcons(this.selectedSpecializationName);
                }
                this.isload = false
            })
    }

    showObOrGynIcons(selectedField: string) {
        if (selectedField === 'OB' || selectedField === 'OBSTETRICS' || selectedField === 'OBS') {
            this.isOb = true;
            this.isGyne = false;
            this.isPed = false;
        } else if (selectedField === 'GYNAECOLOGY' || selectedField === 'GYN' || selectedField === 'Gynaecology') {
            this.isOb = false;
            this.isGyne = true;
            this.isPed = false;
        } else if (selectedField === 'Pediatrics' || selectedField === 'NEONATAL') {
            this.isOb = false;
            this.isGyne = false;
            this.isPed = true;
        }
        else {
            this.isOb = false;
            this.isGyne = false;
            this.isPed = false;
        }
    }

    getSpecializationNameById(id: number): string {
        const specialization = this.specializationlist.find(item => item.specializationId === id);
        return specialization ? specialization.specializationName : 'All';
    }

    onChange(selectedValue: any) {
        if (this.openAllSpecalities == true) {
            this.openPastObDocumentModel(this.templateArchiveViewDocument);
        }
       
        this.dropdownValue = true;
        this.selectedSpecializationName = selectedValue;
        sessionStorage.setItem("glbSelectedSpecializationname", this.selectedSpecializationName);
        sessionStorage.setItem("glbAppointmentId", this.appointment.appointmentId.toString());
        this.showObOrGynIcons(this.selectedSpecializationName);
        if (selectedValue === 'All') {
            this.isAll = true;
        } else {
            this.isAll = false;
        }
       
    }

    dropdownList() {
        let request;
        if (this.dropdownValue == false) {
            if (this.appointment) { request = { SpecializationId: (this.appointment.specializationId).toString(), ParentId: null }; }
            else { request = { ParentId: 0, SpecializationId: '0' } }
        } else {
            if (this.specialityId < 200) {
                if (typeof (this.specialityId) != "string") { request = { SpecializationId: (this.specialityId).toString(), ParentId: 0 }; }
                else { request = { SpecializationId: this.specialityId, ParentId: 0 }; }
            }

        }

        this.httpService
            .post<ViewModel>(ApiResources.getURI(ApiResources.timeline.base, ApiResources.timeline.fetchtimelinemenu), request)
            .subscribe((response: any) => {
                this.dropdownlist = response.filter(item => item.image !== null && item.image !== undefined);
                if (this.encrypPatId) { this.onMenuChange(this.encrypPatId) }
                else (this.onMenuChange(0))
                    ;
            })
    }

    onMenuChange(icon: any) {
        this.archiveStatus = false;
        this.multiview = false;
        this.openAllSpecalities = false;
        this.name = icon.sourceName;
        this.investigationCheckList = [];
        if (icon.eventID == 23) {
            this.labs = [];
            this.timelineData = null;
            this.onFetchAllInvestigationCheckList();
            return;
        }
        if (icon.sourceName == "All") {
            icon.eventID = 0;
            this.parentId = 0;
            this.showData = false;
        }
        else if (icon.sourceName != undefined && icon.sourceName != "All") {
            this.parentId = icon.eventID;
            this.showData = true;
        }
        let request;
        if (icon.eventID !== undefined) {
            if (this.appointment != undefined) {
                if (this.showData == false) { request = { SpecializationId: (this.appointment.specializationId).toString(), EventID: 0 }; }
                else { request = { SpecializationId: (this.appointment.specializationId).toString(), ParentId: (icon.eventID) }; }

            }
            else { request = { SpecializationId: (this.specialityId).toString(), ParentId: (icon.eventID) }; }

        }
        else {
            if (this.appointment) { request = { SpecializationId: (this.appointment.specializationId).toString(), EventID: 0 } }
            else {
                if (icon < 200) { request = { SpecializationId: (icon).toString() } }
                else { request = { SpecializationId: '0' } }
            }
        }

        this.httpService.post<ViewModel>(ApiResources.getURI(ApiResources.timeline.base, ApiResources.timeline.fetchtimelinemenu), request)
            .subscribe((response: any) => {
                this.submenulist = response;
                if (this.appointment?.specializationId == 112) {
                    this.submenulist = response.filter((item: any) => item.eventID !== 31);
                }
                else if (this.appointment?.specializationId !== 48) {
                    this.submenulist = response.filter((item: any) => item.eventID !== 30);
                }
                if (icon != 0 && typeof icon !== 'object' && this.specialityId != undefined) { this.fetchData(this.specialityId); }
                else (this.fetchData(icon))
            })
    }

    fetchData(icon: any) {
        if (typeof (icon) == "string") {
            icon = Number(icon);
        }

        if (this.specialityId != undefined) {
            this.specialityId = Number(this.specialityId);
        }
        let request;
        this.checklEventId = icon
        if (icon.eventID != undefined) { this.eventId = icon.eventID; }
        if (this.isAll == true) {
            if (this.showData == true) {
                request = { PatientId: this.appointment.patientId, EventID: icon.eventID, SpecializationId: 0 };
            }
            else if (this.subMenuValue == true) {
                if (this.specialityId != undefined) {
                    request = { PatientId: this.appointment.patientId, SpecializationId: this.specialityId, EventID: this.eventId }
                } else { request = { PatientId: this.appointment.patientId, SpecializationId: this.appointment.specializationId, EventID: this.eventId }; }
            }
            else { request = { PatientId: this.appointment.patientId, EventID: this.parentId, SpecializationId: 0 }; }
        }
        else {
            if (this.specialityId != undefined) {
                if (this.encrypPatId) {
                    if (typeof icon === 'object') { request = { PatientId: this.encrypPatId, SpecializationId: this.specialityId, EventID: icon.eventID } }
                    else {
                        if (this.subMenuValue == true) { request = { PatientId: this.encrypPatId, SpecializationId: this.specialityId, EventID: icon } }
                        else { request = { PatientId: this.encrypPatId, SpecializationId: this.specialityId } }

                    }
                }
                else {
                    if (typeof icon === 'object') { request = { PatientId: this.appointment.patientId, SpecializationId: this.specialityId, EventID: (icon.eventID) }; }
                    else { request = { PatientId: this.appointment.patientId, SpecializationId: this.specialityId, EventID: icon }; }
                }
            } else {
                if (icon?.eventID != undefined) {
                    request = { PatientId: this.appointment.patientId, SpecializationId: this.appointment.specializationId, EventID: (icon.eventID) };
                } else if (icon == 0 || this.encrypPatId == undefined || icon < 100) {
                    request = { PatientId: this.appointment.patientId, SpecializationId: this.appointment.specializationId, EventID: this.eventId }
                }
                else { request = { PatientId: icon } }
            }
        }
        this.httpService.post<ViewModel>(ApiResources.getURI(ApiResources.timeline.base, ApiResources.timeline.fetchtimelinedata), request)
            .subscribe((response: any) => {
                this.timelineData = response;
                this.labs = new Array<LabBillDetailModel>;
                if (this.eventId == 20) {
                    this.records = response;
                    this.records.forEach((record) => {
                        this.labs.push(record)
                    })
                }
                this.showData = false;
                this.subMenuValue = false;
                if (this.encrypPatId && this.isLoadAppointment) {
                    for (let i = 0; i < this.timelineData.length; i++) {
                        if (this.timelineData[i].encryptedAppointmentId) {
                            this.findAppointment(this.timelineData[i].encryptedAppointmentId);
                            this.encryprtedAppId = this.timelineData[i].encryptedAppointmentId
                            break;
                        }
                    }
                    for (let i = 0; i < this.timelineData.length; i++) {
                        if (this.timelineData[i].encryptedAppointmentId) {
                            this.encriptPatientId = this.timelineData[i].encryptedPatientId;
                            break;
                        }
                    }

                    this.toggleService.encryptedAppointmentId = this.timelineData[0]?.encryptedAppointmentId;
                    this.toggleService.fetch()
                }


            })

        if (this.eventId == 20) {
            this.fetchinvestigationchecklist(this.appointment.patientId);
        }
    }

    onSelectValue(icon: any) {

        this.subMenuValue = true;
        icon = Number(icon);
        if (this.eventId == 25 || icon == 25) {
            this.eventId = 0
        }
        else if (icon == 43) {
            this.openPastObDocumentModel(this.templateArchiveViewDocument)
        } else {
            this.eventId = Number(icon);
            this.archiveStatus = false;
            this.obReportLoding = false;
            this.multiview = false;
            this.openAllSpecalities = false;
        }
        this.fetchData(icon);
    }
    onFetchLab(lab?: any) {
        if (lab) {
            this.router.navigate(['app', this.routingValue, this.encryptedAppointmentId, 'b', 'patient-lab-details', decodeURIComponent(lab.encryptedLabMainDetailId)]);
        } else {
            this.router.navigate(['app', this.routingValue, this.encryptedAppointmentId, 'b', 'investigation-checklist-data']);
        }
    }

    ngOnInit() {
        this.appData.userAccount
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe((userAccount: IUserAccount) => {
                if (userAccount) {
                    this.page.userAccount = userAccount;
                    const url = this.router.url;
                    this.check = url.includes("patient")
                    this.isAdmission = url.split("/")[4] === "a";
                    const appointmentId = url.split("/")[url.split("/").length - 3];
                    this.routingValue = url.split("/")[url.split("/").length - 4];
                    this.encryptedAppointmentId = decodeURIComponent(appointmentId);
                    this.onFetchAllInvestigationCheckList();
                    this.encounterCommunication.encounter.subscribe(() => {
                        this.findAppointment(this.encryptedAppointmentId);
                        this.findDashboard();
                    });
                } else {
                    this.page.userAccount = undefined;
                }
            });
    }



    OnClickAllReports() {
        this.showObOrGynIcons(this.selectedSpecializationName);
        this.isComingFrom = "Html";
        if (this.encryprtedAppId == undefined) {
            this.encryprtedAppId = sessionStorage.getItem("encryptedId");
        }
        if (this.isAdmission == undefined) {
            this.isAdmission = sessionStorage.getItem("isAdmission") === "true" ? true : false;
        }
        this.router.navigate(['app', 'ob-encounter', this.encryprtedAppId, this.isAdmission == true ? 'a' : 'b', 'full-report'])
    }


    PatientInfoDocument() {
        this.showObOrGynIcons(this.selectedSpecializationName);
        this.isComingFrom = "Print";
        if (this.encryprtedAppId == undefined) {
            this.encryprtedAppId = sessionStorage.getItem("encryptedId");
        }
        if (this.isAdmission == undefined) {
            this.isAdmission = sessionStorage.getItem("isAdmission") === "true" ? true : false;
        }

        this.router.navigate(['app', 'ob-encounter', this.encryprtedAppId, this.isAdmission === true ? 'a' : 'b', 'patient-documents']);
    }

    OnClickAllReportsGyne() {
        this.showObOrGynIcons(this.selectedSpecializationName);
        this.isComingFrom = "Html";
        if (this.encryprtedAppId == undefined) {
            this.encryprtedAppId = sessionStorage.getItem("encryptedId");
        }
        if (this.isAdmission == undefined) {
            this.isAdmission = sessionStorage.getItem("isAdmission") === "true" ? true : false;
        }
        this.router.navigate(['app', 'gyn-encounter', this.encryprtedAppId, this.isAdmission == true ? 'a' : 'b', 'gyn-full-report']);
    }



    PatientInfoDocumentGyne() {
        this.showObOrGynIcons(this.selectedSpecializationName);
        this.isComingFrom = "Print";
        if (this.encryprtedAppId == undefined) {
            this.encryprtedAppId = sessionStorage.getItem("encryptedId");
        }
        if (this.isAdmission == undefined) {
            this.isAdmission = sessionStorage.getItem("isAdmission") === "true" ? true : false;
        }
        this.router.navigate(['app', 'gyn-encounter', this.encryprtedAppId, this.isAdmission == true ? 'a' : 'b', 'gyneac-print-document']);
    }


    OnClickAllReportsPedtric() {
        this.showObOrGynIcons(this.selectedSpecializationName);

        this.isComingFrom = "Html";
        if (this.encryprtedAppId == undefined) {
            this.encryprtedAppId = sessionStorage.getItem("encryptedId");
        } if (this.isAdmission == undefined) {
            this.isAdmission = sessionStorage.getItem("isAdmission") === "true" ? true : false;
        }
        this.router.navigate(['app', 'neonatal-ip-encounter', this.encryprtedAppId, this.isAdmission === true ? 'a' : 'b', 'neonatal-report'])

    }

    PatientInfoDocumentpediatric() {
        this.showObOrGynIcons(this.selectedSpecializationName);
        this.isComingFrom = "Print";
        if (this.encryprtedAppId == undefined) {
            this.encryprtedAppId = sessionStorage.getItem("encryptedId");
        }
        if (this.isAdmission == undefined) {
            this.isAdmission = sessionStorage.getItem("isAdmission") === "true" ? true : false;
        }
        this.router.navigate(['app', 'neonatal-ip-encounter', this.encryprtedAppId, this.isAdmission == true ? 'a' : 'b', 'neonatal-document']);
    }

    onFetchAllInvestigationCheckList() {
        this.httpService.get<Array<InvestigationCheckList>>(ApiResources.getURI(ApiResources.timeline.base, ApiResources.timeline.fetchallinvestigationchecklist)).
            subscribe((response: Array<InvestigationCheckList>) => {
                this.investigationCheckList = response;
            })
    }
    fetchinvestigationchecklist(patientId: number) {
        this.labs = [];
        const request = {};
        request["patientId"] = patientId;
        request["isFromInvestigation"] = true;
        this.httpService.post(ApiResources.getURI(ApiResources.labs.base, ApiResources.labs.fetchDoctorVerifiedReports), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => { }))
            .subscribe((response: Array<any>) => {
                this.labs = response;
                const data = [...new Set(this.labs.map(item => (item.testCode)))];
                var duplabs = this.labs;
                this.labs = new Array<LabBillDetailModel>();
                data.forEach((item) => {
                    var lab = duplabs.find(x => x.testCode == item);
                    this.labs.push(lab);
                })
            });
    }

    openPastObDocumentModel(content: TemplateRef<any>) {
        this.archiveStatus = false;
        this.errorMessage = null;
        this.PathReportStrings = []; 
        this.PathReportString = null;
        this.documentNames = []; 
        this.obReportLoding = true;
        this.multiview = false;
        this.openAllSpecalities = false;

        this.httpService.post(ApiResources.getURI(ApiResources.documents.base, ApiResources.documents.downloadPastObDocument), {
            EncryptedPatientId: this.patient.encryptedPatientId,
            UMRNo: this.patient.umrNo,
            SelectedSpecializationName: this.selectedSpecializationName.toUpperCase()
        }).pipe(
            takeUntil(this.page.unSubscribe),
            finalize(() => {
                this.loadingDocument = false;
                this.page.loading = false;
            })
        ).subscribe(
            (response: any) => {
                if (response.base64 && response.base64 !== "") {
                    // Handle single document case
                    this.archiveStatus = false;
                    this.PathReportString = 'data:application/pdf;base64,' + response.base64;
                    this.loadingDocument = true;
                    this.obReportLoding = false;
                    this.openAllSpecalities = true;
                    this.modalRef = this.modalService.open(content, {
                        keyboard: false,
                        backdrop: 'static',
                        centered: true,
                        windowClass: "document-view-modal custom-modal effect-scale cust_attach_model"
                    });
                } else if (response.base64Files && response.base64Files.length > 0) {
                    // Handle multiple documents case (array of base64 strings)
                    this.PathReportStrings = response.base64Files.map((base64File, index) => {
                        return {
                            base64: 'data:application/pdf;base64,' + base64File,
                            name: response.documentNames && response.documentNames[index] ? response.documentNames[index].toUpperCase() : 'Unknown Document'
                        };
                    });
                    this.multiview = true;
                    this.obReportLoding = false;
                    this.archiveStatus = false;
                    this.openAllSpecalities = true;
                } else {
                    this.archiveStatus = true;
                    this.obReportLoding = false;
                    this.multiview = false;
                    this.openAllSpecalities = true;
                    this.errorMessage = response.ErrorMessage || "No document found";
                }
            },
            error => {
                this.errorMessage = "An error occurred while loading the document.";
            }
        );
    }

    openSelectedPdfDocument(pdfBase64: any) {
        if (pdfBase64) {
            this.PathReportString = pdfBase64.base64;

            if (this.modalRef) {
                this.modalRef.close();
            }
            this.modalRef = this.modalService.open(this.templateArchiveViewDocument, {
                keyboard: false,
                backdrop: 'static',
                centered: true,
                windowClass: "document-view-modal custom-modal effect-scale cust_attach_model"
            });

        } else {
            console.error('No valid PDF data available to display');
        }
    }

    OnCheckIsExternal(event: boolean) {
        this.isExternal = event
    }
    OnPdf(data: any) {
        if (data) {
            this.reportData = data["reportData"];
            this.reportDataWithoutAuth = data?.reportDataWithoutAuth;
        }
    }
    onChangePrintType(type: boolean) {
        this.isPrintLogo = type;
    }
    onPrintPdf() {
        if (!this.isExternal) {
            return;
        }
        let printIframe;
        let blobUrls = [];
        let data = this.reportDataWithoutAuth;

        async function printMethod() {
            const resp = await fetch(data);
            const blob = await resp.blob();
            const url = URL.createObjectURL(blob);
            blobUrls.push(url);
            if (!printIframe) {
                printIframe = document.createElement('iframe');
                printIframe.setAttribute("id", "myDOC");
                document.body.appendChild(printIframe);

                printIframe.style.position = 'absolute';
                printIframe.style.top = '0';
                printIframe.style.left = '-1000px';

                printIframe.onload = () => {
                    setTimeout(() => {
                        if (printIframe) {
                            printIframe.focus();
                            printIframe.contentWindow.print();
                        }
                    }, 100)
                }
            }
            printIframe.src = url;
        }

        printMethod();
    }


}

export class MasterBillModel {
    billNumber: number;
    billDate: Date;
    createdDate: Date;
    modifiedDate: Date;
    createdByName: string;
    modifiedByName: string;

    deposit: number;//o/s amount
    total: number;
    netTotal: number;
    discount: number;
    tax: number;
    refund: number;
    rounding: number;
    patientName: string;
    moduleName: string;
    name: string;
    moduleId: number;
    paymentIntiateUrl: string;
    paidAmount: number;
    pendingAmount: number;

    umrNo: string;
    mobile: string;
    receiptSum: number;//deposit if not equal to bill amount

    receipts_Cost: string;
    receipts: string;
    billStatusTypeId: number;
    billStatus: string;
    appointmentDate: Date;
    appointmentNo: string;
    patientId: number;

    opConsultation: number;
    scanConsultation: number;
    lab: number;
    pharmacy: number;
    registrationCharges: number;
    receiptAreaTypeId: number;
    encryptedPatientId: string;
    modulesName: string;
    removedAmount: number;
    overAllTotal: number;
    total1: number;
    dueDate: Date;
}


export class LabDepartment {
    labDepartmentId: number;
    departmentName: string;
    description: string;
    active: boolean;
    createdBy: number;
    createdDate: Date;
    modifiedBy?: number;
    modifiedDate?: Date;
    createdByName: string;
    createdByRole: string;
    modifiedByName: string;
    modifiedByRole: string;
    totalItems: number;
    isReceivedDepartment: boolean;
}
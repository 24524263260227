import { Environment } from "./model";

export const environment: Environment = {
    production: false,
    showDevModule: true,
    firebase: {
        apiKey: "AIzaSyBjbtroeMRnwz_yI9ITSIdnAG0Iah1resM",
        authDomain: "careaxes-68d6e.firebaseapp.com",
        databaseURL: "https://careaxes-68d6e-default-rtdb.firebaseio.com",
        projectId: "careaxes-68d6e",
        storageBucket: "careaxes-68d6e.appspot.com",
        messagingSenderId: "927551524333",
        appId: "1:927551524333:web:bc29d72c402dce9f216be4",
        measurementId: "G-0F1L3VBNDW"
    }
};
import { PackageModuleDetailModel } from "./package-module-detail.model";

export class PackageModuleModel {
    packageModuleId: number;
    encryptedPackageModuleId: string;
    locationId: number;
    locationName: string;
    practiceName: string;
    providerId?: number;
    providerName: string;
    departmentName: string;
    moduleTypeId: number;
    moduleTypeName: string;
    packageName: string;
    packageType: string;
    payType: string;
    chargeModuleTemplateId: number;
    templateName: string;
    startDate: Date;
    endDate: Date;
    modulesMasterIds: string;
    quantity: number;
    freeQuantity: number;
    notes: string;
    exclusions: string;
    discountType: string;
    discountPercentage?: number;
    discountAmount?: number;
    active: boolean;
    createdBy: number;
    createdDate: Date;
    modifiedBy?: number;
    modifiedDate?: Date;
    expiresIn?: number;
    userAccountId: number;
    totalItems: number;
    isModifying: boolean;
    isDeleting: boolean;
    chargeModuleCategoryIds: string;
    total: number;
    totalAfterDiscount: number;
    isInUse: boolean;
    packageDocumentId: number;
    comments: string;
    admissionPackageId: number;
    packageModuleDetailId: number;
    appointmentId: number;
    admissionId: number;
    labMainDetailId: number
    packageModuleDetails: Array<PackageModuleDetailModel>;
    constructor() {
        this.packageModuleDetails = new Array<PackageModuleDetailModel>();
    }
}

export enum PackageType {
    Quantity = "Q",
    Limit = "L",
    SingleSelect = "S",
    MultiSelect = "M",
}

export class PackageDocument {
    packageDocumentId: number;
    packageModuleId: number;
    documentName: string;
    contentType: string;
    size: number;
    documentUrl: string;
    thumbnailUrl: boolean;
    uploadedDate: Date;
    active: boolean;
    isImage: boolean;
    isVideo: boolean;
    modifiedBy: number;
    modifiedByName: string;
    modifiedDate?: Date;
    totalItems: number;
    maxFileSize: string;
    base64: string;
    formedUrl: any;
    patientId?: number;
}